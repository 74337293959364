import React, { Fragment, useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {ModalDialog, openDialog, closeDialog} from "../components/DialogUtils";
import {Alert} from "../components/FormComps";
import SortableColumn from "../components/SortableColumn";
import {useInfiniteQuery, useQueryClient} from "react-query";
import useIntersectionObserver from "../useIntersectionObserver";
import getLogger from "../components/Logging.js"
import '../App.css'
import './PetSolv.css'
import ExportToFileDialog from "../components/ExportToFileDialog";

const log = getLogger("ResponsesDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function ResponsesDialog(props)  {

  const {api, auth: { session: { user, tenant }}} = useSolv()
  const {intl} = useIntlEx()

  const [broadcasts, setBroadcasts] = useState(null)
  const [sortSpec, setSortSpec] = useState({field: "createdOn", direction: "DESC"})
  const [refreshId, setRefreshId] = useState(null)

  const [alert, setAlert] = useState(null)

  const [selectedFeedback, setSelectedFeedback] = useState()

  const queryClient = useQueryClient()

  useEffect(() => {
    if (props.broadcasts) {
      log.debug("useEffect: broadcasts=", props.broadcasts)
      setBroadcasts(props.broadcasts)
    }
  }, [props])

  const fetchFeedbacks = async ({ pageParam = null }) => {
    log.debug("fetchFeedbacks: broadcasts=", broadcasts)
    const res = await api.listResponses(user.tenantId, broadcasts, null, sortSpec, pageParam, 50)
    log.debug("fetchFeedbacks: res=", res)
    if (res && res.data && res.data.length > 0) {
      log.debug("fetchFeedbacks: data=", res.data)
      setSelectedFeedback(res.data.at(0))
    }
    return res
  }

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(["responses", {broadcasts, sortSpec, refreshId}], fetchFeedbacks, {
    enabled: !!(user && user.onboardedOn),
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor
    },
  })

  const loadMoreButtonRef = React.useRef()

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  function doRefresh() {
    queryClient.invalidateQueries("responses")
    setRefreshId(Math.random())
  }

  async function markRead(feedback) {
    if (feedback && feedback.feedbackId && !feedback.readOn) {
      log.debug("Marking read: feedback=", feedback)
      const { data } = await api.markFeedbackAsRead(feedback.feedbackId)
      log.debug("data=", data)
      feedback.readOn = data
    }
  }

  function handleRowClick(feedback) {
    log.debug("handleRowClick: feedback=", feedback)
    markRead(feedback)
    setSelectedFeedback(feedback)
  }

  async function doExport() {

    const payload = {}
    if (broadcasts && broadcasts.length > 0) {
      payload.broadcastIds = broadcasts.map((o) => o.value)
    }

    log.debug("doExport: payload=", payload)

    return await api.exportResponses(payload)
  }

  function handleOpenDialog() {
  }

  function handleCloseDialogClick() {
    log.debug("handleCloseDialogClick")
    if (props.onCancel) {
      props.onCancel()
    }
  }

  return (
    <>
      <ModalDialog
          dialogId={props.id || "dlg_responses"}
          size={props.size || "md"}
          keyboard={"true"}
          backdrop={props.backdrop || "static"}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialogClick}
          header={
            <h1>{intl.msg("responses")}</h1>
          }
          footer={
            <>
              <button className="btn btn-tertiary mr-1" type="button" onClick={() => closeDialog("dlg_responses")}>{intl.msg("close")}</button>
              <button className="btn btn-secondary" type="button" onClick={() => openDialog("dlg_export_responses")}>{intl.msg("export")}</button>
            </>
          }>
        <section className="main">
          <div className="row">
            <div className="col">
              <Alert className="mb-2" alert={alert}/>
              <div className="row">
                <div className="col-5">
                  <table className="table feedback-table table-striped table-bordered" style={{marginTop: "10px"}}>
                    <thead className="thead-dark">
                    <tr>
                      <th scope="col" className="col-clickable">
                        <SortableColumn label={intl.msg("submitted_on")} field="createdOn" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                      </th>
                      {
                          broadcasts?.length > 1 &&
                          <th scope="col" className="col-clickable">
                            <SortableColumn label={intl.msg("broadcast")} field="broadcastName" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                          </th>
                      }
                    </tr>
                    </thead>
                    <tbody>
                    {
                      status === 'loading' ? (
                          <tr>
                            <td colSpan="3"><p>{intl.msg("loading")}</p></td>
                          </tr>
                      ) : status === 'error' ? (
                          <tr>
                            <td colSpan="3"><p>Error</p></td>
                          </tr>
                      ) : data ? (
                          data.pages.map((group, i) => (
                              <React.Fragment key={i}>
                                {group.data.map(feedback => (
                                    <tr key={i} className={feedback.readOn ? "" : "unread"} key={feedback.feedbackId} onClick={() => handleRowClick(feedback)}>
                                      <td>
                                        <a href="#" className="display-only" title={dayjs(feedback.createdOn).format("YYYY-MM-DD hh:mm:ss A")}>
                                          {dayjs(feedback.createdOn).fromNow()}
                                        </a>
                                      </td>
                                      {
                                          broadcasts?.length > 1 &&
                                          <td>
                                            {feedback.broadcastName}
                                          </td>
                                      }
                                    </tr>
                                ))}
                              </React.Fragment>
                          ))
                      ) : ""
                    }
                    </tbody>
                  </table>
                  <div>
                    {
                      hasNextPage ?
                          <button
                              className="btn btn-secondary"
                              ref={loadMoreButtonRef}
                              onClick={() => fetchNextPage()}
                              disabled={!hasNextPage || isFetchingNextPage}>
                            {
                              isFetchingNextPage
                                  ? <span>{intl.msg("loading")}</span>
                                  : <span>{intl.msg("show_more")}</span>
                            }
                          </button>
                          : ""
                    }
                  </div>
                  <div>
                    {isFetching && !isFetchingNextPage ? intl.msg("refreshing") : null}
                  </div>
                </div>
                <div className="col-7">
                  <div style={{position: "sticky", "top": "110px"}}>
                    <div style={{border: "1px solid var(--table-border-color)", marginTop: "10px", padding: "20px", backgroundColor: "var(--form-background-color)"}}>
                      <div className="live-preview-section-divider">
                        {intl.msg("responses_details_section")}
                      </div>
                      {
                          selectedFeedback &&
                          <>
                            <div className="row">
                              <div className="col-12">
                                <label className="mb-0" style={{color: "var(--form-control-label-color)", fontWeight: "400"}}>
                                  {intl.msg("responses_time_submitted")}:
                                </label>
                                <div className="mb-2">{dayjs(selectedFeedback.createdOn).format("YYYY-MM-DD hh:mm:ss A")}</div>
                              </div>
                            </div>
                              {
                                selectedFeedback.feedbackForm && selectedFeedback.feedbackForm.fields && selectedFeedback.feedbackForm.fields.map( field =>
                                    field.input !== "READONLY" &&
                                    <div className="row">
                                      <div className="col-12 mb-2">
                                        <label className="mb-0" style={{color: "var(--form-control-label-color)", fontWeight: "400"}}>{field.label}:</label>
                                        <div>{field.value}</div>
                                      </div>
                                    </div>
                                )
                              }
                          </>
                      }
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </ModalDialog>
      <ExportToFileDialog
          id="dlg_export_responses"
          type="responses"
          fileName={`solv-responses-${intl.date(new Date(), "ISO-COMPACT")}`}
          onExport={doExport}
      />
    </>

  )
}