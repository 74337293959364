import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useQueryClient } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useIntlEx } from "../components/IntlUtils.js"
import {closeDialog, ModalDialog} from "../components/DialogUtils";
import {useSolv} from "../components/SolvProvider";
import {FormGroup, useAlert} from "../components/FormComps";
import {InfoBlock, InfoText} from "../components/ValidationUtils";
import {default as Select} from "react-select";
import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";
import {brandOpts, environmentOpts, tenantPlanOpts, tenantTypeOpts} from "./PropsUtils";
import {mkExtraParams} from "../components/TableComps";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("PropsTestDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function PropsTestDialog(props)  {

  const {api, session:{user}, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const [alert, setAlert] = useAlert()
  const [touched, setTouched] = useState(false)

  const [environmentId, setEnvironmentId] = useState(null)
  const [regionId, setRegionId] = useState(null)
  const [industryId, setIndustryId] = useState(null)
  const [tenantTypeId, setTenantTypeId] = useState(null)
  const [tenantPlanId, setTenantPlanId] = useState(null)
  const [brandId, setBrandId] = useState(null)
  const [flagIds, setFlagIds] = useState(null)
  const [propsJson, setPropsJson] = useState(null)

  function findOpt(opts, value) {
    return opts.find(e => e.value === value)
  }

  useEffect(() => {
  }, [])

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog("dlg_props_test")
  }

  function loadRegions(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {
    return api.listRegions({extraParams: mkExtraParams({filterParams:{regionName: search}}, {sortParams: {regionName: "ASC"}}), cursor: additional?.cursor, limit: 50})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.regionId,
            label: c.regionName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.debug("Error loading regions: ", error)
      })
  }

  function loadIndustries(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {
    return api.listIndustries({extraParams: mkExtraParams({filterParams:{industryName: search}}, {sortParams: {industryName: "ASC"}}), cursor: additional?.cursor, limit: 50})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.industryId,
            label: c.industryName,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        return res
      })
      .catch((error) => {
        log.debug("Error loading regions: ", error)
      })
  }

  function loadFlags(search, loadedOptions, additional) {
    return api.listFlags({extraParams: mkExtraParams({filterParams:{flagId: search}}, {sortParams: {flagId: "ASC"}}), cursor: additional?.cursor})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.flagId,
            label: c.flagId,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadFlags: res=", res)
        return res
      });
  }

  function handleEnvironmentIdChange(v) {
    setEnvironmentId(v)
    setTouched(true)
  }

  function handleRegionIdChange(v) {
    setRegionId(v)
    setTouched(true)
  }

  function handleIndustryIdChange(v) {
    setIndustryId(v)
    setTouched(true)
  }

  function handleTenantTypeIdChange(v) {
    setTenantTypeId(v)
    setTouched(true)
  }

  function handleTenantPlanIdChange(v) {
    setTenantPlanId(v)
    setTouched(true)
  }

  function handleBrandIdChange(v) {
    setBrandId(v)
    setTouched(true)
  }

  function handleFlagIdsChange(v) {
    setFlagIds(v)
    setTouched(true)
  }

  async function handleTestClick() {

    setBusy(intl.msg("saving"))
    try {
      const payload = {
        environmentId: environmentId?.value,
        regionId: regionId?.value,
        industryId: industryId?.value,
        tenantType: tenantTypeId?.value,
        tenantPlanId: tenantPlanId?.value,
        brandId: brandId?.value,
        flagId: flagIds?.map(e => e.value),
      }

      const {data} = await api.testProps(payload)

      setPropsJson(JSON.stringify(data,null,2))

    }
    catch (err) {
      setAlert({error: intl.msg("error_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  function isEditing() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (

    <ModalDialog
      dialogId="dlg_props_test"
      size="xl"
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}>

      <ModalDialog.Header
        title={intl.msg("props_test_title")}
        alert={alert}/>

      <ModalDialog.Body>

        <table>
          <tbody>
            <tr>
              <td>
                <FormGroup>
                  <FormGroup.Label htmlFor="inp_environmentId" text={intl.msg("environment")}/>
                  <FormGroup.Controls>
                    <div style={{width: "200px"}}>
                      <Select id="inp_environmentId" className="react-select" classNamePrefix="react-select" value={environmentId} options={environmentOpts} isClearable placeholder={""} onChange={handleEnvironmentIdChange} isDisabled={!isEditing()}/>
                    </div>
                    <InfoBlock>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <FormGroup.Label htmlFor="inp_regionId" text={intl.msg("region")}/>
                  <FormGroup.Controls>
                    <AsyncPaginate id="inp_regionId" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={regionId} debounceTimeout={800} defaultOptions={true} loadOptions={loadRegions} reduceOptions={reduceGroupedOptions} onChange={handleRegionIdChange} isDisabled={!isEditing()}/><InfoBlock></InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <FormGroup.Label htmlFor="inp_industryId" text={intl.msg("industry")}/>
                  <FormGroup.Controls>
                    <AsyncPaginate id="inp_industryId" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={industryId} debounceTimeout={800} defaultOptions={true} loadOptions={loadIndustries} reduceOptions={reduceGroupedOptions} onChange={handleIndustryIdChange} isDisabled={!isEditing()}/><InfoBlock></InfoBlock>
                    <InfoBlock>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <FormGroup.Label htmlFor="tenantTypeId" text={intl.msg("tenant_type")}/>
                  <FormGroup.Controls>
                    <div style={{width: "200px"}}>
                      <Select id="tenantTypeId" className="react-select" classNamePrefix="react-select" value={tenantTypeId} options={tenantTypeOpts} isClearable placeholder={""} onChange={handleTenantTypeIdChange} isDisabled={!isEditing()}/>
                    </div>
                    <InfoBlock>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
            </tr>
            <tr>
              <td>
                <FormGroup>
                  <FormGroup.Label htmlFor="tenantPlanId" text={intl.msg("tenant_plan")}/>
                  <FormGroup.Controls>
                    <div style={{width: "200px"}}>
                      <Select id="tenantPlanId" className="react-select" classNamePrefix="react-select" value={tenantPlanId} options={tenantPlanOpts} isClearable placeholder={""} onChange={handleTenantPlanIdChange} isDisabled={!isEditing()}/>
                    </div>
                    <InfoBlock>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
              <td>
                <FormGroup>
                  <FormGroup.Label htmlFor="brandId" text={intl.msg("brand")}/>
                  <FormGroup.Controls>
                    <div style={{width: "200px"}}>
                      <Select id="brandId" className="react-select" classNamePrefix="react-select" value={brandId} options={brandOpts} isClearable placeholder={""} onChange={handleBrandIdChange} isDisabled={!isEditing()}/></div>
                    <InfoBlock>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
              <td colspan="2">
                <FormGroup>
                  <FormGroup.Label htmlFor="inp_flags" text={intl.msg("account_system_flags")}/>
                  <FormGroup.Controls>
                    <AsyncPaginate id="inp_flags" className="react-select" classNamePrefix="react-select" cacheOptions isMulti isClearable value={flagIds} placeholder={""} debounceTimeout={800} defaultOptions={true} loadOptions={loadFlags} onChange={handleFlagIdsChange} isDisabled={!isEditing()}/>
                  </FormGroup.Controls>
                </FormGroup>
              </td>
            </tr>
          </tbody>
        </table>

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="inp_environmentId" text={intl.msg("environment")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <div style={{width: "200px"}}>*/}
        {/*      <Select id="inp_environmentId" className="react-select" classNamePrefix="react-select" value={environmentId} options={environmentOpts} isClearable placeholder={""} onChange={handleEnvironmentIdChange} isDisabled={!isEditing()}/>*/}
        {/*    </div>*/}
        {/*    <InfoBlock>*/}
        {/*    </InfoBlock>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="inp_regionId" text={intl.msg("region")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <AsyncPaginate id="inp_regionId" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={regionId} debounceTimeout={800} defaultOptions={true} loadOptions={loadRegions} reduceOptions={reduceGroupedOptions} onChange={handleRegionIdChange} isDisabled={!isEditing()}/><InfoBlock></InfoBlock>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="inp_industryId" text={intl.msg("industry")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <AsyncPaginate id="inp_industryId" className="react-select" classNamePrefix="react-select" cacheOptions isClearable placeholder={""} value={industryId} debounceTimeout={800} defaultOptions={true} loadOptions={loadIndustries} reduceOptions={reduceGroupedOptions} onChange={handleIndustryIdChange} isDisabled={!isEditing()}/><InfoBlock></InfoBlock>*/}
        {/*    <InfoBlock>*/}
        {/*    </InfoBlock>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="tenantTypeId" text={intl.msg("tenant_type")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <div style={{width: "200px"}}>*/}
        {/*      <Select id="tenantTypeId" className="react-select" classNamePrefix="react-select" value={tenantTypeId} options={tenantTypeOpts} isClearable placeholder={""} onChange={handleTenantTypeIdChange} isDisabled={!isEditing()}/>*/}
        {/*    </div>*/}
        {/*    <InfoBlock>*/}
        {/*    </InfoBlock>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="tenantPlanId" text={intl.msg("tenant_plan")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <div style={{width: "200px"}}>*/}
        {/*      <Select id="tenantPlanId" className="react-select" classNamePrefix="react-select" value={tenantPlanId} options={tenantPlanOpts} isClearable placeholder={""} onChange={handleTenantPlanIdChange} isDisabled={!isEditing()}/>*/}
        {/*    </div>*/}
        {/*    <InfoBlock>*/}
        {/*    </InfoBlock>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="brandId" text={intl.msg("brand")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <div style={{width: "200px"}}>*/}
        {/*      <Select id="brandId" className="react-select" classNamePrefix="react-select" value={brandId} options={brandOpts} isClearable placeholder={""} onChange={handleBrandIdChange} isDisabled={!isEditing()}/></div>*/}
        {/*    <InfoBlock>*/}
        {/*    </InfoBlock>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        {/*<FormGroup>*/}
        {/*  <FormGroup.Label htmlFor="inp_flags" text={intl.msg("account_system_flags")}/>*/}
        {/*  <FormGroup.Controls>*/}
        {/*    <AsyncPaginate id="inp_flags" className="react-select" classNamePrefix="react-select" cacheOptions isMulti isClearable value={flagIds} placeholder={""} debounceTimeout={800} defaultOptions={true} loadOptions={loadFlags} onChange={handleFlagIdsChange} isDisabled={!isEditing()}/>*/}
        {/*  </FormGroup.Controls>*/}
        {/*</FormGroup>*/}

        <FormGroup>
          <FormGroup.Label htmlFor="inp_props" text={intl.msg("props")}/>
          <FormGroup.Controls>
            <textarea id="inp_props" className="form-control" style={{width: "100%"}} rows="100" value={propsJson} disabled={true}/>
            <InfoBlock>
            </InfoBlock>
          </FormGroup.Controls>
        </FormGroup>
      </ModalDialog.Body>

      <ModalDialog.Footer>
        <ModalDialog.Footer.Controls>
          <button type="button" className="btn btn-secondary" data-dismiss="modal">
            {intl.msg("cancel")}
          </button>
          <button type="button" className="btn btn-primary" onClick={handleTestClick}>
            {intl.msg("test")}
          </button>
        </ModalDialog.Footer.Controls>
      </ModalDialog.Footer>

    </ModalDialog>

  )
}