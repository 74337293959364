import React, {createContext, useContext} from 'react'
import {Alert} from "./FormComps"
import getLogger from "./Logging.js"
import {useSolv} from "./SolvProvider";
import styles from "./DialogUtils.module.css"
const log = getLogger("DialogUtils")

const ModalDialogContext = createContext({});

export function openDialog(id) {
  const el = document.getElementById(`a_${id}`)
  if (el && el.click) {
    el.click()
  }
  else {
    log.debug("openDialog: Dialog not found: id=", id)
  }
}

export function openDialogCurried(id) {
  // log.debug("openDialogCurried: defined")
  return (() => {
    // log.debug("openDialogCurried: invoked")
    const el = document.getElementById(`a_${id}`)
    // log.debug("openDialogCurried: el=", el)
    if (el && el.click) {
      el.click()
      // log.debug("openDialogCurried: dialog clicked")
    }
    else {
      log.debug("openDialogCurried: Dialog not found: id=", id)
    }
  })
}

export function closeDialog(id) {
  const el = document.getElementById(`btn_${id}_dismiss`)
  // log.debug("closeDialog: el=", el)
  if (el && el.click) {
    el.click()
  }
  else {
    log.debug("closeDialog: Dialog not found: id=", id)
  }
}

export function ModalDialog(props) {

  const { children, ...modalProps } = props;

  const dialogId = props.id || props.dialogId
  const size = props.size ? props.size : "lg"
  const backdrop = props.backdrop ? props.backdrop : "true"
  const keyboard = props.keyboard ? props.keyboard : "true"

  function handleOpenClick(e) {
    if (props.onOpen) {
      props.onOpen(e)
    }
  }

  function handleCloseClick() {
    closeDialog(dialogId)
    if (props.onClose) {
      props.onClose()
    }
  }

  // Include the function in the context value
  const contextValue = {
    ...modalProps,
    handleOpenClick,
    handleCloseClick,
  };

  return (
    <ModalDialogContext.Provider value={contextValue}>
      <a id={`a_${dialogId}`} href="#" style={{display: "none"}} data-toggle="modal" data-target={`#${dialogId}`} onClick={handleOpenClick}></a>
      <div className="modal fade" id={`${dialogId}`} tabIndex="-1" role="dialog" aria-labelledby={`#${dialogId}`} aria-hidden="true" data-keyboard={keyboard} and data-backdrop={backdrop} >
        <div className={`modal-dialog modal-dialog-scrollable modal-${size}`}>
          <button id={`btn_${dialogId}_dismiss`} data-dismiss="modal" style={{display: "none"}}></button>
          <div className="modal-content">
            {props.children}
          </div>
        </div>
      </div>
    </ModalDialogContext.Provider>
  )
}

function ModalDialogHeader(props) {

  const ctx = useContext(ModalDialogContext);

  return (
    <div className="modal-header">
      <div className="modal-title-bar">
        <div className="modal-title">
          <h1>{props.title}</h1>
          {
            props.description &&
              <small>
                {props.description}
              </small>
          }
        </div>
        <div className="modal-controls">
          {
            ctx.onClose &&
              <button type="button" className="close" onClick={ctx.handleCloseClick}>
                <span aria-hidden="true">&times;</span>
              </button>
          }
        </div>
      </div>
      {
        props?.alert &&
          <>
            <div style={{borderTop: "1px solid var(--form-divider-color)", width: "100%", padding: "5px", marginTop: "10px"}}></div>
            <div className="modal-alert mt-2">
              <Alert alert={props?.alert}/>
            </div>
          </>
      }
    </div>
  )

}

function ModalDialogBody(props) {
  const ctx = useContext(ModalDialogContext);
  return (
    <div className="modal-body">
      <form id="frmMain">
        {props.children}
      </form>
    </div>
  )
}

function ModalDialogFooter(props) {

  const ctx = useContext(ModalDialogContext);

  return (
      <div className="modal-footer">
        {props.children}
      </div>
  )

}

function ModalDialogFooterContent(props) {
  return (
    <div>
      {props.children}
    </div>
  )
}

function ModalDialogFooterControls(props) {
  return (
    <div style={{marginLeft: "auto"}} className="d-flex justify-content-end align-items-center gap-2">
    {props.children}
  </div>
)
}

ModalDialog.Header = ModalDialogHeader
ModalDialog.Body = ModalDialogBody
ModalDialog.Footer = ModalDialogFooter
ModalDialog.Footer.Content = ModalDialogFooterContent
ModalDialog.Footer.Controls = ModalDialogFooterControls

// export function ModalDialogBody(props) {
//   return (
//     <div className="modal-header">
//       <h5 className="modal-title">{props.title}</h5>
//       <button type="button" className="close" onClick={handleCloseDialogClick}>
//         <span aria-hidden="true">&times;</span>
//       </button>
//     </div>
//   )
// }