import React from 'react'

const iconClasses = {
  "info": "fas fa-info-circle",
  "attention": "fas fa-info-circle",
  "success": "fas fa-check-circle",
  "warning": "fas fa-exclamation-circle",
  "danger": "fas fa-exclamation-circle",
  "fatal": "fas fa-skull-crossbones"
}

export function AlertBox(props) {

  return (
    (props.children && props.children.length > 0) ? (
      <div style={{display: "flex", alignItems: "center", width: "fit-content"}} className={`alert alert-${props.type} ${props.className ? props.className : ""}` + (props.dismissable ? " alert-dismissible": "")}>
        <div style={{fontSize: "24px", paddingRight: "10px"}}>
          <i className={iconClasses[props.type]}></i>
        </div>
        <div>
          {props.children}
        </div>
      </div>
    ) : (
      <></>
    )  
  )
}