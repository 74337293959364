import React, { createContext,  useState, useEffect } from "react"
import axios from "axios";
import { useGoogleLogin } from "@react-oauth/google";
import getLogger from "./Logging.js"
import '../App.css'

const log = getLogger("GoogleButton")

export const GoogleButton = (props) => {

    const googleLogin = useGoogleLogin({
      // flow: "auth-code",
      onSuccess: async tokenResponse => {
        log.debug("googleLogin.onSuccess: tokenResponse=", tokenResponse)
        try {
          const userInfo = await axios.get(
              `https://www.googleapis.com/oauth2/v3/userinfo?access_token=${tokenResponse.access_token}`,
              {headers: {Authorization: `Bearer ${tokenResponse.access_token}`}},
          );
          const res = {
            code: tokenResponse.access_token,
            profileObj: userInfo.data,
          }
          return props.onSuccess(res)
        }
        catch (error) {
          props.onError(error)
        }
      },
      onError: props.onError
    });

  return (
    <button type="button" onClick={() => googleLogin()} disabled={props.disabled} style={{width: "210px", height: "44px", backgroundColor: "#fff", display: "inline-flex", alignItems: "center", color: "#fff", padding: "0px", borderRadius: "5px", border: "1px solid #ccc", fontSize: "14px", fontWeight: "700", fontFamily: "Roboto, sans-serif"}}>
      <div style={{marginRight: "0px", background: "rgb(255, 255, 255)", padding: "8px 0 8px 14px", borderRadius: "5px"}}>
          <svg width="18" height="20" xmlns="http://www.w3.org/2000/svg"><g fill="#000" fillRule="evenodd"><path d="M9 3.48c1.69 0 2.83.73 3.48 1.34l2.54-2.48C13.46.89 11.43 0 9 0 5.48 0 2.44 2.02.96 4.96l2.91 2.26C4.6 5.05 6.62 3.48 9 3.48z" fill="#EA4335"></path><path d="M17.64 9.2c0-.74-.06-1.28-.19-1.84H9v3.34h4.96c-.1.83-.64 2.08-1.84 2.92l2.84 2.2c1.7-1.57 2.68-3.88 2.68-6.62z" fill="#4285F4"></path><path d="M3.88 10.78A5.54 5.54 0 0 1 3.58 9c0-.62.11-1.22.29-1.78L.96 4.96A9.008 9.008 0 0 0 0 9c0 1.45.35 2.82.96 4.04l2.92-2.26z" fill="#FBBC05"></path><path d="M9 18c2.43 0 4.47-.8 5.96-2.18l-2.84-2.2c-.76.53-1.78.9-3.12.9-2.38 0-4.4-1.57-5.12-3.74L.97 13.04C2.45 15.98 5.48 18 9 18z" fill="#34A853"></path><path fill="none" d="M0 0h18v18H0z"></path></g></svg>
      </div>
      <span style={{color: "#000", padding: "10px 10px 10px 14px", fontWeight: "700"}}>{props.buttonText}</span>
    </button>
  )
}