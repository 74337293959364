// import { Parser, HtmlRenderer } from 'commonmark'

// const commonMarkReader = new Parser()
// const commonMarkWriter = new HtmlRenderer()

import MarkdownIt from "markdown-it"

export class Markdown {

  allowedDomains
  allowedDomainPats

  constructor(homePage, allowedDomains, disallowedDomains, allowLinks) {

    this.homePageUrl = homePage ? new URL(homePage).hostname : null
    const homePageUrl = this.homePageUrl

    this.allowedDomains = allowedDomains
    const allowedDomainPats = allowedDomains ? allowedDomains.map(d => new RegExp("^(.+?\.)?" + d.replaceAll(".", "\\.") + "$")) : null
    this.allowedDomainPats = allowedDomainPats

    this.disallowedDomains = disallowedDomains
    const disallowedDomainPats = disallowedDomains ? disallowedDomains.map(d => new RegExp("^(.+?\.)?" + d.replaceAll(".", "\\.") + "$")) : null
    this.disallowedDomainPats = disallowedDomainPats

    const opts = {
      html: false, 
      xhtmlOut: true, 
      breaks: true, 
      linkify: true === allowLinks,
      typographer:  false,  
    }

    this.mdi = new MarkdownIt(opts)
    if (!allowLinks) {
      // console.log("[MarkdownUtils]: disabled links")
      this.mdi.disable([ "link", "image"])
    }

    let defaultLinkRender = this.mdi.renderer.rules.link_open || function(tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    }

    let defaultImageRender = this.mdi.renderer.rules.image || function(tokens, idx, options, env, self) {
      return self.renderToken(tokens, idx, options);
    }

    if (true === allowLinks) {
      this.mdi.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        let token = tokens[idx]
        let aIndex = token.attrIndex("target")
        if (aIndex < 0) {
          token.attrPush(["target", "_blank"]) 
        } 
        else {
          token.attrs[aIndex][1] = "_blank"
        }
        return defaultLinkRender(tokens, idx, options, env, self)
      }
    }
    else {
      this.mdi.renderer.rules.link_open = function (tokens, idx, options, env, self) {
        return ""
      }
    }

    if (true === allowLinks) {
      this.mdi.renderer.rules.image = function (tokens, idx, options, env, self) {
        let token = tokens[idx]
        let alt = token.content
        if (token.attrIndex("title") < 0) {
          token.attrPush(['title', alt]) 
        }
        return defaultImageRender(tokens, idx, options, env, self)
      }
    }
    else {
      this.mdi.renderer.rules.image = function (tokens, idx, options, env, self) {
        return ""
      }
    }

    this.mdi.validateLink = function (href) { 
      try {
        const url = new URL(href)

        if ("https:" !== url.protocol) {
          return false
        }

        if (homePageUrl?.trim() == url.hostname) {
          return true
        }
        else if (allowedDomainPats || disallowedDomainPats) {
          if (allowedDomainPats) {
            return (allowedDomainPats.some(p => {return p.test(url.hostname)}))
          }
          if (disallowedDomainPats) {
            return (!disallowedDomainPats.some(p => {return p.test(url.hostname)}))
          }
        }
        else {
          return true
        }
      }
      catch (e) {
        return false
      }
    }

  }


  toHtml(md) {
    // const parsed = commonMarkReader.parse(md)
    // const result = commonMarkWriter.render(parsed)        
    const result = this.mdi.render(md)
    return result
  }
  
}
