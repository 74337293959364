import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import './App.css'

export default function Page403() {

  return (
    <Fragment>
        <main>

          <div class="jumbotron m-3">
            <center>
              <h1 style={{fontSize: "4.5rem"}}><i class="fas fa-cloud-download-alt"></i></h1>
              {/* <h1 style={{fontSize: "2.5rem", color: "#999"}}>426</h1> */}
              <h1 style={{fontSize: "3rem", color: "#fff", marginBottom: "30px"}}>Upgrade Required</h1>
              <p className="lead" style={{color: "#ccc"}}>
                A new version of the SOLV Console is available! Please click 'Upgrade' to get the latest version.
                <div className="mt-4"></div>
                <a className="btn btn-primary" href="javascript:window.location.reload(true);"><i className="fas fa-cloud-download-alt"></i> Upgrade</a>
              </p>
            </center>
          </div>            

        </main>
      </Fragment>
    )
}
