import React, {useEffect, useState} from 'react'
import {useParams}  from 'react-router-dom'
import {useSolv} from "../components/SolvProvider";
import {useIntlEx} from "../components/IntlUtils";
import {MainContainer} from "../components/FormComps.js"
import {PageDialogFooter, PageDialogHeader} from "../solv/PageDialogUtils";
import {PageDialog, PageDialogContainer, PageDialogMainPanel} from "../components/PageDialog";
import {Alert} from "../components/FormComps"
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("EmailAddressChanged")

export default function EmailAddressChanged(props) {

  const {api, setBusy, setFatal} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (params.magicCode) {
      updateSignin(params.magicCode)
    }
    else {
      window.location = "/signin"
    }
  }, [])

  function updateSignin(magicCode) {
    log.debug("updateSignin: magicCode=", magicCode)
    setBusy(intl.msg("loading"))
    api.updateSignin(magicCode)
      .then(({data}) => {
        setAlert({success: intl.msg("member_change_email_address_success_text")})
      })
      .catch((error) => {
        setAlert({error: intl.msg("error_failed")})
      })
      .finally(() => {
        setBusy(null)
      })
  }

  return (
    <MainContainer>
      <PageDialogContainer>
        <PageDialog
          header={PageDialogHeader}
          footer={PageDialogFooter}
          size="md">
          <PageDialogMainPanel>
            <Alert alert={alert}/>
            <p className="p-3">
              Please sign in with new email address.
            </p>
            <a className="btn btn-primary" href="/signin">
              {intl.msg("signin")}
            </a>
          </PageDialogMainPanel>
        </PageDialog>
      </PageDialogContainer>
    </MainContainer>
  )
}
