import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useMutation } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useSolv, ADMIN_USER_LEVEL, BASIC_USER_LEVEL } from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import { InfoBlock, InfoText } from "./components/ValidationUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkAccountDocUrl} from "./AccountTabs";

const log = getLogger("TemplateCloneDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function TemplateCloneDialog(props)  {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [tenant, setTenant] = useState(null)
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null)
  const [broadcastTemplateName, setBroadcastTemplateName] = useState(null)

  const params = useParams()

  const [touched, setTouched] = useState(true)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
      }
      if (props.broadcastTemplateId) {
        setBroadcastTemplateId(props.broadcastTemplateId)
      }
      if (props.broadcastTemplateName) {
        setBroadcastTemplateName(`Copy of ${props.broadcastTemplateName}`)
      }
    }
  }, [props])

  function validate() {
    return true
  }
    
  async function handleCloneClick() {

    try {

      setAlert(null)

      if (validate()) {

        setBusy(intl.msg("working"))

        let payload = {
          broadcastTemplateName: broadcastTemplateName,
        }

        log.debug("handleCloneClick: payload=", payload)

        const {data} = await api.cloneBroadcastTemplate(tenant.tenantId, broadcastTemplateId, payload)

        log.debug("handleCloneClick: data=", data)

        const url = mkAccountDocUrl(user, tenant, "templates", data.broadcastTemplateId)

        log.debug("handleCloneClick: url=", url)

        setTouched(false)

        window.location = url

      }
    }
    catch (error) {
      handleError(error)
    }
    finally {
      setBusy(null)
    }
  }
  
  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("template_error_already_exits")})
      }
      else {
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
          setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleOpenDialog() {
    setAlert(null)
    setBusy(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_template_clone")
  }

  return (

    <ModalDialog
      dialogId="dlg_template_clone"
      header={<h1>{intl.msg("template_clone_title")}</h1>}
      footer={
        <>
          {
            touched &&
              <button type="button" className="btn btn-primary" onClick={handleCloneClick}>{intl.msg("clone")}</button>
          }
          <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
        </>
      }
      size="lg"
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}
      alert={alert}>

      <form id="frmMain" autoComplete="off">

        <div className="form-card">

          <div className="row">

            <div className="col">

              <label className="text">{intl.msg("template_clone_text")}</label>

              <div className="form-group row">
                <label className="col-sm-3 col-form-label" style={{whiteSpace: "nowrap"}} htmlFor="inp_emailAddress">{intl.msg("template_clone_new_template_name")}</label>
                <div className="col-sm-9">
                <input id="inp_broadcastTemplateName" name="broadcastTemplateName" type="text" className="form-control" value={broadcastTemplateName} disabled={false} onChange={(e) => {setBroadcastTemplateName(e.target.value); setTouched(true)}} autoComplete="no"/>
                <InfoBlock>
                  <InfoText>{intl.msg("template_clone_new_template_name_helptext_1")}</InfoText>
                </InfoBlock>
                </div>
              </div>

            </div>

          </div>

        </div>

      </form>

    </ModalDialog>
  )
}