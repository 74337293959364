export function rangeMap(start, end, cbfn) {
  let r = []
  for (var i = start; i <= end; i++) {
    r.push(i);
  }
  return r.map(r => {
    return cbfn(r)
  })
}

export const uuidRegex = /^[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12}$|^[0-9a-fA-F]{32}$/;

