import React, { Fragment, useState, useRef }  from 'react'
import { useHistory, useLocation }  from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { v4 as uuidv4 } from 'uuid'
import { useSolv } from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils.js"
import { clearAllErrors, InfoBlock, InfoText} from "./components/ValidationUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CustomFieldCloneDalog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CustomFieldCloneDialog(props)  {

  const { api, auth: { session: { user, tenant } } } = useSolv()
  const { intl } = useIntlEx()

  const location = useLocation()
  const history = useHistory()
    
  const [fieldLabel, setFieldLabel] = useState(null)
  const btnCloseRef = useRef(null)
  
  const [touched, setTouched] = useState(true)
  const [working, setWorking] = useState(null)
  const [info, setInfo] = useState(null)
  const [error, setError] = useState(null)

  function validate() {
  
    let opt = {focusEl: null, valid: true}

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }
    // }

    return opt.valid
  }

  function resetState() {
    setWorking(null)
    setError(null)
    clearAllErrors(`frmMain-${props.id}`)
  }
  
  async function handleApplyClick() {
    if (validate()) {
      try {
        setWorking(intl.msg("working"))
        let g = {...props.field, id: uuidv4().replace(/[\-]+/g,''), label: fieldLabel}
        // if (props.fields && props.fields.length > 1) {
          let index = props.fields.findIndex(e => e.id === props.field.id)
          props.setFields([
            ...props.fields.slice(0, index + 1),
            g,
            ...props.fields.slice(index + 1),
          ])
        // }
        // else {
        //   props.setFields([g])
        // }
        props.setTouched(true)
        setTouched(false)
        resetState()
        btnCloseRef.current.click()
      }
      finally {
        setWorking(null)
      }
    }
    else {
      setError(intl.msg("error_failed"))
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug(">>>handleError", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setError(intl.msg("template_field_duplicate_label", {type: props.dialogType}))
      }
      else {
        setError(intl.msg("error_failed"))
      } 
    }
    else {
      setError(intl.msg("error_failed"))
    }
  }  

  function handleFieldLabelChange(e) {
    setFieldLabel(e.target.value)
    setTouched(true)
  }    

  function isEditable() {
    return true
  }

  return (

      <Fragment>

        <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
          <p>{working}</p>
        </div>

        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby={props.id} aria-hidden="true">
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">{intl.msg("template_field_clone_title", {type: props.dialogType})}</h5>
                <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div className="modal-body">
              
                <div className="row">

                  <div className="col">

                    {
                        error &&
                        <div className="alert alert-danger" style={error ? {display: "block"} : {display: "none"}}>
                        <span>{error}</span>
                        </div>
                    }

                    { info &&
                        <div className="alert alert-dismissible alert-success" style={info ? {display: "block"} : {display: "none"}}>
                        <span>{info}</span>
                        </div>
                    }

                    <form id={`frmMain-${props.id}`} autoComplete="off">

                      <div className="form-card">
                            
                        <div className="row">
                                    
                          <div className="col-12">

                            <div className="form-group row">
                              <label className="col-sm-3 col-form-label" htmlFor={`inp_fieldLabel_${props.id}`}>{intl.msg("template_field_clone_new_template_name")}</label>
                              <div className="col-sm-9">
                                <input id={`inp_fieldLabel_${props.id}`} name="label" type="text" className="form-control" value={fieldLabel} placeholder={props.field.label} disabled={false} onChange={handleFieldLabelChange} autoComplete="no"/>
                                <small>
                                  <InfoBlock>
                                    <InfoText>{intl.msg("template_field_clone_new_template_name_helptext_1")}</InfoText>
                                    <InfoText validate="unique">{intl.msg("template_field_clone_new_template_name_helptext_2", {type: props.dialogType})}</InfoText>
                                    <InfoText validate="non-empty">{intl.msg("helptext_required")}</InfoText>
                                  </InfoBlock>
                                </small>                          
                              </div>    
                            </div>

                          </div>

                        </div>

                      </div>

                    </form> 
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button ref={btnCloseRef} id={`btn_close_${props.id}`} type="button" className="btn btn-secondary" data-dismiss="modal" onClick={resetState}>Cancel</button>
                {
                  touched &&
                    <button id={`btn_apply_${props.id}`} type="button" className="btn btn-primary" onClick={handleApplyClick}>Clone</button>
                }
              </div>
            </div>
          </div>
        </div>

      </Fragment>
    )
}