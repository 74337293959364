

export function getAllIndustryOpts(intl) {
  return (
      [
        {
          value: "PUBLIC_SAFETY",
          label: intl.msg("industry_public_safety")
        },
        {
          value: "SME",
          label: intl.msg("industry_sme")
        },
        {
          value: "REAL_ESTATE",
          label: intl.msg("industry_real_estate")
        },
        {
          value: "OTHER",
          label: intl.msg("industry_other")
        },
      ]
  )
}

export function getIndustryOpt(industryId, intl) {
  return getAllIndustryOpts(intl).find(i => i.value === industryId)
}