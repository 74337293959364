import React, { useEffect, useState }  from 'react'
import { useHistory } from 'react-router-dom'
import getLogger from "./Logging.js"

const log = getLogger("PrefUtils")

const PREFS_COOKIE_NAME = "SOLV-PREFS"

export function usePrefs() {

  const history = useHistory()
  const [prefs, setPrefs] = useState(null)

  useEffect(() => {
    log.debug("Loading prefs")
    let p = localStorage.getItem(PREFS_COOKIE_NAME)
    if (p) {
        p = JSON.parse(p)
        log.debug("loadPrefs: prefs=", p)
        setPrefs(p)
    }
  }, [])

  const savePrefs = (p, reload) => {
    log.debug("savePrefs: prefs=", p)
    localStorage.setItem(PREFS_COOKIE_NAME, JSON.stringify(p))
    setPrefs(p)
    if (reload) {
      window.location.reload(false)
    }
  }

  return [prefs, savePrefs]
}