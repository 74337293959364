import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import { useSolv } from "./components/SolvProvider"
import MainMenu from "./MainMenu"
import getLogger from "./components/Logging"
import './App.css'
import './SignInSignUp.css'

const log = getLogger("Welcome")

export default function Welcome(props) {

  const { auth: { session: { user, tenant } } } = useSolv()

  const location = useLocation()

  const [working, setWorking] = useState(null)
  const [error, setError] = useState(null)

  const [welcome, setWelcome] = useState("?welcome" === location.search)
  const [redirect, setRedirect] = useState(null)

  function WelcomeDialog(props) {

    useEffect(() => {


      if (user && user.isClientUser()) {
        const btn = document.getElementById("btn_welcome")
        if (btn) {
          btn.click()
        }
      }
      else {
        gotoStartPage()
      }
      
  
    }, [])

    function handleCreateBroadcastClick() {
      setRedirect("/broadcasts/_new")
    }
  
    function handleCloseClick() {
      gotoStartPage()
    }

    function gotoStartPage() {
      if (user.isSystemUser() || user.isClientUser()) {
        setRedirect("/broadcasts")
      }
      else if (user.isResellerUser()) {
        setRedirect("/clients")
      }
      else {
        setRedirect("/")
      }
    }

    return (
      <>
        <div className="modal fade" id="dlg_welcome" tabIndex="-1" role="dialog" data-backdrop="static" aria-labelledby="#dlg_welcome" aria-hidden="true">
          <div className="modal-dialog modal-center" role="document">
            <div className="modal-content modal-center-center">

              {/* <div className="signin-signup-container">

                <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
                  <p>{working}</p>
                </div>

                <div className="signin-signup-card">
                  Hello! WTF is this?
                </div>

              </div> */}

              <div className="modal-header">
                <center style={{width:"100%"}}><h5 className="modal-title" id="dlg_details">Welcome to SOLV!</h5></center>
                {/* <button type="button" className="close" data-dismiss="modal" aria-label="Close" onClick={handleCloseClick}>
                  <span aria-hidden="true">&times;</span>
                </button> */}
              </div>
              <div className="modal-body">
                <section className="main">
                  <div className="row">
                    <div className="col">
                      <center>
                        <label style={{color: "#fff"}}>Congrats {user.fullName}, you're all set!</label>
                        <p>What would you like to do next?</p>
                      </center>
                    </div>  
                  </div>
                </section>
              </div>
              <div className="modal-footer">
                <label>I want to</label>
                <button type="button" className="btn btn-primary" data-dismiss="modal" onClick={handleCreateBroadcastClick}>Create My First Broadcast</button>
                <button type="button" className="btn btn-secondary" data-dismiss="modal" onClick={handleCloseClick}>Start Exploring</button>
              </div>            
              
            </div>
          </div>
        </div> 
      </>
    )
  }

  return (
    redirect ? (
      <Redirect to={redirect} push={false}/>  
    ) : (
      <>

        <MainMenu/>

        <main>

          <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
            <p>{working}</p>
          </div>

          <WelcomeDialog setRedirect={setRedirect}/>
          <button id="btn_welcome" data-toggle="modal" data-target="#dlg_welcome" style={{display: "none"}}>Welcome</button>

        </main>

      </>
    )
  ) 
}