import React, {forwardRef, useEffect, useRef, useState} from 'react'
import {useResizeDetector} from 'react-resize-detector'
import {useIntlEx} from "./IntlUtils";
import {useSolv} from "./SolvProvider";
import {PageDialog, PageDialogContainer, PageDialogMainPanel} from "./PageDialog";
import {useLocation} from "react-router-dom";
import {showError, validateUrl} from "./ValidationUtils";
import {shortIdEqual} from "./StringUtils";
import {parsePathname} from "./UrlUtils";
import {booleanPropIsTrue} from "./ReactUtils";
import getLogger from "./Logging.js"
import '../App.css'
import styles from "./FormComps.module.css"

const iconClasses = {
  "info":      <span className="fa-layers mr-1">
                 <i className="far fa-circle"></i>
                 <i className="fas fa-info" data-fa-transform="shrink-8 right-0.2"></i>
               </span>,
  "attention": <span className="fa-layers mr-1">
                 <i className="far fa-circle"></i>
                 <i className="fas fa-info" data-fa-transform="shrink-8 right-0.2"></i>
               </span>,
  "success":   <span className="fa-layers mr-1">
                 <i className="far fa-circle"></i>
                 <i className="fas fa-check" data-fa-transform="shrink-8 right-0.2"></i>
               </span>,
  "warning":   <span className="fa-layers mr-1">
                 <i className="far fa-circle"></i>
                 <i className="fas fa-exclamation" data-fa-transform="shrink-8 right-0.2"></i>
               </span>,
  "error":     <i className="fas fa-ban"></i>,
  "fatal":     <i className="fas fa-ban"></i>,
}

const log = getLogger("FormComps")

export function useBusySpinner(props) {

  const { intl } = useIntlEx()

  const [busy, setBusy] = useState(null)

  function BusySpinner(props) {
    // log.debug("useBusySpinner: props=", props)
    return (
      <div className={styles.busy_spinner} style={{display: (busy ? "flex" : "none")}}>
        <div className={styles.busy_spinner_body}>
          <img src="/images/spinner-white.svg"/>
          {
            busy && (
              "boolean" === typeof(busy) ? (
                intl.msg("loading")
              ) : (
                busy
              )
            )
          }
        </div>
      </div>
    )
  }

  return (
    {
      busy,
      setBusy,
      BusySpinner
    }
  )
}

export function useAlert(props) {

  const [alert, _setAlert] = useState(props ? props : null)
  function setAlert(a) {
    // log.debug("setAlert: a=", a)
    _setAlert(a)
    if (a?.field) {
      showError(a.field, a.constraint || "required")
    }
  }

  return (
    [
      alert,
      setAlert
    ]
  )
}

export function Alert(props) {

  const {intl} = useIntlEx()

  let type, text, title
  if (props) {
    if (props.alert) {
      const alert = props.alert
      if (alert.type) {
        type = alert.type
        text = alert.text
        title = alert.title
      }
      else {
        const fields = ["attention", "fatal", "error", "info", "success", "warning"]
        const i = fields.findIndex((field) => alert.hasOwnProperty(field))
        type = fields[i]
        text = alert[fields[i]]
        title = alert.title
      }
    }
    else {
      type = props.type
      title = props.title
    }
  }
  // if (!title && type) {
  //   title = intl.msg(type)
  // }
  const align = props.align ? `talign-${props.align}` : "talign-left"
  const whitespace = props.whitespace ? `whitespace-${props.whitespace}` : ""
  return (
    (text || props.children) ? (
      <div className="d-flex justify-content-center align-items-center">
        <div className={props.className}>
          <div className={`${styles.alert_container} ${styles[`alert_${type}`]} ${align} ${whitespace}`} data-alert={type}>
            <div className={`${styles.alert_icon_container}`} data-alert={type}>
            {/*<div className={`${styles.alert_icon_container} ${title ? "align-items-start" : "align-items-center"}`} data-alert={type}>*/}
              {iconClasses[type]}
            </div>
            <div className={styles.alert_body_container} data-alert={type}>
              {
                title &&
                  <div className={`${styles.alert_title_container} ${align}`} data-alert={type}>
                    {title}
                  </div>
              }
              <div className={`${styles.alert_content_container} ${align}`} data-alert={type}>
                {
                  props.children ? (
                    <div>
                      {props.children}
                    </div>
                  ) : (
                    <div>{text}</div>
                  )
                }
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <></>
    )
  )
}

export function FormHeader(props) {

  const { width, height, ref } = useResizeDetector()

  return (
    <>
      <nav ref={ref} className={`${styles["form-header"]} fixed-top`}>
        {props.children}
      </nav>
      <div className={styles["form-header-push"]} style={{height: height}}></div>
    </>
  )

}

function FormHeaderToolbar(props) {
  return (
    <div className={styles["toolbar"]}>
      {props.children}
    </div>
  )
}

function FormHeaderToolbarTitle(props) {
  return (
    <div className={styles["title"]}>
      {props.children}
    </div>
  )
}

function FormHeaderToolbarControls(props) {
  return (
    <div className={styles["controls"]}>
      {props.children}
    </div>
  )
}

function FormHeaderAlerts(props) {
  return (
    (props.children && props.children.length > 0) ? (
      <>
        <div className={styles["divider"]}/>
        <center className={styles["alerts"]}>
          {props.children}
        </center>
      </>
    ) : (
      <></>
    )
  )
}

function FormHeaderSection(props) {
  return (
    props.children &&
      <div className={styles.section} style={{padding: "var(--form-header-padding-height)", borderTop: "1px solid var(--form-header-divider-color)"}}>
        {props.children}
      </div>
  )
}

function FormHeaderAlert(props) {
  return (
    (props?.alert) ? (
      <div style={{padding: "var(--form-header-padding-height)", borderTop: "1px solid var(--form-header-divider-color)"}}>
        <Alert alert={props.alert}/>
      </div>
    ) : (
      <></>
    )
  )
}

function FormHeaderContent(props) {
  return (
    <>
      <div className={styles["divider"]}/>
      <div className={styles["content"]}>
        {props.children}
      </div>
    </>
  )
}

function FormHeaderTabs(props) {
  const tp = `tabs-position-${props.position ? props.position : "bottom"}`
  return (
    <>
      {
        props.title &&
          <div className={styles["tabs-title"]}>
            {props.title}
          </div>
      }
      {
        props.children &&
          <ul className={`${styles["tabs"]} ${styles[tp]}`} id={props.id ? props.id : ""} role="tablist">
            {props.children}
          </ul>
      }
    </>
  )
}

function handleTabClick(e) {

}

function FormHeaderTab(props) {
  return (
    <>
      <li className={styles["tab"]} role="presentation">
        <a className={`${styles["tab-link"]} ${(props.active === true) ? styles["active"] : ""}`} id={props.id ? props.id : ""} href={props.href} role="tab" aria-selected={props.active === true ? "true" : "false"}>{props.title}</a>
      </li>
    </>
  )
}

FormHeader.Toolbar = FormHeaderToolbar
FormHeader.Toolbar.Title = FormHeaderToolbarTitle
FormHeader.Toolbar.Controls = FormHeaderToolbarControls
FormHeader.Section = FormHeaderSection
FormHeader.Alerts = FormHeaderAlerts
FormHeader.Alert = FormHeaderAlert
FormHeader.Content = FormHeaderContent
FormHeader.Tabs = FormHeaderTabs
FormHeader.Tab = FormHeaderTab

export function FormBody(props) {
  return (
    <div className={`${styles["form-body"]} container-fluid`}>
      <div className="row">
        <div className="col">
          <form id={props.id || "frmMain"} autoComplete={props.autoComplete || false}>
            {props.children}
          </form>
        </div>
      </div>
    </div>
  )
}

function FormBodyDivider(props) {
  return (
    <div style={{margin: "28px 0 24px 0", borderTop: "1px solid var(--form-divider-color)"}}>
    </div>
  )
}

FormBody.Divider = FormBodyDivider

export function FormControl(props) {
  return (
    <></>
  )
}

function FormControlGroup(props) {
  return (
    <div className={`form-group ${booleanPropIsTrue(props.disabled) ? "readonly" : ""}`}>
      <div className="row">
        {props.children}
      </div>
    </div>
  )
}

function FormControlLabel(props) {
  return (
    <label className={`${props.className} ${styles["form-label"]}`} htmlFor={props.htmlFor}>
      <p>
        <span className={styles["form-label-label"]}>{props.children}</span>
        <span className={styles["form-label-delimiter"]}>:</span>
      </p>
      {
        (props.subtitle || props.description) &&
          <small>
            {props.subtitle || props.description}
          </small>
      }
    </label>
  )
}

FormControl.Group = FormControlGroup
FormControl.Label = FormControlLabel

export function FormGroups(props) {
  return (
    <div className={`form-groups ${props.className ? props.className : ""}`}>
      <div className="row">
        <div className="col">
          {
            props.title &&
              <h1>{props.title}</h1>
          }
          {props.children}
        </div>
      </div>
    </div>
  )
}

export function FormGroup(props) {
  return (
    <div className={`form-group ${booleanPropIsTrue(props.disabled) ? "readonly" : ""} ${props.className ? props.className : ""}`}>
      <div className="row">
        {props.children}
      </div>
    </div>
  )
}

function FormGroupLabel(props) {
  return (
    <div className="col-12">
      <label className={`${props.className} ${styles["form-label"]}`} htmlFor={props.htmlFor}>
        {
          props.text ? (
            <p>
              <span className={styles["form-label-label"]}>{props.text}</span>
              <span className={styles["form-label-delimiter"]}>:</span>
            </p>
          ) : (
            <p>
              <span className={styles["form-label-label"]}>{props.children}</span>
              <span className={styles["form-label-delimiter"]}>:</span>
            </p>
          )
        }
        <small>
          {props.subtitle || props.description}
          {
            props.help &&
              <a className="icon-link ml-2" title={props.help.tooltip || ""} href={`https://help.solv.technology/article/${props.help.slug}`} target="_blank">
                <span className="fa-layers">
                  <i className="far fa-circle" style={{color: "var(--form-control-border-color)"}}
                     data-fa-transform="grow-4"></i>
                  <i className="fas fa-question" data-fa-transform="shrink-6"></i>
                </span>
              </a>
          }

        </small>
      </label>
    </div>
  )
}

function FormGroupControl(props) {
  return (
    <div className="col-12">
      {props.children}
    </div>
  )
}

function FormGroupControls(props) {
  return (
    <div className="col-12">
      {props.children}
    </div>
  )
}

FormGroup.Label = FormGroupLabel
FormGroup.Control = FormGroupControl
FormGroup.Controls = FormGroupControls

export function Loading(props) {
  return (
    <div className={styles.loading}></div>
  )
}

export function MainContainer(props) {

  const {brandId, env, busy, fatal, setFatal} = useSolv()
  const {intl} = useIntlEx()

  // const [tenant, setTenant] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      if (typeof(props.allowIf) === "boolean" && props.allowIf === false) {
          setFatal("INSUFFICIENT_PERMISSION")
      }
      if (props.allowIfAccessingAs) {
        if (props.tenant.accessingAs && !props.tenant.accessingAs(props.allowIfAccessingAs)) {
          setFatal("INSUFFICIENT_PERMISSION")
        }
      }
    }
  }, [props.tenant]);

  const fatalTypes = {
    DENIED_ACCESS: {
      title: intl.msg("fatal_error_access_denied_title"),
      description: intl.msg("fatal_error_access_denied_text"),
      iconClassName: "fas fa-ban text-warning",
      buttons: [{type: "SIGNIN", buttonClass: "btn-secondary"}]
    },
    INSUFFICIENT_PERMISSION: {
      title: intl.msg("fatal_error_access_denied_title"),
      description: intl.msg("fatal_error_access_denied_text"),
      iconClassName: "fas fa-ban text-warning",
      buttons: [{type: "SIGNIN", buttonClass: "btn-secondary"}]
    },
    UNAVAILABLE: {
      title: intl.msg("fatal_error_unavailable_title"),
      description: intl.msg("fatal_error_unavailable_text"),
      iconClassName: "fas fa-ban text-warning",
      buttons: [{type: "RETRY", buttonClass: "btn-secondary"}]
    },
    NOT_FOUND: {
      title: intl.msg("fatal_error_not_found_title"),
      description: intl.msg("fatal_error_not_found_text"),
      iconClassName: "fas fa-question-circle text-warning",
      buttons: [{type: "BACK", buttonClass: "btn-secondary"}]
    },
    OOPS: {
      title: intl.msg("fatal_error_oops_title"),
      description: intl.msg("fatal_error_oops_text"),
      iconClassName: "fas fa-frown text-warning",
      buttons: [{type: "RETRY", buttonClass: "btn-secondary"}]
    }
  }

  function FatalError() {
    let ft
    if (typeof(fatal) === "string") {
      ft = fatal
    }
    else if (fatal.type) {
      ft = fatal.type
    }
    else if (fatal.code) {
      if (fatal.code === "INSUFFICIENT_PERMISSION") {
        ft = "DENIED_ACCESS"
      }
      else {
        ft = "OOPS"
      }
    }
    else {
      ft = "OOPS"
    }
    let f
    if (ft in fatalTypes) {
      f = fatalTypes[ft]
    }
    else {
      f = fatalTypes["OOPS"]
    }
    // log.debug("FatalError: ft,f=", ft, f)

    return (
      fatal &&
        <PageDialogContainer className="mt-4 justify-content-start">
          <PageDialog size="md">
            <PageDialogMainPanel>
              <h1 style={{fontSize: "4.5rem"}}><i className={f.iconClassName}></i></h1>
              <h1 style={{fontSize: "3rem", marginBottom: "30px"}}>{f.title}</h1>
              <p className="lead">
                {f.description}<br/>
                <div className="mt-4"></div>
                {
                  f.buttons.map ( button =>
                      <>
                        {
                          button.type == "BACK" ? (
                            <a className={`btn ${button.buttonClass} mr-1`} style={{fontSize: "1.171875rem"}} href="javascript:window.history.back()">{intl.msg("go_back")}</a>
                          ) : button.type == "RETRY" ? (
                            <a className={`btn ${button.buttonClass} mr-1`} style={{fontSize: "1.171875rem"}} href="javascript:window.location.reload()">{intl.msg("retry")}</a>
                          ) : button.type == "HOME" ? (
                            <a className={`btn ${button.buttonClass} mr-1`} style={{fontSize: "1.171875rem"}} href="/">{intl.msg("home")}</a>
                          ) : button.type == "SIGNIN" ? (
                            <a className={`btn ${button.buttonClass} mr-1`} style={{fontSize: "1.171875rem"}} href="/signin">{intl.msg("signin_as_another")}</a>
                          ) : (
                            <></>
                          )
                        }
                      </>
                  )
                }
                <a className={`btn btn-secondary`} style={{fontSize: "1.171875rem"}} href={`${env.HELP_ENDPOINT}`} target="_help">{intl.msg("help")}</a>
              </p>
            </PageDialogMainPanel>
          </PageDialog>
        </PageDialogContainer>
    )
  }

  return (
    <>
      <div className={styles.busy_spinner} style={{display: (busy ? "flex" : "none")}}>
        <div className={styles.busy_spinner_body}>
          <img src="/images/spinner-light.svg" width="100" height="auto"/>
          {
              busy && (
                  "boolean" === typeof(busy) ? (
                      intl.msg("loading")
                  ) : (
                      busy
                  )
              )
          }
        </div>
      </div>
      <div className={brandId ? `${brandId.toLowerCase()}-skin` : ""} style={{height: "100vh", padding: "0", background: "var(--main-background)"}}>
        {
          props.menu &&
            props.menu({tenant: props.tenant})
        }
        {
          fatal ? (
            <FatalError/>
          ) : (
            props.children
          )
        }
      </div>
    </>
  )
}

export function TenantFormTitle(props) {
  const tenant = props.tenant
  if (tenant) {
    if (tenant.accessingAs("*/MEMBER/*")) {
      return (
        <h1 className="d-flex flex-row">{props.children}</h1>
      )
    }
    else {
      return (
        <div className="d-flex flex-column">
          <p>{tenant.tenantName}</p>
          <h1 className="d-flex flex-row">{props.children}</h1>
        </div>
      )
    }
  }
  else {
    return (
      <h1 className="d-flex flex-row">{props.children}</h1>    )
  }
}

function matchPath(matchType, href, pathname) {
  // log.debug(`matchPath: matchType=${matchType}, href=${href}, pathname=${pathname}`)
  switch (matchType) {
    case "EXACT":
      return (pathname === href)
    case "START":
    case "STARTSWITH":
      return (pathname.startsWith(href))
    case "END":
    case "ENDSWITH":
      return (pathname.endsWith(href))
    case "CONTAINS":
      return (pathname.indexOf(href) !== -1)
    default:
      return (pathname === href)
  }
}

export const Anchor = forwardRef((props, ref) => {
  const location = useLocation()
  let pathname = location.pathname
  let p = {}
  let active = false
  let match = props.match
  if (props.active !== undefined) {
    active = props.active
  }
  else {
    const parseResult = parsePathname(pathname)
    // log.debug("Anchor: parseResult=", parseResult)
    if (parseResult.tenantId) {
      if (shortIdEqual(props?.user?.tenantId, parseResult.tenantId)) {
        active = matchPath(match, props.href, parseResult.path)
      }
      else {
        switch (props.tenant?.tenantTypeId) {
          case "CLIENT":
            active = (props.href === "/clients")
            break
          case "RESELLER":
            active = (props.href === "/resellers")
            break
          default:
        }
      }
    }
    else {
      // log.debug("Anchor: ", match, props.href, parseResult)
      active = matchPath(match, props.href, parseResult.path)
    }
  }
  if (active) {
    p.className = (props.activeClassName ? props.activeClassName : "active")
    p["aria-current"] = "page"
  }
  p.href = props.href
  return (
    <a {...p} ref={ref}>
      {props.children}
    </a>
  )
})

export function ExternalLink(props) {

  const [iconVisible, setIconVisible] = useState()

  function handleMouseLeave(e) {
    setIconVisible(false)
  }

  function handleMouseEnter(e) {
    setIconVisible(true)
  }

  return (
    <span>
    {/*<span className="d-flex align-items-center gap-1">*/}
      <a className="text-link" href={props.href} title={props.href} target="_blank" onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
        <span>{props.children}</span>
      </a>
      <span key="icon-span" className="ml-1">
        {/*style={{visibility: iconVisible ? "visible" : "hidden"}}>*/}
        <i key="icon" className="fas fa-external-link-alt" style={{fontSize: "8pt"}}></i>
      </span>
    </span>
  )
}

export function ExternalLinkButton(props) {
  const {intl} = useIntlEx()
  return (
    <button key={props.key} className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title={props.title || props.href} disabled={!validateUrl(props.href, {relaxed: true})} onClick={(e) => window.open(props.href)}><i className="fas fa-external-link-alt"></i></button>
  )
}

export function RegionLink(props) {
  if (props.region) {
    if ("SYSTEM" === props?.session?.user?.userTypeId) {
      return (
        <a className="text-link" href={`/sys/regions/${props.region.regionId.toShortId()}`} target="_blank">{props.region.regionName}</a>
      )
    }
    else {
      return (
        <span>{props.region.regionName}</span>
      )
    }
  }
  else {
    return <></>
  }
}

export function SectionHeader(props) {
  const {intl} = useIntlEx()
  return (
    <div className={`${styles.section_header} ${props.className ? props.className : ""}`}>
      <h1>{props.title}</h1>
      {
        props.text &&
          <p>{props.text}</p>
      }
    </div>
  )
}

export function SelectList(props) {
  const [value, setValue] = useState()

  useEffect(() => {
    setValue(props.value)
  }, []);

  function handleClick(v) {
    setValue(v)
    if (props.onChange) {
      props.onChange(v)
    }
  }

  return (
    <ul className={styles["select-list"]}>
      {
        props.options.map(i => {
          return (
            <li>
              <div className={styles["select-list-item"]} data-item={i} data-selected={i.value === value?.value ? "true" : "false"} onClick={() => handleClick(i)}>
                {i.label}
              </div>
            </li>
          )
        })
      }
    </ul>
  )
}

export function UrlInput(props) {

  const [value, setValue] = useState(props.value)

  function handleChange(e) {
    let url = e.target.value.trim()
    setValue(url)
    if (props.onChange) {
      props.onChange({target: {value: url}})
    }
  }

  function handleBlur(e) {
    let url = value
    if (url?.length > 0) {
      try {
        if (value.startsWith("http://")) {
          url = `https://${url.substring(7)}`
        }
        else if (!url.startsWith("https://")) {
          url = `https://${url}`
        }
        // log.debug("handleClickUrlUrlBlur: url2=", url)
        setValue(url)
        if (props.onChange) {
          props.onChange({target: {value: url}})
        }
      }
      catch (e) {
      }
    }
  }

  function handleKeyDown(e) {
    if (e && e.key === "Enter") {
      handleBlur(e)
    }
  }

  return (
    <div className="input-group">
      <input id={props.id} type="text" className="form-control" value={value} onChange={handleChange} onBlur={handleBlur} onKeyDown={handleKeyDown}/>
      <div className="input-group-append">
        <button className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="View page in new browser tab" onClick={(e) => window.open(value)} disabled={!validateUrl(value)}><i className="fas fa-external-link-alt"></i></button>
      </div>
    </div>
  )
}

export function RangeSlider(props) {

  const [value, setValue] = useState(props.value)

  function handleChange(e) {
    let v = e.target.value
    setValue(v)
    if (props.onChange) {
      props.onChange(v)
    }
  }

  return (
    <div>
      <input className={styles["range-slider"]} id={props.id} type="range" min={props.min} max={props.max} step={props.value} value={value} onChange={handleChange}  list="markers"/>
      <datalist id="markers">
        <option value="0"></option>
        <option value="10"></option>
        <option value="20"></option>
        <option value="30"></option>
        <option value="40"></option>
      </datalist>
    </div>
  )
}
