import React, { Fragment, useState, useEffect }  from 'react'
import {default as Select} from "react-select";
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {SolvError, useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {clearAllErrors, InfoBlock, InfoText, showError, validateField} from "./components/ValidationUtils";
import NumericInput from "react-numeric-input";
import {PayPalButton} from "react-paypal-button-v2";
import useStateRef from "react-usestateref";
import {FormBody, FormControl, FormGroup} from "./components/FormComps";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CreditsTopupDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CreditsTopupDialog(props)  {

  const {api, session: {user}, refreshSignin, env, setFatal, setBusy} = useSolv()

  const {intl} = useIntlEx()

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  const [credits, setCredits] = useState(0.0)
  const [minimumCredits, setMinimumCredits] = useState(0.0)
  const [tenant, setTenant] = useState(null)
  const [usdMultiplier, setUsdMultiplier] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [topupReason, setTopupReason] = useState(null)
  const [topupReasonOpts, setTopupReasonOpts] = useState(null)
  const [bonusReason, setBonusReason] = useState(null)
  const [bonusReasonOpts, setBonusReasonOpts] = useState(null)
  const [paymentMethod, setPaymentMethod] = useState(null)
  const [paymentMethodOpts, setPaymentMethodOpts] = useState(null)
  const [description, setDescription] = useState(null)
  const [refInfo, setRefInfo] = useState(null)
  const [details, setDetails] = useState(null)

  const [paymentTransactionId, setPaymentTransactionId, paymentTransactionIdRef] = useStateRef(null)

  useEffect(() => {
    if (props.tenant) {
      log.debug("useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
  }, [props.tenant])

  useEffect(() => {
    if (tenant && tenant.region) {
      api.getCurrency(tenant.region.currencyCode)
        .then(({data}) => {
          setUsdMultiplier(data.usdMultiplier)
          setCurrencyCode(data.currencyCode)
          const minCredits = Math.ceil(10.00 / data.usdMultiplier)
          setMinimumCredits(minCredits)
          setCredits(minCredits)
        })
    }
  }, [tenant])

  useEffect(() => {

    let topupOpts = [
      {
        value: "BONUS",
        label: intl.msg("credits_topup_reason_bonus"),
      },
      {
        value: "PURCHASE",
        label: intl.msg("credits_topup_reason_purchase"),
      },
    ]

    setTopupReasonOpts(topupOpts)
    setTopupReason(topupOpts[0])

    let bonusOpts = []

    if (user.isSystemUser()) {
      bonusOpts = [
        {
          value: "GOODWILL",
          label: intl.msg("credits_bonus_reason_goodwill"),
        },
        {
          value: "PROMOTION",
          label: intl.msg("credits_bonus_reason_promotion"),
        },
        {
          value: "REWARD",
          label: intl.msg("credits_bonus_reason_reward"),
        },
        {
          value: "RELATIONSHIP",
          label: intl.msg("credits_bonus_reason_relationship"),
        },
        // {
        //   value: "COMPENSATION",
        //   label: intl.msg("credits_topup_reason_opts_compensation"),
        // },
      ]
    }
    else {
      bonusOpts = []
    }
    if (bonusOpts) {
      bonusOpts.sort((a, b) => a.label.localeCompare(b.label))
    }
    bonusOpts.push({
      value: "OTHER",
      label: intl.msg("credits_bonus_reason_other"),
    })
    setBonusReasonOpts(bonusOpts)
    setBonusReason(bonusOpts.find(e => e.value === "PROMOTION"))

    let paymentMethodOpts

    if (user.isSystemUser()) {
      paymentMethodOpts = [
        {
          value: "BANK",
          label: intl.msg("payment_method_bank"),
        },
        // {
        //   value: "CASH",
        //   label: intl.msg("payment_method_cash"),
        // },
        {
          value: "CRYPTO",
          label: intl.msg("payment_method_crypto"),
        },
        {
          value: "WISE",
          label: intl.msg("payment_method_wise"),
        },
        {
          value: "PAYPAL",
          label: intl.msg("payment_method_paypal"),
        },
      ]

      if (paymentMethodOpts) {
        paymentMethodOpts.sort((a, b) => a.label.localeCompare(b.label)).push(
            {
              value: "OTHER",
              label: intl.msg("payment_method_other"),
            }
        )
      }
      else {
        paymentMethodOpts = []
      }

      setPaymentMethodOpts(paymentMethodOpts)
      setPaymentMethod(paymentMethodOpts.find(e => e.value === "BANK"))
    }

  }, [])

  function handleCreditsChange(v) {
    setCredits(v)
    setTouched(true)
  }

  function handleTopupTypeChange(v) {
    setTopupReason(v)
    setTouched(true)
  }

  function handleBonusReasonChange(v) {
    setBonusReason(v)
    setTouched(true)
  }

  function handlePaymentMethodChange(v) {
    setPaymentMethod(v)
    setTouched(true)
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value)
    setTouched(true)
  }

  function handleRefInfoChange(e) {
    setRefInfo(e.target.value)
    setTouched(true)
  }

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog("dlg_credits_topup")
  }

  async function handlePaypalCreateOrder(orderData, actions) {

    log.debug("handlePaypalCreateOrder: orderData=", orderData)

    setBusy(intl.msg("working"))

    try {
      const { data } = await api.createPaymentTransaction({
        paymentTransactionType: "TOP_UP",
        paymentMethodId: "PAYPAL_CHECKOUT",
        amount: credits,
        usdMultiplier: usdMultiplier,
        details: orderData
      })
      if (data) {
        log.debug("handlePaypalCreateOrder: data=", data)
        setPaymentTransactionId(data.paymentTransactionId)
        return actions.order.create({
          purchase_units: [
            {
              amount: {
                currency_code: `${currencyCode ? currencyCode : "USD"}`,
                value: credits
              }
            }
          ],
          application_context: {
            brand_name: "SOLV: Top up Credits",
            shipping_preference: "NO_SHIPPING"
          }
        })
      }
      else {
        log.debug("handlePaypalCreateOrder: error=", data.error)
        setAlert({error: intl.msg("error_paypal_failed")})
        return false
      }
    }
    catch (error) {
      setAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  async function handlePaypalOnApprove(orderData, actions) {

    log.debug("handlePaypalOnApprove: orderData=", orderData)

    setBusy(intl.msg("working"))
    try {

      const details = await actions.order.capture()

      const { data } = await api.purchaseCredits(tenant.tenantId, {
        paymentTransactionId: paymentTransactionIdRef.current,
        purchaseType: "CREDITS",
        description: "Top up",
        details: details,
      })

      return actions.order
        .capture()
          .then((details) => {
            log.debug("handlePaypalOnApprove: details=", details)
            window.location.reload()
          })
          .catch((err) => {
            log.debug("handlePaypalOnApprove: err=", err)
            setAlert({error: intl.msg("error_paypal_failed")})
          })
    }
    catch (err) {
      log.debug("handlePaypalOnApprove: error=", JSON.stringify(err))
      setAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  async function handleTopupClick() {

    if (tenant && validate()) {
      setBusy(intl.msg("saving"))
      try {

        if ("PURCHASE" === topupReason.value) {

          try {

            let payload = {
              paymentTransactionType: "TOP_UP",
              paymentMethodId: paymentMethod.value,
              tenantId: tenant.tenantId,
              amount: credits,
              usdMultiplier: usdMultiplier,
              reason: topupReason.value,
              details: {
                "refInfo": refInfo
              }
            }

            log.debug("createPaymentTransaction: payload=", payload)

            const res = await api.createPaymentTransaction(payload)

            log.debug("createPaymentTransaction: data=", res)

            if (res && res.data) {

              try {

                let desc = ""
                switch (paymentMethod.value) {
                  case "OTHER":
                    desc = `Top up (${description})`
                    break
                  default:
                    desc = `Top up (Ref: ${refInfo})`
                    break
                }

                let payload = {
                  paymentTransactionId: res.data.paymentTransactionId,
                  purchaseType: "CREDITS",
                  description: desc,
                  details: {
                    "refInfo": refInfo
                  }
                }

                log.debug("purchaseCredits: payload=", payload)

                await api.purchaseCredits(tenant.tenantId, payload)

                window.location.reload()

              }
              catch (error) {
                handleError(error)
              }
            }
            else {
              handleError(new SolvError())
            }

          }
          catch (error) {
            log.debug("Error: error=", error)
            handleError(error)
          }

        }
        else {

          const payload = {
            creditTransactionType: "BONUS",
            paymentMethod: "CREDITS",
            tenantId: tenant.tenantId,
            credits: credits,
            refType: null,
            refId: null,
            reason: bonusReason.value,
            description: `Bonus (${description})`,
          }

          log.debug("handleTopupClick: payload=", payload)

          try {

            const data = await api.adjustCredits(tenant.tenantId, payload)

            log.debug("handleTopupClick: res=", data)

            window.location.reload()

          }
          catch (error) {
            handleError(error)
          }

        }

      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleError(error) {
    log.debug("handleError: >>>error=", error)
    if (error && error.code) {
      switch (error.code) {
        case "TENANT_NOT_FOUND":
          setAlert({error: intl.msg("error_failed")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_credits", credits && credits >= minimumCredits, "min-value", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function isEditing() {
    return true
  }

  return (
    tenant &&
      <ModalDialog
        dialogId="dlg_credits_topup"
        size={props.size || "md"}
        keyboard={"true"}
        backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header
          title={intl.msg("credits_topup_title")}
          description={intl.msg("credits_topup_title_description")}
          alert={alert}
        />

        <ModalDialog.Body>

          {
            tenant.accessingAs("*/MEMBER/*") ? (
              <>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_credits" text={intl.msg("credits_topup_credits")} description={intl.msg("credits_topup_credits_description")}/>
                  <FormGroup.Controls>
                    <div style={{display: 'flex', alignItems: "center", width: "120px"}}>
                      <NumericInput id="inp_credits" className="form-control" style={false} min={minimumCredits} precision={2} disabled={!isEditing()} value={credits} onChange={handleCreditsChange}/>
                      <div className="ml-2">{currencyCode ? currencyCode : "USD"}</div>
                    </div>
                    <InfoBlock>
                      <InfoText validate="min-value" disabled={!isEditing()}>{intl.msg("credits_topup_credits_helptext_min_value", {minCredits: intl.num(minimumCredits), currencyCode: currencyCode})}</InfoText>
                      <InfoText disabled={!isEditing()}>{intl.msg("credits_topup_credits_helptext_note_1")}</InfoText>
                      <InfoText disabled={!isEditing()}>{intl.msg("credits_topup_credits_helptext_note_2")}</InfoText>
                      <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_paymentMethod" text={intl.msg("credits_topup_payment_method")} description={intl.msg("credits_topup_payment_method_description")}/>
                  <FormGroup.Controls>
                    <div className="paypal-buttons-container">
                      {
                        tenant && currencyCode &&
                          <PayPalButton
                            options={{
                              clientId: (("SYSTEM" === tenant.tenantTypeId) || ["DEMO", "TEST"].includes(tenant.tenantPlanId) ? env.PAYPAL_TEST_CLIENT_ID : env.PAYPAL_LIVE_CLIENT_ID),
                              currency: `${currencyCode ? currencyCode : "USD"}`
                            }}
                            createOrder={handlePaypalCreateOrder}
                            onApprove={handlePaypalOnApprove}
                          />
                      }
                    </div>
                    {/*<div className="talign-center mt-3">*/}
                    {/*  <span style={{color: "var(--form-control-label-muted-color)"}}>&mdash;</span>&nbsp;or&nbsp;<span style={{color: "var(--form-control-label-muted-color)"}}>&mdash;</span>*/}
                    {/*</div>*/}
                    {/*<div>*/}
                    {/*  <button className="btn btn-primary-blue mt-3 p-3" style={{width: "100%"}}>*/}
                    {/*    <span className="fa-layers mr-3">*/}
                    {/*      <i className="fas fa-long-arrow-alt-right" data-fa-transform="shrink-2 right-8 up-10"></i>*/}
                    {/*      <i className="fas fa-dollar-sign" data-fa-transform="shrink-8 right-2"></i>*/}
                    {/*      <i className="fas fa-money-bill" data-fa-transform="grow-6"></i>*/}
                    {/*      <i className="fas fa-long-arrow-alt-left" data-fa-transform="shrink-2 left-6 down-10"></i>*/}
                    {/*    </span>*/}
                    {/*    Bank Transfer*/}
                    {/*  </button>*/}
                    {/*</div>*/}
                  </FormGroup.Controls>
                </FormGroup>

              </>

            ) : (
              <>
                {
                  topupReasonOpts && topupReasonOpts.length > 1 &&
                  <FormGroup>
                    <FormGroup.Label htmlFor="inp_credits_topup_reason" text={intl.msg("credits_topup_reason")} description={intl.msg("credits_topup_reason_description")}/>
                      <FormGroup.Controls>
                        <div style={{width: "200px"}}>
                          <Select
                              id="inp_credits_topup_reason"
                              className="react-select"
                              classNamePrefix="react-select"
                              value={topupReason}
                              options={topupReasonOpts}
                              onChange={handleTopupTypeChange}
                              isDisabled={!isEditing()}/>
                        </div>
                        <InfoBlock>
                          <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>
                }

                {
                  ("PURCHASE" === topupReason?.value) &&
                    paymentMethodOpts && paymentMethodOpts.length > 1 &&
                      <FormGroup>
                        <FormGroup.Label htmlFor="inp_credits_topup_payment_method" text={intl.msg("credits_topup_payment_method")} description={intl.msg("credits_topup_payment_method_description")}/>
                        <FormGroup.Controls>
                          <div style={{width: "200px"}}>
                            <Select
                              id="inp_credits_topup_payment_method"
                              className="react-select"
                              classNamePrefix="react-select"
                              value={paymentMethod}
                              options={paymentMethodOpts}
                              onChange={handlePaymentMethodChange}
                              isDisabled={!isEditing()}
                            />
                          </div>
                          <InfoBlock>
                            <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                          </InfoBlock>
                        </FormGroup.Controls>
                      </FormGroup>

                }

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_credits" text={intl.msg("credits_topup_credits")} description={intl.msg("credits_topup_credits_description")}/>
                  <FormGroup.Controls>
                    <div style={{display: 'flex', alignItems: "center", width: "120px"}}>
                      <NumericInput id="inp_credits" className="form-control" style={false} min={minimumCredits} precision={2} disabled={!isEditing()} value={credits} onChange={handleCreditsChange}/>
                      <div className="ml-2">{currencyCode ? currencyCode : "USD"}</div>
                    </div>
                    <InfoBlock>
                      <InfoText validate="min-value" disabled={!isEditing()}>{intl.msg("credits_topup_credits_helptext_min_value", {minCredits: intl.num(minimumCredits), currencyCode: currencyCode})}</InfoText>
                      <InfoText validate="required" disbled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

                {
                  ("PURCHASE" === topupReason?.value && !["CASH", "OTHER"].includes(paymentMethod.value)) ? (
                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_refInfo" text={intl.msg("credits_topup_ref_info")} description={intl.msg("credits_topup_ref_info_description")}/>
                      <FormGroup.Controls>
                        <input id="inp_refInfo" className="form-control" type="text" value={refInfo} disabled={!isEditing()} onChange={handleRefInfoChange}/>
                        <InfoBlock>
                          <InfoText validate="required" disbled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>
                  ) : (
                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_description" text={intl.msg("credits_topup_description")} description={intl.msg("credits_topup_description_description")}/>
                      <FormGroup.Controls>
                        <input id="inp_description" className="form-control" type="text" value={description} disabled={!isEditing()} onChange={handleDescriptionChange}/>
                        <InfoBlock>
                          <InfoText validate="required" disabled={!isEditing()}>{intl.msg("helptext_required")}</InfoText>
                        </InfoBlock>
                      </FormGroup.Controls>
                    </FormGroup>
                  )
                }
              </>
            )
          }

        </ModalDialog.Body>

        {
          !tenant.accessingAs("*/MEMBER/*") &&
            <ModalDialog.Footer>
              <ModalDialog.Footer.Controls>
                <button className="btn btn-primary" onClick={handleTopupClick}>{intl.msg("topup")}</button>
                <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
              </ModalDialog.Footer.Controls>
            </ModalDialog.Footer>
        }

      </ModalDialog>
    )
  }