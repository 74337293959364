import React, {useEffect, useState} from 'react'
import {useSolv} from "./components/SolvProvider";
import {useIntlEx} from "./components/IntlUtils.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import {Alert, useAlert} from "./components/FormComps";

const log = getLogger("MemberReinviteDialog")

export default function MemberReinviteDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)
  const [row, setRow] = useState(null)

  useEffect(() => {
    log.debug(">>>Z: useEffect: invoked")
    if (props.tenant) {
      log.debug(">>>Z: useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
    if (props.row) {
      log.debug(">>>Z: useEffect: row=", props.row)
      setRow(props.row)
    }
  }, [props])

  function handleReinviteClick() {
    setBusy(intl.msg("resending"))
    api.reinviteMember(props.tenant.tenantId, props.row.userId)
        .then(({data}) => {
          log.debug("Reinvite: data=", data)
          window.location.reload()
        })
        .catch((error) => {
          log.debug("Error reinviting invitation")
          setAlert({error: intl.msg("error_failed")})
        })
        .finally(() => {
          setBusy(null)
        })
  }

  function handleOpenDialog() {
    log.debug(">>>Z: handleOpenDialog: tenant=, row=", props.tenant, props.row)
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_member_reinvite")
  }

  return (

      <>
        <ModalDialog
          id="dlg_member_reinvite"
          size="md"
          keyboard="true"
          backdrop="static"
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header title={intl.msg("member_reinvite_title")} description={intl.msg("member_reinvite_description")} alert={alert}/>

          {
            !alert &&
              <ModalDialog.Body>
                <Alert alert={{warning: intl.msg(`member_reinvite_text`, {displayName: props.row?.emailAddress})}} align="center"/>
              </ModalDialog.Body>
          }

          <ModalDialog.Footer>
            <ModalDialog.Footer.Controls>
              <button className="btn btn-secondary" type="button" onClick={() => closeDialog("dlg_member_reinvite")}>
                {intl.msg("cancel")}
              </button>
              {
                !alert &&
                  <button className="btn btn-primary" type="button" onClick={handleReinviteClick}>
                    {intl.msg("resend")}
                  </button>
              }
            </ModalDialog.Footer.Controls>
          </ModalDialog.Footer>

        </ModalDialog>
      </>

  )
}