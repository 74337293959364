import React, {useCallback, useEffect, useState} from 'react'
import CreatableSelect from 'react-select/creatable'
import getLogger from "./Logging.js"

const log = getLogger("TagInput")

export default function TagInput(props) {

  const [value, setValue] = useState(null)
  const [inputValue, setInputValue] = useState(null)

  const breakKeys = props.breakKeys || ["Enter"]

  useEffect(() => {
    setValue(props.value)
  }, [props.value]);

  // const notifyChange = useCallback(() => {
  //   props.onChange(value)
  // }, [props.onChange]);

  function notifyChange(r) {
    log.debug("notifyChange: 1: r=", r)
    if (props.onChange) {
      log.debug("notifyChange: 2: r=", r)
      props.onChange(r)
    }
  }

  // useEffect(() => {
  //     if (props.onChange) {
  //       props.onChange(value)
  //     }
  //   }, [value]);

    function createOption(label) {
        return (
            {
                label,
                value: label,
            }
        )
    }

    function setInputValueFromEvent(event, inputValue) {
      if (inputValue) {
        let v = inputValue.trim()
        if (v.length > 0) {
          if (props.lowerCase) {
            v = v.toLowerCase()
          }
          if (v.startsWith("mailto:")) {
            v = v.substring(7)
          }
          // setValue(prevValue => prevValue && prevValue.length ? (!prevValue.some(pv => pv.value == v) ? [...prevValue, createOption(v)] : prevValue) : [createOption(v)])
          setValue(prevValue => {
            let r
            if (prevValue && prevValue.length) {
              r = (!prevValue.some(pv => pv.value == v) ? [...prevValue, createOption(v)] : prevValue)
            }
            else {
              r = [createOption(v)]
            }
            notifyChange(r)
            log.debug("setInputValueFromEvent: r=", r)
            return r
          })
        }
        setInputValue("")
        event.preventDefault();
      }
    }

    function handleKeyDown(event) {
        if (!inputValue) return;
        if (breakKeys.includes(event.key)) {
            setInputValueFromEvent(event, inputValue)
        }
    }

    function handleBlur(event) {
      setInputValueFromEvent(event, inputValue)
    }

    function handlePaste(event) {    
        let paste = (event.clipboardData || window.clipboardData).getData('text');
        for (const inputValue of paste.split(/[\n\t, ]/)) {
            if (inputValue.length > 0) {
                setInputValueFromEvent(event, inputValue)
            }
        }
    }
    
    function styles() {
      log.debug("getting styles...")
      return {
        multiValueLabel: (styles, { data }) => {
          log.debug("getting styles 3 ...:data=", styles, data)
          return {
            ...styles,
            color: "hsl(10%, 10%, 20%)",
            fontSize: "20%"
         }
        }
      }
    }

    return (
        <div onPaste={handlePaste} style={{height: '100%', width: '100%' }}>
          <CreatableSelect
              id={props.id}
              className={props.className}
              classNamePrefix={props.classNamePrefix}
              components={{DropdownIndicator: null}}
              inputValue={inputValue}
              isClearable
              isMulti
              placeholder=""
              menuIsOpen={false}
              onChange={e => props.onChange(e)}
              onInputChange={v => setInputValue(v)}
              onKeyDown={handleKeyDown}
              onBlur={handleBlur}
              onPaste={handlePaste}
              value={value}
              isDisabled={props.isDisabled}
              styles={{
                multiValueLabel: (providedStyles, props) => {
                  return {...providedStyles,
                    color: (props.data.__isError__ ? 'var(--form-control-error-color) !important' : 'var(--form-control-react-select-multivalue-text-color) !important')
                  }
                }
            }}
          />        
        </div>
    )

}