import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert} from "./components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from './components/ValidationUtils.js';
import MainMenu from "./MainMenu"
import getLogger from "./components/Logging.js"
import {useTenant} from "./components/SolvProvider";
import AccountTabs from "./AccountTabs";
import {mkHref} from "./components/AccountUtils";
import './App.css'
import {UserAndTimeLink} from "./components/UserUtils";
import {openDialog, openDialogCurried} from "./components/DialogUtils";
import CreditTransactionReverseDialog from "./CreditTransactionReverseDialog";

const log = getLogger("CreditTransaction")

export default function CreditTransaction(props) {

  const {api, session, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [creditTransactionId, setCreditTransactionId] = useState(params.userId)

  const [alert, setAlert] = useAlert()
  const [touched, setTouched] = useState(false)

  const [creditTransactionType, setCreditTransactionType] = useState(null)
  const [description, setDescription] = useState(null)
  const [credits, setCredits] = useState(null)
  const [displayCredits, setDisplayCredits] = useState(null)
  const [details, setDetails] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [createdOn, setCreatedOn] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)

  useEffect(() => {
    log.debug("getCreditTransaction: tenant=", tenant, params.creditTransactionId)
    if (tenant && params.creditTransactionId) {
      setBusy(intl.msg("working"))
      log.debug("getCreditTransaction: loading")
      api.getCreditTransaction(tenant.tenantId, params.creditTransactionId)
        .then(({data}) => {
          log.debug("getCreditTransaction: data=", data)
          setCreditTransactionId(data.creditTransactionId)
          setCreditTransactionType(data.creditTransactionType)
          setDescription(data.description)
          setCredits(data.credits)
          if (data.credits > 0) {
            setDisplayCredits(`${intl.num(data.credits)} ${data.currencyCode}`)
          }
          else {
            setDisplayCredits(`(${intl.num(data.credits)}) ${data.currencyCode}`)
          }
          setCurrencyCode(data.currencyCode)
          setDetails(data.details)
          setCreatedOn(data.createdOn)
          setCreatedBy(data.createdBy)
        })
        .catch((err) => {
          setFatal(err)
        })
        .finally(() => {
          setBusy(null)
        })
    }
  }, [tenant, creditTransactionId])

  function handleReverseClick() {

  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("region_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  // function MoreButton(props) {
  //   return (
  //     tenant.accessingAs("*/*/SYSTEM") ? (
  //       <>
  //         <button className="btn btn-secondary" type="button" id="dropdownMenuButton" title={intl.msg("more_menu")} data-toggle="dropdown" aria-haspopup="true" aria-expanded="false"><i className="fas fa-ellipsis-v"></i></button>
  //         <div className="dropdown-menu dropdown-menu-right" aria-labelledby="dropdownMenuButton">
  //           <>
  //             {
  //               creditTransactionType && ("REVERSE" !== creditTransactionType) &&
  //               <>
  //                 <a className="dropdown-item" href="#">
  //                   <i className="fas fa-paper-plane mr-1"></i>
  //                   {intl.msg("reverse")}
  //                 </a>
  //               </>
  //             }
  //           </>
  //         </div>
  //       </>
  //     ) : (
  //       <></>
  //     )
  //   )
  // }

  return (
    tenant &&
    <>
      <MainContainer menu={MainMenu}>

        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("credit_transaction")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              {
                tenant && tenant.accessingAs("SUPERADMIN/*/SYSTEM") ? (
                  creditTransactionType && ("TOP_UP" === creditTransactionType) &&
                    <button key="key_btn_reverse" className="btn btn-secondary" onClick={openDialogCurried("dlg_credit_transaction_reverse")}>
                      <i className="fas fa-undo"></i>{intl.msg("reverse")}
                    </button>
                ) : (
                  <></>
                )
              }
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>
        <FormBody>

          <div className="row no-gutters">

            <div className="col">

              <form id="frmMain" autoComplete="off">

                <div className="form-card">

                  <div className="row">

                    <div className="col">

                      <div className="form-group row form-compact">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_transactionId">{intl.msg("credits_transaction_id")}:</label>
                        <div className="col-sm-9">
                          <label className="form-control">{creditTransactionId}</label>
                        </div>
                      </div>

                      <div className="form-group row form-compact">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_description">{intl.msg("description")}:</label>
                        <div className="col-sm-9">
                          <label className="form-control">{description}</label>
                        </div>
                      </div>

                      {
                        (details && (details?.payment?.refInfo || details?.refInfo)) &&
                          <div className="form-group row form-compact">
                            <label className="col-sm-3 col-form-label" htmlFor="inp_refInfo">{intl.msg("credits_ref_info")}:</label>
                            <div className="col-sm-9">
                              <label className="form-control">{details?.payment?.refInfo || details?.refInfo}</label>
                            </div>
                          </div>
                      }

                      <div className="form-group row form-compact">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_credits">{intl.msg("credits_amount")}:</label>
                        <div className="col-sm-9">
                          <label className="form-control">{displayCredits}</label>
                        </div>
                      </div>

                      <div className="form-group row form-compact">
                        <label className="col-sm-3 col-form-label" htmlFor="inp_created">{intl.msg("created")}:</label>
                        <div className="col-sm-9">
                          <label className="form-control"><UserAndTimeLink user={createdBy} timestamp={createdOn} session={session} tenant={tenant}/></label>
                        </div>
                      </div>

                    </div>

                  </div>

                </div>

              </form>

            </div>

          </div>

        </FormBody>
      </MainContainer>

      <CreditTransactionReverseDialog
        tenant={tenant}
        creditTransactionId={creditTransactionId}
        creditTransactionType={creditTransactionType}
        credits={credits}
        currencyCode={currencyCode}
        description={description}/>

    </>

  )
}
