import React  from 'react'
import { useIntlEx } from "./IntlUtils.js"
import { TagLabel } from './TagBox'
import getLogger from "./Logging.js"

const log = getLogger("BroadcastUtils")

// const statusColors = {
//   "DRAFT": "#D7CCC8",
//   "DELIVERING": "#9FE2BF",
//   "GENERATED": "#81C784",
//   "IMPORTED": "#81C784",
//   "PUBLISHED": "#81C784",
//   "STARTED": "#9FE2BF",
//   "RUNNING": "#9FE2BF",
//   "PAUSED": "#FFCC80",
//   "ENDED": "#66CCFF",
//   "DELIVERED": "#66CCFF",
//   "FAILED_IMPORT": "#81C784",
//   "REFUNDED": "#FF6600",
// }
//
// const statusIconClasses = {
//   "DRAFT": "fa-inverse fas fa-edit",
//   "DELIVERING": "fas fa-play",
//   "GENERATED": "fa-inverse fas fa-paper-plane",
//   "IMPORTED": "fa-inverse fas fa-paper-plane",
//   "PUBLISHED": "fa-inverse fas fa-paper-plane",
//   "STARTED": "fas fa-play",
//   "RUNNING": "fas fa-play",
//   "PAUSED": "fa-inverse fas fa-pause",
//   "ENDED": "fa-inverse fas fa-square",
//   "DELIVERED": "fa-inverse fas fa-square",
//   "FAILED_IMPORT": "fa-inverse fas fa-paper-plane",
//   "REFUNDED": "fa-inverse fas fa-square",
// }

export function BroadcastStatusTagLabel(props) {
  const { intl } = useIntlEx()
  const tenant = props.tenant
  const broadcast = props.broadcast
  let realStatus = (broadcast ? broadcast.status : props.status)?.toLowerCase()
  if (realStatus) {
    const displayStatus = sanitizeStatus(realStatus)
    let title
    if (tenant && tenant.accessingAs("*/*/SYSTEM")) {
      title = intl.msg(`status_broadcast_${realStatus}`)
    }
    else {
      title = intl.msg(`status_broadcast_${displayStatus}`)
    }
    // log.debug("status=", status)
    let txt = intl.msg(`status_broadcast_${displayStatus}`)
    let pct = getPctDelivered(broadcast, intl)
    if (pct) {
      txt = txt + " "  + intl.int(pct) + "%"
    }
    return (
      displayStatus &&
        <TagLabel
          label={txt}
          title={title}
          className={"broadcast-status-color-" + displayStatus + (props.className ? ` ${props.className}` : "")}/>
    )
  }
  else {
    return <></>
  }
}

function sanitizeStatus(status) {
  const s = status?.trim()?.toLowerCase()
  switch (s) {
    case "published":
    case "queued":
    case "generated":
    case "imported":
    case "failed_import":
      return "awaiting_stats"
    default:
      return s
  }
}

export function mkFieldRowClassName(opts) {
  let className = "field-row"
  if (opts) {
    if (opts.layout) {
      className = ` ${className} field-style-${opts.layout.toLowerCase()}`
    }
    if (opts.labelStyle) {
      className = ` ${className} field-style-label-${opts.labelStyle.toLowerCase()}`
    }
    if (opts.valueStyle) {
      className = `${className} field-style-value-${opts.valueStyle.toLowerCase()}`
    }
  }
  return className
}

export function getStyle(style, el, at, defaultValue) {
  if (style && style[el] && style[el][at]) {
    return style[el][at]
  }
  return defaultValue
}

export function getPctDelivered(broadcast, intl) {
  if (broadcast && broadcast.status && broadcast.adViews && broadcast.targetAdViews && ("STARTED" === broadcast.status)) {
    return intl.num(broadcast.adViews < broadcast.targetAdViews ? Math.min((broadcast.adViews + 0.0) / (broadcast.targetAdViews + 0.0) * 100, 99.0) : 99.0)
  }
  else {
    return null
  }
}

export function getMessageFormatOpts(tenant, intl) {
  let opts = []
  opts.push({
    label: <><div>{intl.msg("broadcast_message_type_solv")}</div><small>{intl.msg("broadcast_message_type_solv_tooltip")}</small></>,
    value: "SOLV",
  })
  opts.push({
    label: <><div>{intl.msg("broadcast_message_type_image")}</div><small>{intl.msg("broadcast_message_type_image_tooltip")}</small></>,
    value: "IMAGE",
  })
  return opts
}

export function getMessageFormatOpt(messageFormat, tenant, intl) {
  return getMessageFormatOpts(tenant, intl).find(i => i.value === messageFormat)
}

export function getClickActionOpts(tenant, intl, opts = ["SOLV", "EXTERNAL", "HOME", "WHATSAPP"]) {
  let o = []
  // if (opts.includes("SOLV")) {
  //   o.push({
  //     label: <>
  //       <div>{intl.msg("broadcast_page_type_solv_page")}</div>
  //       <small>{intl.msg("broadcast_page_type_solv_page_tooltip")}</small></>,
  //     value: "SOLV",
  //     displayLabel: intl.msg("broadcast_page_type_solv_page"),
  //   })
  // }
  if (opts.includes("EXTERNAL")) {
    o.push({
      label: <>
        <div>{intl.msg("broadcast_page_type_custom_page")}</div>
        <small>{intl.msg("broadcast_page_type_custom_page_tooltip")}</small>
      </>,
      value: "EXTERNAL",
      displayLabel: intl.msg("broadcast_page_type_custom_page"),
    })
  }
  if (opts.includes("HOME")) {
    if (tenant.homePage && tenant.homePage.trim().length > 0) {
      o.push({
        label: <>
          <div>{intl.msg("broadcast_page_type_home_page")}</div>
          <small>{intl.msg("broadcast_page_type_home_page_tooltip")}</small></>,
        value: "HOME",
        displayLabel: intl.msg("broadcast_page_type_home_page"),
      })
    }
  }
  if (opts.includes("WHATSAPP")) {
    o.push({
      label: <>
        <div>{intl.msg("broadcast_page_type_whatsapp_chat")}</div>
        <small>{intl.msg("broadcast_page_type_whatsapp_chat_tooltip")}</small></>,
      value: "WHATSAPP",
      displayLabel: intl.msg("broadcast_page_type_whatsapp_chat"),
    })
  }
  return o
}

export function getClickActionOpt(clickAction, tenant, intl, opts = ["SOLV", "EXTERNAL", "HOME", "WHATSAPP"]) {
  return getClickActionOpts(tenant, intl, opts).find(i => i.value === clickAction)
}

export function getWhatsAppPhoneNoFromPageUrl(url) {
  try {
    const u = new URL(url)
    return u.pathname.replace(/^\/*/, '').trim()
  }
  catch (err) {
    return null
  }
}

