import React, { useState, useRef }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { v4 as uuidv4 } from 'uuid'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { clearAllErrors } from "./components/ValidationUtils.js"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {Alert} from "./components/FormComps";
import './App.css'

const log = getLogger("BroadcastCloneAsDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CustomFieldCloneAsDialog(props)  {

  const {intl} = useIntlEx()

  const [alert, setAlert] = useState(null)

  const btnCloseRef = useRef(null)
  
  function handleCloneClick() {
    const g = {...props.field, id: uuidv4().replace(/[\-]+/g,'')}
    const index = props.targetFields.findIndex(e => e.label === props.field.label)
    if (index === -1) {
      log.debug("handleCloneClick: ", index)
      props.setTargetFields([
          ...props.targetFields,
          g
      ])  
      btnCloseRef.current.click()
      resetState()
    }
    else {
      setAlert({error: intl.msg("template_field_duplicate_label", {type: props.targetType})})
    }

    props.setTouched(true)
  }

  function resetState() {
    setAlert(null)
    clearAllErrors(`frmMain-${props.id}`)
  }

  function handleOpenDialog() {
    resetState()
  }

  function handleCloseDialog() {
    closeDialog(props.id)
  }

  return ( 

    <ModalDialog
      dialogId={props.id}
      header={<h1>{intl.msg("template_set_default_title")}</h1>}
      footer={
        <>
          <button type="button" class="btn btn-primary" onClick={handleCloneClick}>{intl.msg("clone")}</button>
          <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
        </>
      }
      size="md"
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}
      alert={alert}>
      <center>
        <Alert alert={{attention: intl.msg("template_field_clone_as_text", {fieldLabel: props.field.label, type: props.targetType})}}/>
      </center>
    </ModalDialog>
  )
}