import React, {Fragment, useEffect, useState} from 'react'
import {useParams}  from 'react-router-dom'
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils.js"
import MainMenu from "../MainMenu.js"
import {FormHeader, FormBody, MainContainer, useAlert} from "../components/FormComps.js"
import {HumanTimestamp} from "../components/DateUtils"
import {useCols} from "../components/TableComps"
import {DataTable} from "../components/TableComps"
import getLogger from "../components/Logging.js"
import '../App.css'
import {useResizeDetector} from "react-resize-detector";
import PropsTestDialog from "./PropsTestDialog";
import {openDialogCurried} from "../components/DialogUtils";

const log = getLogger("Props")

export default function Props() {

  const params = useParams()

  const {api, session:{user}, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert(null)

  const { width: editorWidth, height: editorHeight, ref: editorRef } = useResizeDetector()

  const [props, setProps] = useState(null)
  const [row, setRow] = useState(null)

  const [dirty, setDirty] = useState(false)

  const {cols} = useCols({
    specs: [
      {
        field: "propsId",
        label: intl.msg("props_id"),
        filter: "text",
        sort: null,
        align: "left",
      },
      {
        field: "environmentId",
        label: intl.msg("environment"),
        filter: {
          type: "select",
          options: [
            {
              label: "DEV",
              value: "DEV"
            },
            {
              label: "LIVE",
              value: "LIVE"
            },
          ],
          multi: true,
        },
        sort: null,
        align: "left",
      },
      {
        field: "regionId",
        label: intl.msg("region"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listRegions: invoked")
            return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.regionId,
                    label: c.regionName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadRegions: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "industryId",
        label: intl.msg("industry"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listIndustry: invoked")
            return api.listIndustries({view: "BASIC", extraParams: `${input ? `filter=industryName:${encodeURIComponent(input)}&` : ""}sort=industryName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.industryId,
                    label: c.industryName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadIndustries: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "tenantTypeId",
        label: intl.msg("tenant_type"),
        filter: {
          type: "select",
          options: [
            {
              label: "CLIENT",
              value: "CLIENT"
            },
            {
              label: "RESELLER",
              value: "RESELLER"
            },
            {
              label: "SYSTEM",
              value: "SYSTEM"
            },
          ],
          multi: true,
        },
        sort: null,
        align: "left",
      },
      {
        field: "tenantPlanId",
        label: intl.msg("tenant_plan"),
        filter: {
          type: "select",
          options: [
            {
              label: "BUSINESS_A",
              value: "BUSINESS_A"
            },
            {
              label: "BUSINESS_B",
              value: "BUSINESS_B"
            },
            {
              label: "RESELLER",
              value: "RESELLER"
            },
            {
              label: "SYSTEM",
              value: "SYSTEM"
            },
          ],
          multi: true,
        },
        sort: null,
        align: "left",
      },
      {
        field: "brandId",
        label: intl.msg("brand"),
        filter: {
          type: "select",
          options: [
            {
              label: "MEDIASOLV",
              value: "MEDIASOLV"
            },
            {
              label: "PETSOLV",
              value: "PETSOLV"
            },
            {
              label: "SOLV",
              value: "SOLV"
            },
          ],
          multi: true,
        },
        sort: null,
        align: "left",
      },
      {
        field: "flagIds",
        label: intl.msg("flags"),
        filter: "text",
        sort: null,
        align: "left",
      },
    ],
    storageItemName: "props",
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listProps({extraParams: cols.stringify(), cursor: cursor, limit: 50})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "propsId":
        n = <span>{row.propsId}</span>
        break
      case "environmentId":
        n = <span>{row.environmentId}</span>
        break
      case "regionId":
        n = <span>{row.regionId}</span>
        break
      case "industryId":
        n = <span>{row.industryId}</span>
        break
      case "tenantTypeId":
        n = <span>{row.tenantTypeId}</span>
        break
      case "tenantPlanId":
        n = <span>{row.tenantPlanId}</span>
        break
      case "brandId":
        n = <span>{row.brandId}</span>
        break
      case "flagIds":
        n = <span>{row.flagIds}</span>
        break
      case "touchedOn":
        n = <HumanTimestamp timestamp={row.touchedOn} else={intl.msg("never")}/>
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = `/sys/props/${row.propsId}`
  }

  function handleAddClick() {
    window.location = `/sys/props/_new`
  }

  function canEdit() {
    return user.isSystem() && user.isOwner()
  }

  function canTest() {
    return user.isSystem()
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu}>
          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("props")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  canEdit() &&
                    <button key="key_btn_create" className="btn btn-primary" onClick={handleAddClick}>
                      <i className="fas fa-plus-circle mr-2"></i>
                      {intl.msg("add_new")}
                    </button>
                }
                {
                  canTest() &&
                    <button key="key_btn_test" className="btn btn-secondary" onClick={openDialogCurried("dlg_props_test")}>
                      <i className="fas fa-filter mr-2"></i>
                      {intl.msg("test")}
                    </button>
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>
          <FormBody>
            <DataTable
              tenant={tenant}
              cols={cols}
              resourceName="props"
              dataKey="propsId"
              selectable="true"
              onFetchDataPage={handleFetchDataPage}
              onRenderDataCol={handleRenderDataCol}
              onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>

        <PropsTestDialog/>

      </>
  )
}