import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Route, Switch, useLocation } from 'react-router-dom'
import { LinkedInCallback } from "react-linkedin-login-oauth2"
import PrivateRoute from "../components/PrivateRoute"
import Broadcast from "../Broadcast"
import BroadcastCreativePreview from "../BroadcastCreativePreview"
import BroadcastPagePreview from "../BroadcastPagePreview"
import Broadcasts from "../Broadcasts"
import Responses from "../Responses"
import Clients from "../Clients"
import Resellers from "../Resellers"
import Bills from "../Bills"
import Incomes from "../Incomes"
import Template from "../Template"
import Templates from "../Templates"
import CreditTransactions from "../CreditTransactions"
import CreditTransaction from "../CreditTransaction"
import Account from "../Account"
import Members from "../Members"
import Member from "../Member"
import ReferralCodes from "../ReferralCodes"
import ReferralCode from "../ReferralCode"
import Page403 from "../Page403"
import Page426 from "../Page426"
import Page500 from "../Page500"
import Page503 from "../Page503"
import Page404 from "../Page404"
import Signin from "./Signin"
import Signup from "./Signup"
import Onboarding from "./Onboarding"
import EmailAddressChanged from "./EmailAddressChanged"
import SysStatus from "../sysadmin/SysStatus"
import Regions from "../sysadmin/Regions"
import Region from "../sysadmin/Region"
import Countries from "../sysadmin/Countries"
import Country from "../sysadmin/Country"
import Locations from "../sysadmin/Locations"
import Location from "../sysadmin/Location"
import Industries from "../sysadmin/Industries"
import Industry from "../sysadmin/Industry"
import Currencies from "../sysadmin/Currencies"
import Currency from "../sysadmin/Currency"
import RepResellers from "../sysadmin/RepResellers";
import Props from "../sysadmin/Props";
import Prop from "../sysadmin/Prop";
import {MicrosoftAuthCallback} from "../components/MicrosoftAuthCallback"
import ExpressPublish from "../extension/ExpressPublish";

export default function SolvRoutes(props) {
  return (
    <Switch>

      <Route path="/signin" component={Signin} exact />
      <Route path="/signin/:magicCode" component={Signin} exact />

      <Route path="/signup" component={Signup} exact />
      <Route path="/signup/:signupCode" component={Signup} exact />
      <Route path="/signup/:signupCode/:magicCode" component={Signup} exact />

      <Route path="/me/:magicCode" component={EmailAddressChanged} exact />

      <Route path="/onboarding" component={Onboarding} exact />

      <Route path="/forbidden" component={Page403} />
      <Route path="/upgrade" component={Page426} />
      <Route path="/error" component={Page500} />
      <Route path="/unavailable" component={Page503} />
      <Route path="/403" component={Page403} />
      <Route path="/426" component={Page426} />
      <Route path="/500" component={Page500} />
      <Route path="/503" component={Page503} />

      <Route path="/linkedin" component={LinkedInCallback} exact />

      <Route path="/authcb/ms/" component={MicrosoftAuthCallback} exact />

      {/*  Logged-in user routes  */}

      <PrivateRoute path="/" component={Account} exact />
      <PrivateRoute path="/_new" component={Account} exact />
      <PrivateRoute path="/_edit" component={Account} exact />
      <PrivateRoute path="/clients" component={Clients} exact />
      <PrivateRoute path="/resellers" component={Resellers} exact />
      <PrivateRoute path="/broadcasts" component={Broadcasts} exact />
      <PrivateRoute path="/broadcasts/_new" component={Broadcast} exact />
      <PrivateRoute path="/broadcasts/:broadcastId" component={Broadcast} exact />
      <PrivateRoute path="/broadcasts/:broadcastId/creative" component={BroadcastCreativePreview} exact />
      <PrivateRoute path="/broadcasts/:broadcastId/page" component={BroadcastPagePreview} exact />
      <PrivateRoute path="/broadcasts/:broadcastId/_edit" component={Broadcast} exact />
      <PrivateRoute path="/broadcasts/:broadcastId/_publish" component={Broadcast} exact />
      <PrivateRoute path="/responses" component={Responses} exact />
      <PrivateRoute path="/templates" component={Templates} exact />
      <PrivateRoute path="/templates/_new" component={Template} exact />
      <PrivateRoute path="/templates/:templateId" component={Template} exact />
      <PrivateRoute path="/members" component={Members} exact />
      <PrivateRoute path="/members/:userId" component={Member} exact />
      <PrivateRoute path="/credits" component={CreditTransactions} exact />
      <PrivateRoute path="/credits/:creditTransactionId" component={CreditTransaction} exact />
      <PrivateRoute path="/refcodes" component={ReferralCodes} exact />
      <PrivateRoute path="/billing" component={Bills} exact />
      <PrivateRoute path="/bills" component={Bills} exact />
      <PrivateRoute path="/incomes" component={Incomes} exact />
      <PrivateRoute path="/income" component={Incomes} exact />
      <PrivateRoute path="/profile" component={Member} exact />
      <PrivateRoute path="/me" component={Member} exact />

      <PrivateRoute path="/sys" component={SysStatus} exact />
      <PrivateRoute path="/sys/regions" component={Regions} exact />
      <PrivateRoute path="/sys/regions/:regionId" component={Region} exact />
      <PrivateRoute path="/sys/countries" component={Countries} exact />
      <PrivateRoute path="/sys/countries/:countryCode" component={Country} exact />
      <PrivateRoute path="/sys/locations" component={Locations} exact />
      <PrivateRoute path="/sys/locations/:locationId" component={Location} exact />
      <PrivateRoute path="/sys/industries/" component={Industries} exact />
      <PrivateRoute path="/sys/industries/:industryId" component={Industry} exact />
      <PrivateRoute path="/sys/currencies/" component={Currencies} exact />
      <PrivateRoute path="/sys/currencies/:currencyCode" component={Currency} exact />
      <PrivateRoute path="/sys/represellers/" component={RepResellers} exact />
      <PrivateRoute path="/sys/props/" component={Props} exact />
      <PrivateRoute path="/sys/props/:propsId" component={Prop} exact />

      <PrivateRoute path="/:tenantId" component={Account} exact/>
      <PrivateRoute path="/:tenantId/_new" component={Account} exact/>
      <PrivateRoute path="/:tenantId/_edit" component={Account} exact/>
      <PrivateRoute path="/:tenantId/clients" component={Clients} exact/>
      <PrivateRoute path="/:tenantId/resellers" component={Resellers} exact/>
      <PrivateRoute path="/:tenantId/broadcasts" component={Broadcasts} exact />
      <PrivateRoute path="/:tenantId/broadcasts/_new" component={Broadcast} exact />
      <PrivateRoute path="/:tenantId/broadcasts/:broadcastId" component={Broadcast} exact />
      <PrivateRoute path="/:tenantId/broadcasts/:broadcastId/creative" component={BroadcastCreativePreview} exact />
      <PrivateRoute path="/:tenantId/broadcasts/:broadcastId/page" component={BroadcastPagePreview} exact />
      <PrivateRoute path="/:tenantId/broadcasts/:broadcastId/_edit" component={Broadcast} exact />
      <PrivateRoute path="/:tenantId/broadcasts/:broadcastId/_publish" component={Broadcast} exact />
      <PrivateRoute path="/:tenantId/responses" component={Responses} exact />
      <PrivateRoute path="/:tenantId/templates" component={Templates} exact />
      <PrivateRoute path="/:tenantId/templates/_new" component={Template} exact />
      <PrivateRoute path="/:tenantId/templates/:templateId" component={Template} exact />
      <PrivateRoute path="/:tenantId/credits" component={CreditTransactions} exact />
      <PrivateRoute path="/:tenantId/credits/:creditTransactionId" component={CreditTransaction} exact />
      <PrivateRoute path="/:tenantId/members" component={Members} exact />
      <PrivateRoute path="/:tenantId/members/:userId" component={Member} exact />
      <PrivateRoute path="/:tenantId/refcodes" component={ReferralCodes} exact />
      <PrivateRoute path="/:tenantId/refcodes/:referralCode" component={ReferralCode} exact />
      <PrivateRoute path="/:tenantId/income" component={Incomes} exact />

      <PrivateRoute path="/:tenantId/expressPublish" component={ExpressPublish} exact />

      <Route component={Page404} />

    </Switch>
  )
}