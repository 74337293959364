import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup, FormGroups} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import MainMenu from "../MainMenu"
import SystemTabs from "./SystemTabs"
import getLogger from "../components/Logging.js"
import '../App.css'
import {AsyncPaginate} from "react-select-async-paginate";

const log = getLogger("Country")

export default function Country(props) {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [countryCode, setCountryCode] = useState(null)
  const [countryName, setCountryName] = useState(null)
  const [callingCode, setCallingCode] = useState(null)
  const [isAdding, setAdding] = useState(null)

  const [touched, setTouched] = useState(false)

  const [status, setStatus] = useState(null)

  useEffect(() => {
    if (params.countryCode) {
      if (params.countryCode.trim().toLowerCase() === "_new") {
        setCountryCode(null)
        setCountryName(null)
        setCallingCode(null)
        setAdding(true)
      }
      else {
        setCountryCode(params.countryCode)
        setAdding(false)
      }
    }
  }, [])

  useEffect(() => {

    log.debug("useEffect: invoked")

    if (countryCode) {
      setBusy(intl.msg("loading"))
      api.getCountry(countryCode)
        .then(({data}) => {
          log.debug("getCountry: data=", data)
          setCountryCode(data.countryCode)
          setCountryName(data.countryName)
          setCallingCode(data.callingCode)
          setStatus(data.status)
        })
        .catch((error) => {
          setFatal(error)
        })
        .finally(() => {
          setBusy(null)
        })
    }

  }, [countryCode]);

  function handleCountryCodeChange(e) {
    const v = e.target?.value
    setCountryCode(v)
    setTouched(true)
  }

  function handleCountryNameChange(e) {
    const v = e.target?.value
    setCountryName(v)
    setTouched(true)
  }

  function handleCallingCodeChange(e) {
    const v = e.target?.value
    setCallingCode(v)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_countryCode", countryCode && countryCode.trim().length > 0 , "required", opt)
    validateField("inp_countryName", countryName && countryName.trim().length > 0, "required", opt)
    validateField("inp_callingCode", callingCode && callingCode.trim().length > 0 , "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        const payload = {
          countryCode: countryCode,
          countryName: countryName,
          callingCode: callingCode,
          status: status
        }

        if (isAdding) {
          const {data} = await api.insertCountry(payload)
        }
        else {
          const {data} = await api.updateCountry(countryCode, payload)
        }

        window.location = `/sys/countries`
      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("country_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("country_title")} {" - " + (isAdding ? "(new)" : countryCode)}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              {
                touched &&
                  <>
                    <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                      <i className="fas fa-check"></i>{intl.msg("save")}
                    </button>
                    <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                      <i className="fas fa-times"></i>{intl.msg("cancel")}
                    </button>
                  </>
              }
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_countryCode" text={intl.msg("country_code")} description={intl.msg("country_code_description")}/>
            <FormGroup.Controls>
              <input id="inp_countryCode" type="text" className="form-control" value={countryCode} disabled={!isEditable()} onChange={handleCountryCodeChange}/>
              <InfoBlock>
                <InfoText validate="unique">{intl.msg("country_code_helptext_unique")}</InfoText>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_countryName" text={intl.msg("country_name")} description={intl.msg("country_name_description")}/>
            <FormGroup.Controls>
              <input id="inp_countryName" type="text" className="form-control" value={countryName} disabled={!isEditable()} onChange={handleCountryNameChange}/>
              <InfoBlock>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_callingCode" text={intl.msg("country_calling_code")} description={intl.msg("country_calling_code_description")}/>
            <FormGroup.Controls>
              <input id="inp_callingCode" type="text" className="form-control" value={callingCode} disabled={!isEditable()} onChange={handleCallingCodeChange}/>
              <InfoBlock>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

        </FormBody>
      </MainContainer>

    </>

  )
}
