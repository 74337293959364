import React, { Fragment, useState, useEffect } from 'react'
import '../App.css'

function ToggleButton(props) {

  const {checked: p_checked, readonly: p_readonly, onChange: p_onChange} = props
  const readonly_cls = p_readonly ? "readonly" : ""
  const [checked, setChecked] = useState(p_checked)

  useEffect(() => {
    setChecked(p_checked);
  }, [p_checked]);

  function triggerToggle() {
    if (!p_readonly) {
      const c = !checked
      setChecked(c)
      p_onChange && p_onChange(c)
    }
  }
    
  return (
      <>
        <div id={props.id} style={{width: "58px"}}>
          {
            checked ? (
              <div className={`toggle-btn on noselect ${readonly_cls}`} onClick={triggerToggle}>
                <div className="toggle-btn-label">
                  {props.onLabel ? props.onLabel : "On"}
                </div>    
                <div className="toggle-btn-indicator">
                  &nbsp;
                </div>       
              </div>                    
            ) : (
              <div className={`toggle-btn off noselect ${readonly_cls}`} onClick={triggerToggle}>
                <div className="toggle-btn-indicator">
                  &nbsp;
                </div>       
                <div className="toggle-btn-label">
                {props.offLabel ? props.offLabel : "Off"}
                </div>    
              </div>                
            )
          }
        </div>
      </>
  )    
}

export default ToggleButton