import React, { createContext,  useState, useEffect } from "react"
import axios from 'axios'
import { useLinkedIn } from 'react-linkedin-login-oauth2'
import getLogger from "./Logging.js"
import '../App.css'

const BASE_URL = process.env.REACT_APP_BASE_URL
const LINKEDIN_CLIENT_ID = process.env.REACT_APP_LINKEDIN_CLIENT_ID

const log = getLogger("LinkedInButton")

export const LinkedInButton = (props) => {

  const fetchProfile = (code) => {
    const url = `/auth/linkedin/accessToken?code=${code}`
    log.debug(`fetchProfile: url=${url}`)
    return axios.get(url)  
      .then(function (response) {
        log.debug("fetchProfile: response=", response)
        if (response.data.error) {
          log.debug("fetchProfile: error=", response.data.error)
        }
        else {
          log.debug("fetchProfile: data=", response.data)
          return null
        }
    })
  }

  const { linkedInLogin } = useLinkedIn({
    clientId: `${LINKEDIN_CLIENT_ID}`,
    redirectUri: `${BASE_URL}/linkedin`, 
    scope: "r_emailaddress r_liteprofile",
    onSuccess: (code) => {
      log.debug("Linkedin Success :) code=", code);
      props.onSuccess(code)
      // return fetchProfile(code)  
    },
    onError: (error) => {
        log.debug("Linkedin Failed :( error=", error);
    },
  })

  return (
    <button type="button" onClick={linkedInLogin} disabled={props.disabled} style={{width: "210px", height: "44px", backgroundColor: "rgb(48, 119, 176)", display: "inline-flex", alignItems: "center", color: "rgb(255, 255, 255)", boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px", padding: "0px", borderRadius: "5px", border: "1px solid transparent", fontSize: "14px", fontWeight: "500", fontFamily: "Roboto, sans-serif"}}>
      <div style={{display: "flex", alignItems: "center", marginRight: "0px", background: "rgb(48, 119, 176)", padding: "10px", borderRadius: "2px", height: "42px"}}>
          <img src="/images/logo-linkedin.png" style={{width: "25px", height: "auto", padding: "0 0 2px 0"}}/>
      </div>
      <span style={{padding: "10px 10px 10px 0px", fontWeight: "700"}}>{props.buttonText}</span>
    </button>
  )
}