import React from 'react'

export function numberWithCommas(v, decimals) {
  // return v.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  let n
  if (decimals) {
    n = v.toFixed(decimals)  
  }
  else {
    n = v.toString()
  }
  return n.replace(/\d(?=(\d{3})+\.)/g, '$&,')
}

String.prototype.trimSpaces = function () {
  return this.replace(/  +/g, ' ')
}

String.prototype.stripSpaces = function () {
  return this.replace(/\s/g,'')
}

export function stripCommas(v) {
  return v.replaceAll(",", "")
}

export function isBlankString(str) {
  return (!str || /^\s*$/.test(str))
}

export function shortIdEqual(id1, id2) {
  return (id1?.replace(/-/g, "") === id2?.replace(/-/g, ""))
}

const currencyHtmlCodes = {
  "ARS": "&#8369;",
  "AUD": "&#65284;",
  "CAD": "&#65284;",
  "CHF": "&#8355;",
  "CNY": "&#20803;",
  "EUR": "&euro;",
  "GBP": "&pound;",
  "HKD": "&#65284;",
  "INR": "&#8377;",
  "JPY": "&#65509;",
  "KRW": "&#8361;",
  "PHP": "&#8369;",
  "PKR": "&#8360;",
  "THB": "&#3647;",
  "TRY": "&#8378;",
  "RUB": "&#8381;",
  "SGD": "&#65284;",
  "USD": "&#65284;",
}

export function CurrencySymbol(props) {
  return (
    <></>
    // props.currencyCode in currencyHtmlCodes ? (
    //   <span dangerouslySetInnerHTML={{__html: currencyHtmlCodes[props.currencyCode]}}/>
    // ) : (
    //   <></>
    // )
  )
}

export function capFirst(string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }
  else {
    return null
  }
}

String.prototype.toCapitalized = function() {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase()
}

String.prototype.toShortId = function() {
  return this.replaceAll("-", "")
}

export function toHtmlEntities(s) {
  return s.replace(/./gm, function(s) {
    return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
  })
}

export function parseBool(val) {
  switch (typeof val) {
    case "boolean":
      return val
    case "string":
      if ("true" === val.toLowerCase()) {
        return true
      }
      else {
        return false
      }
    case "number":
      if (val > 0) {
        return true
      }
      else {
        return false
      }
    default:
      return false
  }
}

// String.prototype.toHtmlEntities = function() {
//   return this.replace(/./gm, function(s) {
//       // return "&#" + s.charCodeAt(0) + ";";
//       return (s.match(/[a-z0-9\s]+/i)) ? s : "&#" + s.charCodeAt(0) + ";";
//   });
// };
