import React, { Fragment, useState }  from 'react'
import { useIntl } from "react-intl"
import * as dayjs from 'dayjs'
import {LOCALES} from "../i18n/locales";
import {da} from "date-fns/locale";

export const defaultIntlParams = {}

export function useIntlEx() {

    const fromNowRegex = new RegExp("(an?|[0-9]+) (second|minute|hour|day|week|month|quarter|year)(s)?")

    const intl = useIntl()

    const msg = (id, params) => {
      params = {...defaultIntlParams, ...params}
      return intl.formatMessage({id: id}, params)
    }

    intl.msg = msg

    const intFormat = new Intl.NumberFormat(intl.locale,{ minimumFractionDigits: 0, maximumFractionDigits: 0 })
    const intCompactFormat = new Intl.NumberFormat(intl.locale,{ minimumFractionDigits: 0, maximumFractionDigits: 2, notation: "compact" })
    const numFormat = new Intl.NumberFormat(intl.locale,{ minimumFractionDigits: 2, maximumFractionDigits: 2 })
    const numCompactFormat = new Intl.NumberFormat(intl.locale,{ minimumFractionDigits: 2, maximumFractionDigits: 2, notation: "compact" })
    const numCurrencyFormat = (ccy) => new Intl.NumberFormat(intl.locale,{ style: "currency", currency: ccy, currencyDisplay: "code", minimumFractionDigits: 2, maximumFractionDigits: 2 })
    const numAccountingFormat = {
      format: function (n) {
        n = parseFloat(n)
        if (n < 0) {
          return `(${numFormat.format(Math.abs(n))})`
        }
        else {
          return numFormat.format(n)
        }
      }
    }

    const longDateFormat = new Intl.DateTimeFormat(intl.locale, { dateStyle: 'long' })
    const mediumDateFormat = new Intl.DateTimeFormat(intl.locale, { dateStyle: 'medium' })
    const shortDateFormat = new Intl.DateTimeFormat(intl.locale, { dateStyle: 'short' })
    const isoDateFormat = {
      format: function (dateTime) {
        let d
        if (dateTime.getMonth) {
          d = dateTime
        }
        else {
          d = new Date(dateTime)
        }
        return  `${d.getUTCFullYear()}-${(d.getUTCMonth() + 1).toString().padStart(2, '0')}-${d.getUTCDate().toString().padStart(2, '0')}`
      }
    }
    const isoCompactDateFormat = {
      format: function (dateTime) {
        let d
        if (dateTime.getMonth) {
          d = dateTime
        }
        else {
          d = new Date(dateTime)
        }
        return  `${d.getUTCFullYear()}${(d.getUTCMonth() + 1).toString().padStart(2, '0')}${d.getUTCDate().toString().padStart(2, '0')}`
      }
    }
    const longTimeFormat = new Intl.DateTimeFormat(intl.locale, { timeStyle: 'long' })
    const mediumTimeFormat = new Intl.DateTimeFormat(intl.locale, { timeStyle: 'medium' })
    const shortTimeFormat = new Intl.DateTimeFormat(intl.locale, { timeStyle: 'short' })
    const isoTimeFormat = {
      format: function (dateTime) {
        let d
        if (dateTime.getHours) {
          d = dateTime
        }
        else {
          d = new Date(dateTime)
        }
        return  `${d.getUTCHours().toString().padStart(2, '0')}:${(d.getUTCMinutes() + 1).toString().padStart(2, '0')}:${d.getUTCSeconds().toString().padStart(2, '0')}`
      }
    }
    const isoCompactTimeFormat = {
      format: function (dateTime) {
        let d
        if (dateTime.getHours) {
          d = dateTime
        }
        else {
          d = new Date(dateTime)
        }
        return  `${d.getUTCHours().toString().padStart(2, '0')}${(d.getUTCMinutes() + 1).toString().padStart(2, '0')}${d.getUTCSeconds().toString().padStart(2, '0')}`
      }
    }
    const longDateTimeFormat = new Intl.DateTimeFormat(intl.locale, { dateStyle: 'long', timeStyle: 'long' })
    const mediumDateTimeFormat = new Intl.DateTimeFormat(intl.locale, { dateStyle: 'medium', timeStyle: 'medium' })
    const shortDateTimeFormat = new Intl.DateTimeFormat(intl.locale, { dateStyle: 'short', timeStyle: 'short' })
    const isoDateTimeFormat = {
      format: function (dateTime) {
        return isoDateFormat.format(dateTime) + "T" + isoTimeFormat.format(dateTime)
      }
    }
    const isoCompactDateTimeFormat = {
      format: function (dateTime) {
        return isoCompactDateFormat.format(dateTime) + "T" + isoCompactTimeFormat.format(dateTime)
      }
    }
    const relativeTimeFormatter = new Intl.RelativeTimeFormat(intl.locale, {numeric: 'auto'})

    const DIVISIONS = [
      { amount: 60, name: 'seconds' },
      { amount: 60, name: 'minutes' },
      { amount: 24, name: 'hours' },
      { amount: 7, name: 'days' },
      { amount: 4.34524, name: 'weeks' },
      { amount: 12, name: 'months' },
      { amount: Number.POSITIVE_INFINITY, name: 'years' }
    ]

    intl.regionDisplayNames = new Intl.DisplayNames([intl.locale], {type: "region"})

    intl.regionDisplayName = function (regionId) {
      if (regionId) {
        try {
          return intl.regionDisplayNames.of(regionId)
        }
        catch (err) {
          return ""
        }
      }
      else {
        return ""
      }
    }

    intl.date = function (dateTime, format) {
      if (dateTime) {
        let fmt
        switch (format) {
          case "short":
          case "SHORT":
            fmt = shortDateFormat
            break
          case "long":
          case "LONG":
            fmt = longDateFormat
            break
          case "iso":
          case "ISO":
            fmt = isoDateFormat
            break
          case "iso-compact":
          case "ISO-COMPACT":
            fmt = isoCompactDateFormat
            break
          default:
            fmt = mediumDateFormat
            break
        }
        return fmt.format(dateTime)
      }
      else {
        return ""
      }
    }

    intl.time = function (dateTime, format) {
      let fmt
      switch (format) {
        case "short":
        case "SHORT":
          fmt = shortTimeFormat
          break
        case "long":
        case "LONG":
          fmt = longTimeFormat
          break
        case "iso":
        case "ISO":
          fmt = isoTimeFormat
          break
        case "iso-compact":
        case "ISO-COMPACT":
          fmt = isoCompactTimeFormat
          break
        default:
          fmt = mediumTimeFormat
          break
      }
      return fmt.format(dateTime)
    }

    intl.dateTime = function (dateTime, format) {
        let fmt
        switch (format) {
          case "short":
          case "SHORT":
            fmt = shortDateTimeFormat
            break
          case "long":
          case "LONG":
            fmt = longDateTimeFormat
            break
          case "iso":
          case "ISO":
            fmt = isoDateTimeFormat
            break
          case "iso-compact":
          case "ISO-COMPACT":
            fmt = isoCompactDateTimeFormat
            break
          default:
            fmt = mediumDateTimeFormat
            break
        }
        return fmt.format(dateTime)
    }

    intl.fmFromNow = function(dateTime) {
        const dt = dayjs(dateTime)
        let value = ""
        let unit = "second"
        const fromNow = dt.fromNow().trim()
        if (fromNow === "a few seconds ago") {
            const s = dayjs().diff(dt, "second")
            value = s
            if (s > 1) {
                unit = unit + "s"
            }
        }
        const r = fromNow.match(fromNowRegex)
        if (r && r.length > 1) {
            value = r[1]
            if (value === "a" || value === "an") {
                value = "1"
            }
            unit = r[2]
            if (r[3]) {
                unit += "s"
            }
        }
        return intl.msg("time_from_now", {value: value, unit: intl.msg(`time_unit_${unit}`)})
    }

    intl.fmToNow = function(dateTime) {
        const dt = dayjs(dateTime)
        let value = ""
        let unit = "second"
        const toNow = dt.toNow(true).trim()
        if (toNow === "a few seconds") {
            const s = dt.diff(dayjs(), "second")
            value = s
            if (s > 1) {
                unit = unit + "s"
            }
        }
        const r = toNow.match(fromNowRegex)
        if (r && r.length > 1) {
            value = r[1]
            if (value === "a" || value === "an") {
                value = "1"
            }
            unit = r[2]
            if (r[3]) {
                unit += "s"
            }
        }
        return intl.msg("time_to_now", {value: value, unit: intl.msg(`time_unit_${unit}`)})
    }

    intl.fmNowRel = function(dateTime) {
      if (dayjs(dateTime).isAfter(dayjs())) {
        return intl.fmToNow(dateTime)
      }
      else {
        return intl.fmFromNow(dateTime)
      }
    }

    intl.fmDaysFrom = function(dateTime) {
        const dt = dayjs(dateTime)
        return intFormat.format(dt.diff(dayjs(), "day"))
    }

    intl.int = function (num, format) {
      let fmt = intFormat
      switch (format) {
        case "compact":
        case "COMPACT":
          fmt = intCompactFormat
          break
        default:
          fmt = intFormat
          break
      }
      return fmt.format(num)
    }

    intl.num = function (num, format, opt) {
      let fmt = numFormat
      switch (format) {
        case "compact":
        case "COMPACT":
          fmt = numCompactFormat
          break
        case "currency":
        case "CURRENCY":
          fmt = numCurrencyFormat(opt?.currencyCode || "USD")
          break
        case "accounting":
        case "ACCOUNTING":
          fmt = numAccountingFormat
          break
        default:
          fmt = numFormat
          break
      }
      return fmt.format(num)
    }

    intl.Fm = function(props) {
        return (
            props.text ? (
                intl.formatMessage({id: props.text}, props.params)
            ) : props.int ? (
                intFormat.format(props.int)
            ) : props.num ? (
                intFormat.format(props.num)
            ) : props.dateTime ? (
                intl.formatDateTime(props.dateTime)
            ) : props.fromNow ? (
                intl.formatFromNow(props.dateTime)
            ) : (
                <></>
            )
        )
    }

    intl.numFormat = numFormat
    intl.intFormat = intFormat

    return { intl }
}

const langOpts = Object.keys(LOCALES).map(l => {
  const engName = LOCALES[l].engName
  return {
    value: l,
    label: (LOCALES[l].langCode === "en") ? `${LOCALES[l].localName}` : `${LOCALES[l].localName} (${engName})`,
    engName: engName
  }
})

langOpts.sort((a, b) => a.engName.localeCompare(b.engName))

export function getAllLangOpts() {
  return langOpts
}

export function getLangOpt(lang) {
  return langOpts.find(i => i.value === lang)
}

