import React  from 'react'
import '../App.css'

export function TableHeadColumn(props) {

    function renderSort(sortSpec, field) {
    if (sortSpec.field === field) {
      if (sortSpec.direction === "ASC") {
        return <span>&#9651;</span>   
      }
      else {
        return <span>&#9661;</span>   
      }
    }
    else {
      return <span></span>
    }
  }
  
  function handleColumnClick(sortSpec, field) {
    if (sortSpec.field === field) {
      if (sortSpec.direction === "ASC") {
        props.setSortSpec(prev => {return {field: field, direction: "DESC"}})
      }
      else {
        props.setSortSpec(prev => {return {field: field, direction: "ASC"}})
      }
    } 
    else {
      props.setSortSpec(prev => {return {field: field, direction: "ASC"}})
    }   
  }

  return (
    <>
      <th scope="col" className={props.clickable ? "col-clickable noselect" : "noselect"} onClick={() => props.clickable ? handleColumnClick(props.sortSpec, props.field) : null}>
        <label className={props.clickable ? "sortable-column" : ""}>{props.label} {renderSort(props.sortSpec, props.field)}</label>
      </th>
    </>
  )
  
}