import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";
import './App.css'
import {InfoBlock, InfoText} from "./components/ValidationUtils";

const log = getLogger("CreditTransactionReverseDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CreditTransactionReverseDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)

  const [creditTransactionId, setCreditTransactionId] = useState(null)
  const [credits, setCredits] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [description, setDescription] = useState(null)
  const [touched, setTouched] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      setTenant(props.tenant)
    }
    if (props.creditTransactionId) {
      setCreditTransactionId(props.creditTransactionId)
    }
    if (props.credits) {
      if (props.credits > 0) {
        setCredits(`-${intl.num(props.credits)} ${props.currencyCode}`)
      }
      else {
        setCredits(`+${intl.num(Math.abs(props.credits))} ${props.currencyCode}`)
      }
    }
  }, [props])

  function handleReverseClick() {
    log.debug("handleReverseClick: invoked")
    setBusy(intl.msg("working"))
    const payload = {
      creditTransactionId: creditTransactionId,
      description: description,
      details: {},
    }
    api.reverseCreditTransaction(tenant.tenantId, payload)
      .then((data) => {
        window.location = mkHref({suffix: `/credits`, tenant: tenant})
      })
      .catch((error) => {
        handleError(error)
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      switch (code) {
        case "DUPLICATE_KEY":
          setAlert({error: intl.msg("credit_transaction_reverse_error_duplicate")})
          break
        case "INSUFFICIENT_FUNDS":
          setAlert({error: intl.msg("credit_transaction_reverse_error_insufficient_funds")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
          break
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value)
    setTouched(true)
  }

  function handleOpenDialog() {
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_credit_transaction_reverse")
  }


  return (

    tenant &&
    <ModalDialog
      dialogId="dlg_credit_transaction_reverse"
      size="lg" keyboard={"true"} backdrop={props.backdrop || "static"}
      header={<h1>{intl.msg("credit_transaction_reverse_title")}</h1>}
      footer={
        <>
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            {intl.msg("cancel")}
          </button>
          {
            touched &&
              <button type="button" class="btn btn-danger" onClick={handleReverseClick}>
                {intl.msg("reverse")}
              </button>
          }
        </>
      }
      alert={alert}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}>

      <p>{intl.msg("credit_transaction_reverse_text", {credits: credits})}</p>

      <form id="frmMain" autoComplete="off">

        <div className="form-card">

          <div className="row">

            <div className="col">

              <div className="form-group row">
                <label className="col-sm-3 col-form-label" htmlFor="inp_description">{intl.msg("description")}</label>
                <div className="col-sm-9">
                  <input id="inp_description" name="description" type="text" className="form-control" value={description} disabled={false} onChange={handleDescriptionChange} autoComplete="no"/>
                  <InfoBlock>
                    <InfoText>{intl.msg("credit_transaction_reverse_description_helptext_1")}</InfoText>
                  </InfoBlock>
                </div>
              </div>

            </div>

          </div>

        </div>

      </form>

    </ModalDialog>
  )
}