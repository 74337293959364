import React, { Fragment, useState, useEffect, useRef }  from 'react'
import { useHistory, useLocation }  from 'react-router-dom'
import CreatableSelect from 'react-select/async'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { v4 as uuidv4 } from 'uuid'
import { useSolv } from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils.js"
import { validateField, clearAllErrors, InfoBlock, InfoText} from "./components/ValidationUtils.js"
import { fieldLayoutOpts, fieldLabelStyleOpts, fieldValueStyleOpts, getCustomFieldTypeOptions } from './components/CustomFieldUtils.js';
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CustomFieldEditDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CustomFieldEditDialog(props)  {

  const { api, auth: { session: { user, tenant } } } = useSolv()
  const { intl } = useIntlEx()

  const location = useLocation()
  const history = useHistory()
    
  const [fieldId, setFieldId] = useState(null)
  const [fieldLabel, setFieldLabel] = useState(null)
  const [fieldValue, setFieldValue] = useState(null)
  const [fieldType, setFieldType] = useState(null)
  const [fieldDescription, setFieldDescription] = useState(null)
  const [fieldInputMode, setFieldInputMode] = useState(null)
  const [fieldSelectList, setFieldSelectList] = useState(null)
  const [fieldSelectListInputValue, setFieldSelectListInputValue] = useState(null)
  const [fieldLayout, setFieldLayout] = useState(null)
  const [fieldLabelStyle, setFieldLabelStyle] = useState(null)
  const [fieldValueStyle, setFieldValueStyle] = useState(null)
    
  const selectRef = useRef(null)
  const btnCloseRef = useRef(null)
  
  const [touched, setTouched] = useState(true)
  const [working, setWorking] = useState(null)
  const [info, setInfo] = useState(null)
  const [error, setError] = useState(null)

  function findOptsValue(opts, v) {
    return v
  }
  
  function isAddMode() {
    return (!props.field)
  }
      
  useEffect(() => {

    if (isAddMode()) {
      setFieldId(uuidv4().replace(/[\-]+/g,''))
      setFieldLabel("")
      setFieldValue("")
      setFieldType("TEXT_BLOCK")
      setFieldDescription("")
      setFieldInputMode("OPTIONAL")
      setFieldSelectList(null)
      setFieldLayout(findOptsValue(fieldLayoutOpts, "LCELL"))
      setFieldLabelStyle(findOptsValue(fieldLabelStyleOpts, "BOLD"))
      setFieldValueStyle(findOptsValue(fieldValueStyleOpts, "NORMAL"))        
    } 
    else {
      setFieldId(props.field.id)
      setFieldLabel(props.field.label)
      setFieldValue(props.field.value)
      setFieldType(props.field.type)
      setFieldDescription(props.field.desc)
      setFieldInputMode(props.field.input)
      setFieldSelectList(null)
      setFieldLayout(findOptsValue(fieldLayoutOpts, "LCELL"))
      setFieldLabelStyle(findOptsValue(fieldLabelStyleOpts, "BOLD"))
      setFieldValueStyle(findOptsValue(fieldValueStyleOpts, "NORMAL"))
      
      const opts = props.field.opts
      if (opts) {

        if (opts.selectList) {
          const v = opts.selectList.split(",").map(s => {return {value: s.trim(), label: s.trim()}})
          setFieldSelectList(v)
        }
        else {
          setFieldSelectList(null)
        }

        if (opts.layout) {
          const o = findOptsValue(fieldLayoutOpts, opts.layout)
          if (o) {
            setFieldLayout(o)
          }
        }
      
        if (opts.labelStyle) {
          const o = findOptsValue(fieldLabelStyleOpts, opts.labelStyle)
          if (o) {
            setFieldLabelStyle(o)
          }
        }

        if (opts.valueStyle) {
          const o = findOptsValue(fieldValueStyleOpts, opts.valueStyle)
          if (o) {
            setFieldValueStyle(o)
          }
        }
        
      }
      else {
        
      }
      // setFieldShowLabel(props.field.showLabel)
    }
  }, [props])       

  function validate() {
  
    let opt = {focusEl: null, valid: true}


    validateField(`inp_fieldLabel_${props.id}`, fieldLabel && fieldLabel.trim().length > 0, "non-empty", opt)
    validateField(`inp_fieldLabel_${props.id}`, !props.fields || props.fields.findIndex(e => e.label === fieldLabel && e.id !== fieldId) === -1, "unique", opt)

    // validateField(`inp_description_${props.id}`, fieldDescription && fieldDescription.trim().length > 0, "non-empty", opt)

    if ("SELECT" === fieldType) {
      // validateField(`inp_fieldOpts_select_${props.id}`, fieldOpts && fieldOpts.length > 0, "non-empty", opt)
    }

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }
    // }

    return opt.valid
  }

  function resetState() {
    if (isAddMode()) {
      setFieldLabel("")
      setFieldDescription("")
      setFieldInputMode("OPTIONAL")
      setFieldLayout("LCELL")
      setFieldLabelStyle("BOLD")
      setFieldValueStyle("NORMAL")
    }
    setWorking(null)
    setError(null)
    clearAllErrors(`frmMain-${props.id}`)
  }
    
  async function handleApplyClick() {
    if (validate()) {
      try {
        setWorking(intl.msg("working"))
        let g
        if (isAddMode()) {
          g = {}
        }
        else {
          g = props.field
        }
        if (isAddMode()) {
          g.id = uuidv4().replace(/[\-]+/g,'')
        }
        else {
          g.id = fieldId
        }
        g.label = fieldLabel
        g.type = fieldType
        g.value = fieldValue
        g.desc = fieldDescription
        g.input = fieldInputMode
        const opts = {}
        if (fieldSelectList && fieldSelectList.length) {
          opts.selectList = fieldSelectList.map(o => o.value).join(",")
        }
        if (fieldLayout) {
          opts.layout = fieldLayout
        }
        if (fieldLabelStyle) {
          opts.labelStyle = fieldLabelStyle
        }
        if (fieldValueStyle) {
          opts.valueStyle = fieldValueStyle
        }
        g.opts = opts
        if (isAddMode()) {
          if (props.fields && props.fields.length > 0) {
            props.setFields([
              ...props.fields,
              g
            ])
          }
          else {
            props.setFields([g])
          }            
        }
        else {
          if (props.fields && props.fields.length > 1) {
            let index = props.fields.findIndex(e => e.id === props.field.id)
            props.setFields([
              ...props.fields.slice(0, index),
              g,
              ...props.fields.slice(index + 1),
            ])
          }
          else {
            props.setFields([g])
          }
        }
        props.setTouched(true)
        setTouched(false)
        resetState()
        btnCloseRef.current.click()
      }
      finally {
        setWorking(null)
      }
    }
    else {
      setError(intl.msg("error_failed"))
    }
  }
  
  function handleError(error) {
    if (error && error.code) {
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setError(intl.msg("template_field_duplicate_label"))
      }
      else {
        setError(intl.msg("error_failed"))
      } 
    }
    else {
      setError(intl.msg("error_failed"))
    }
  }  

  function handleFieldLabelChange(e) {
    setFieldLabel(e.target.value)
    setTouched(true)
  }    

  function handleFieldTypeChange(e) {
    const v = e.target.value
    setFieldType(v)
    switch (v) {
      case "STATIC":
        setFieldLabel(uuidv4().replace(/[\-]+/g,''))
        setFieldValue("")
        setFieldInputMode("READONLY")
        break
      case "SELECT":
        break;  
      default:
        
    }
    setTouched(true)
  }

  function handleFieldDescriptionChange(e) {
    setFieldDescription(e.target.value)
    setTouched(true)
  }

  function handleFieldInputModeChange(e) {
    setFieldInputMode(e.target.value)
    setTouched(true)
  }

  function handleFieldLayoutChange(e) {
    const v = e.target.value
    setFieldLayout(v)
    setTouched(true)
  }
  
  function handleFieldLabelStyleChange(e) {
    const v = e.target.value
    setFieldLabelStyle(v)
    setTouched(true)
  }

  function handleFieldValueStyleChange(e) {
    const v = e.target.value
    setFieldValueStyle(v)
    setTouched(true)
  }

  function createOption(label) {
    return (
        {
            label,
            value: label,
        }
    )
  }

  function handleFieldSelectListKeyDown(event) {
    if (!fieldSelectListInputValue) return;
    switch (event.key) {
      case "Enter":
        setFieldSelectList(prevValue => prevValue && prevValue.length ? [...prevValue, createOption(fieldSelectListInputValue)] : [createOption(fieldSelectListInputValue)])
        setFieldSelectListInputValue("")
        event.preventDefault();
    }        
  }

  function handleFieldSelectListBlur(e) {
    if (!fieldSelectListInputValue) return;
    setFieldSelectList(prevValue => prevValue && prevValue.length ? [...prevValue, createOption(fieldSelectListInputValue)] : [createOption(fieldSelectListInputValue)])
    setFieldSelectListInputValue("")
  }

  function isEditable() {
    return true
  }

  return (

    <>

      <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
        <p>{working}</p>
      </div>

      <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby={props.id} aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{intl.msg(`template_${props.dialogType.toLowerCase()}_field_${isAddMode() ? "add" : "edit"}_title`)}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
            
              <div className="row">

                <div className="col">

                  {
                      error &&
                        <div className="alert alert-danger" style={error ? {display: "block"} : {display: "none"}}>
                          <span>{error}</span>
                        </div>
                  }

                  { info &&
                      <div className="alert alert-dismissible alert-success" style={info ? {display: "block"} : {display: "none"}}>
                        <span>{info}</span>
                      </div>
                  }

                  <form id={`frmMain-${props.id}`} autoComplete="off">

                    <div className="form-card">
                          
                      <div className="row">
                                  
                        <div className="col-12">

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor={`inp_fieldLabel_${props.id}`}>{intl.msg("template_field_edit_field_label")}</label>
                            <div className="col-sm-9">
                              <input id={`inp_fieldLabel_${props.id}`} name="label" type="text" className="form-control" value={fieldLabel} disabled={false} onChange={handleFieldLabelChange} autoComplete="no"/>
                              <InfoBlock>
                                <InfoText>{intl.msg("template_field_edit_field_label_helptext_1")}</InfoText>
                                <InfoText validate="unique">{intl.msg("template_field_edit_field_label_helptext_unique", {dialogType: props.dialogType})}</InfoText>
                                <InfoText validate="non-empty">{intl.msg("helptext_required")}</InfoText>
                              </InfoBlock>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor={`inp_type-${props.id}`}>{intl.msg("template_field_edit_field_type")}</label>
                            <div className="col-sm-9">
                              <select
                                id={`inp_type_${props.id}`}
                                value={fieldType}
                                onChange={handleFieldTypeChange}
                                className="form-control">
                                  {
                                    getCustomFieldTypeOptions(fieldType, props.allowedFieldTypes)
                                  }
                              </select>
                              <InfoBlock>
                                <InfoText>{intl.msg("template_field_edit_field_type_helptext_1")}</InfoText>
                              </InfoBlock>
                            </div>
                          </div>

                          {
                            "SELECT" === fieldType &&
                              <div className="form-group row">
                                <label className="col-sm-3 col-form-label" htmlFor={`inp_fieldOpts_select_${props.id}`}>{intl.msg("template_field_edit_select_options")}</label>
                                <div className="col-sm-9">
                                  <CreatableSelect
                                    ref={selectRef}
                                    id={`inp_fieldOpts_select_${props.id}`} 
                                    className="react-select w-100"
                                    classNamePrefix="react-select"
                                    components={{DropdownIndicator: null}}
                                    value={fieldSelectList}
                                    inputValue={fieldSelectListInputValue}
                                    isClearable
                                    isMulti
                                    placeholder=""
                                    menuIsOpen={false}
                                    onChange={v => {setFieldSelectList(v); setTouched(true)}}
                                    onInputChange={v => setFieldSelectListInputValue(v)}
                                    onKeyDown={handleFieldSelectListKeyDown}
                                    onBlur={handleFieldSelectListBlur}
                                    isDisabled={!isEditable()}/>                                            
                                  <InfoBlock>
                                    <InfoText>{intl.msg("template_field_edit_select_options_helptext_1")}</InfoText>
                                    <InfoText>{intl.msg("template_field_edit_select_options_helptext_2")}</InfoText>
                                    <InfoText validate="non-empty">{intl.msg("template_field_edit_select_options_required")}</InfoText>
                                  </InfoBlock>
                                </div>
                              </div>  
                          }

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor={`inp_description_${props.id}`}>{intl.msg("template_field_edit_field_description")}</label>
                            <div className="col-sm-9">
                              <input id={`inp_description_${props.id}`} name="label" type="text" className="form-control" value={fieldDescription} disabled={false} onChange={handleFieldDescriptionChange} autoComplete="no"/>
                              <InfoBlock>
                                <InfoText>{intl.msg("template_field_edit_field_description_helptext_1")}</InfoText>
                              </InfoBlock>
                            </div>
                          </div>               

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor={`inp_inputMode-${props.id}`}>{intl.msg("template_field_edit_field_input")}</label>
                            <div className="col-sm-9">
                              <select
                                id={`inp_inputMode_${props.id}`}
                                value={fieldInputMode}
                                onChange={handleFieldInputModeChange}
                                className="form-control">                                        
                                <option value="OPTIONAL" selected={"OPTIONAL" === fieldInputMode ? "selected" : null}>Optional</option>                                  
                                <option value="READONLY" selected={"READONLY" === fieldInputMode ? "selected" : null}>Read-only</option>                                 
                                <option value="REQUIRED" selected={"REQUIRED" === fieldInputMode ? "selected" : null}>Required</option>                                  
                              </select>
                              <InfoBlock>
                                <InfoText>{intl.msg("template_field_edit_field_input_helptext_1")}</InfoText>
                              </InfoBlock>
                            </div>
                          </div> 

                          <div className="form-group row">
                            <label className="col-sm-3 col-form-label" htmlFor={`inp_opts_layout-${props.id}`}>{intl.msg("template_field_edit_field_layout")}</label>
                            <div className="col-sm-9">
                              <select
                                id={`inp_opts_layout_${props.id}`}
                                value={fieldLayout}
                                onChange={handleFieldLayoutChange}
                                className="form-control">
                                  {
                                    fieldLayoutOpts.map(e =>
                                      (!props.allowedFieldLayouts || props.allowedFieldLayouts.includes(e.value))  &&
                                        <option value={e.value}>{e.label}</option>
                                    )
                                  }
                              </select>
                              <InfoBlock>
                                <InfoText>{intl.msg("template_field_edit_field_layout_helptext_1")}</InfoText>
                              </InfoBlock>
                            </div>
                          </div>

                          <div className="form-group row mb-0">
                            <label className="col-sm-3 col-form-label" htmlFor={`inp_opts_style_label_${props.id}`}>{intl.msg("template_field_edit_field_style")}</label>
                            <div className="col-sm-9">
                              <div className="form-group row mb-0 ml-0">
                                <div className="mr-4">
                                  <div style={{textAlign: "left"}}>
                                    <label>{intl.msg("template_field_edit_field_style_label")}</label>
                                  </div>  
                                  <select
                                    id={`inp_opts_style_label_${props.id}`}
                                    value={fieldLabelStyle}
                                    onChange={handleFieldLabelStyleChange}
                                    className="form-control">
                                      {
                                        fieldLabelStyleOpts.map(e =>
                                          <option value={e.value}>{e.label}</option>
                                        )
                                      }
                                  </select>
                                </div>
                                <div>
                                  <div style={{textAlign: "left"}}>
                                    <label>{intl.msg("template_field_edit_field_style_value")}</label>
                                  </div>
                                  <select
                                    id={`inp_opts_style_label_${props.id}`}
                                    value={fieldValueStyle}
                                    onChange={handleFieldValueStyleChange}
                                    className="form-control">
                                      {
                                        fieldLabelStyleOpts.map(e =>
                                          <option value={e.value}>{e.label}</option>
                                        )
                                      }
                                  </select>
                                </div>
                              </div>
                              <InfoBlock>
                                <InfoText>{intl.msg("template_field_edit_field_style_helptext_1")}</InfoText>
                              </InfoBlock>
                            </div>
                          </div>                            

                        </div>

                      </div>

                    </div>

                  </form> 
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button ref={btnCloseRef} id={`btn_close_${props.id}`} type="button" className="btn btn-secondary" data-dismiss="modal" onClick={resetState}>{intl.msg("cancel")}</button>
              {
                touched &&
                  <button id={`btn_apply_${props.id}`} type="button" className="btn btn-primary" onClick={handleApplyClick}>{isAddMode() ? intl.msg("add") : (intl.msg("apply"))}</button>
              }
            </div>
          </div>
        </div>
      </div>

    </>
  )
}