import React, {Fragment}  from 'react'
import {useParams} from 'react-router-dom'
import {useSolv, useTenant} from "./components/SolvProvider";
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer} from "./components/FormComps.js"
import { useIntlEx } from "./components/IntlUtils.js"
import ClientInviteDialog from './ClientInviteDialog.js'
import getLogger from "./components/Logging.js"
import {AccountStatus} from "./components/AccountUtils"
import {openDialogCurried} from "./components/DialogUtils";
import AccountTabs from "./AccountTabs";
import {UserLink} from "./components/UserUtils";
import {TagLabel} from "./components/TagBox";
import {DataTable, useCols} from "./components/TableComps";
import './App.css'

const log = getLogger("Clients")

export default function Clients(props) {

  const params = useParams()

  const {api, session} = useSolv()
  const {intl} = useIntlEx()

  const {tenant} = useTenant(params.tenantId)

  const {cols} = useCols({
    specs: [
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "resellerTenantName",
        label: intl.msg("reseller"),
        filter: "text",
        sort: "",
        align: "left",
        visible: () => (session.user.isSystem() || tenant.hasSubTenants())
      },
      {
        field: "ownerDisplayName",
        label: intl.msg("owner"),
        filter: "text",
        sort: "",
        align: "left"
      },
      {
        field: "regionId",
        label: intl.msg("region"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listRegions: invoked")
            return api.listRegions({view: "BASIC", extraParams: `${input ? `filter=regionName:${encodeURIComponent(input)}&` : ""}sort=regionName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.regionId,
                    label: c.regionName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadRegions: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "industryId",
        label: intl.msg("industry"),
        filter: {
          type: "asyncselect",
          loader: (input, loadedOptions, additional) => {
            log.debug("listIndustries: invoked")
            return api.listIndustries({view: "BASIC", extraParams: `${input ? `filter=industryName:${encodeURIComponent(input)}&` : ""}sort=industryName:ASC`, cursor: additional?.cursor, limit: 20})
              .then(({data, nextCursor}) => {
                let opt = data.map(c => {
                  return {
                    value: c.industryId,
                    label: c.industryName,
                    pop: c.pop
                  };
                });
                let res = {
                  options: opt,
                }
                if (nextCursor) {
                  res = {
                    ...res,
                    hasMore: true,
                    additional: {
                      cursor: nextCursor
                    }
                  }
                }
                log.debug("loadIndustries: res=", res)
                return res
              });
          }
        },
        sort: "",
        align: "left",
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: "select:accountStatus",
        multi: true,
        sort: "",
        align: "left",
      },
    ],
    storageItemName: "clients",
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listClients({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "tenantName":
        n = <span>{row.tenantName}</span>
        break
      case "resellerTenantName":
        n = <div className="d-flex gap-1">
              <a className="text-link" href={`/${row.resellerTenantId}`}>
                {row.resellerTenantName}
              </a>
              {/*<span>*/}
              {/*  {tenant && row && row.resellerTenantId.replace(/\-/g, "") === tenant.tenantId.replace(/\-/g, "") ? <TagLabel label="you"/>: ""}*/}
              {/*</span>*/}
            </div>
        break
      case "ownerDisplayName":
        n = <div className="d-flex">
              {
                row.status !== "INVITING" ? (
                  <UserLink user={row.owner} session={session} tenant={row}/>
                ) : (
                  row.owner.displayName
                )
              }
            </div>
        break
      case "regionId":
        n = <span>{row.regionName}</span>
        break
      case "industryId":
        n = <span>{row.industryName}</span>
        break
      case "status":
        n = AccountStatus(row)
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = `/${row.tenantId}`
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={["*/*/RESELLER", "*/*/SYSTEM"]}>
          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("clients")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  (session && (session.user.isSystemUser() || session.user.isResellerUser())) &&
                    <button className="btn btn-primary" onClick={openDialogCurried("dlg_client_invite")}>
                      <i className="fas fa-plus mr-1"></i>
                      {intl.msg("add_new")}
                    </button>
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
          </FormHeader>
          <FormBody>
            <DataTable
              tenant={tenant}
              cols={cols}
              resourceName="clients"
              dataKey="tenantId"
              onFetchDataPage={handleFetchDataPage}
              onRenderDataCol={handleRenderDataCol}
              onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>
        <ClientInviteDialog
          tenant={tenant}
        />
      </>
   )
}