import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useMutation } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useSolv } from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import { AsyncPaginate } from 'react-select-async-paginate'
import { InfoBlock, InfoText } from "./components/ValidationUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkExtraParams} from "./components/TableComps";
import {Alert, FormGroup, useAlert} from "./components/FormComps";
import {M} from "react-select/dist/index-4bd03571.esm";

const log = getLogger("ResellerMoveDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function ResellerMoveDialog(props)  {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [parentResellerCandidate, setParentResellerCandidate] = useState(null)

  const [touched, setTouched] = useState(true)
  const [alert, setAlert] = useAlert(null)

  useEffect(() => {
    log.debug(">>>Z: useEffect: tenant=", props.tenant)
  }, [props]);

  function loadParentResellerCandidates(search, loadedOptions, additional) {
    log.debug(">>>Z: loadParentResellerCandidates:", search, loadedOptions, additional)
    return api.listParentResellerCandidates({tenantId: props.tenant.tenantId, extraParams: mkExtraParams({filterParams: {tenantName: search?.trim()}, sortParams: {tenantName: "ASC"}}), cursor: additional?.cursor})
      .then(({data, nextCursor}) => {
        log.debug(">>>Z: listParentResellerCandidates: data=", data)
        let opt = data.map(c => {
            return {
              value: c.tenantId,
              label: c.tenantName,
              pop: c.pop
            };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug(">>>Z: listParentResellerCandidates: res=", res)
        return res
      });
  }

  function validate() {
    return true
  }
    
  async function handleMoveClick() {

    setAlert(null)

    if (validate()) {

      setBusy(intl.msg("working"))
      try {

        const payload = {
          newParentTenantId: parentResellerCandidate.value,
        }

        const {data} = await api.moveReseller(props.tenant.tenantId, payload)

        window.location.reload()
      }
      catch (err) {
        handleError(err)
      }
      finally {
        setBusy(null)
      }

    }
    else {
      log.debug("Validation error")
      setAlert({error: intl.msg("error_invalid_form")})
      setTouched(true)
    }

  }
  
  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      switch (error.code) {
        case "DUPLICATE_KEY":
        case "DUPLICATE_RESELLER":
          setAlert({error: intl.msg("reseller_move_error_sub_reseller_already_exits")})
          break
        default:
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
          setAlert({error: intl.msg("error_failed")})
    }
  }
      
  function handleParentResellerChange(value) {
    log.debug("handleParentResellerChange: value=", value)
    setParentResellerCandidate(value)
    setTouched(true)
  }
    
  function canEdit() {
    return true
  }

  function handleOpenDialog() {
    setAlert(null)
    setBusy(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_reseller_move")
  }

  return (

    // props.tenant ? (
      <ModalDialog
        dialogId="dlg_reseller_move"
        size="lg"
        keyboard={"true"}
        backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header title={intl.msg("reseller_move_title")} description={intl.msg("reseller_move_description")} alert={alert}/>

        <ModalDialog.Body>

          <Alert className="pb-3" alert={{warning: intl.msg("reseller_move_alert", {tenantName: props?.tenant?.tenantName})}}/>

          <FormGroup>
            <FormGroup.Label text={intl.msg("reseller_move_parent_reseller")} description={intl.msg("reseller_move_parent_reseller_description")} htmlFor="inp_parentReseller"/>
            <FormGroup.Controls>
              <AsyncPaginate
                id="inp_parentReseller"
                className="react-select"
                classNamePrefix="react-select"
                cacheOptions
                isClearable
                value={parentResellerCandidate}
                debounceTimeout={800}
                defaultOptions={true}
                loadOptions={loadParentResellerCandidates}
                onChange={handleParentResellerChange}
                isDisabled={!canEdit()}
              />
              <InfoBlock>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
            {
              parentResellerCandidate &&
                <button type="button" className="btn btn-primary" onClick={handleMoveClick}>{intl.msg("move")}</button>
            }
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>
      </ModalDialog>
    // ) : (
    //   <></>
    // )
  )
}