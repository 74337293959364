import React, {useEffect, useState} from 'react'
import {useSolv} from "./components/SolvProvider";
import {useIntlEx} from "./components/IntlUtils.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {Alert, FormGroup, useAlert} from "./components/FormComps";
import {mkExtraParams} from "./components/TableComps";
import {shortIdEqual} from "./components/StringUtils";
import {mkHref} from "./components/AccountUtils";
import getLogger from "./components/Logging.js"
import './App.css'
import {AsyncPaginate} from "react-select-async-paginate";

const log = getLogger("MemberDeleteDialog")

export default function MemberDeleteDialog(props)  {

  const {api, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)
  const [row, setRow] = useState(null)

  const [newResourceOwner, setNewResourceOwner] = useState(null)

  useEffect(() => {
    // log.debug("useEffect: invoked")
    if (props.tenant) {
      // log.debug("useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
    if (props.row) {
      // log.debug("useEffect: row=", props.row)
      setRow(props.row)
      // setBusy(intl.msg("working"))
      // api.listMembers({tenantId: tenant.tenantId, view: "BASIC", limit: -1})
      //   .then(({data}) => {
      //     log.debug(">>>Z: members=", data)
      //   })
      //   .catch((error) => {
      //     setFatal(error)
      //   })
      //   .finally(() => {
      //     setBusy(null)
      //   })
    }
  }, [props])

  async function loadNewResourceOwner(search, loadedOptions, cursor) {

    const filters = null

    log.debug("loadNewResourceOwner:", search, filters, loadedOptions, cursor)

    return api.listMembers({tenantId: tenant.tenantId, extraParams: mkExtraParams({sortParams: {displayName: "ASC"}}), cursor: cursor})
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.userId,
            label: c.displayName,
          };
        });
        opt = opt.filter(o => !shortIdEqual(o.value, props.row.userId))
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadNewResourceOwner: res=", res)
        return res
      })
      .catch((error) => {
        log.debug("Error loading reseller: ", error)
      })
  }

  async function handleNewResourceOwnerChange(v) {
    setNewResourceOwner(v)
  }

  function handleDeleteClick() {
    setBusy(intl.msg("deleting"))
    let payload = {}
    if (!isInvitation()) {
      payload = {
        newResourceOwner: newResourceOwner?.value
      }
    }
    log.debug("Delete member: payload=", payload)
    api.deleteMember(props.tenant.tenantId, props.row.userId, payload)
        .then(({data}) => {
          log.debug("Delete member: data=", data)
          window.location = mkHref({suffix: `/members`, tenant: props.tenant})
        })
        .catch((error) => {
          log.debug("Error deleting invitation")
          setAlert({error: intl.msg("error_failed")})
        })
        .finally(() => {
          setBusy(null)
        })
  }

  function isInvitation() {
    return ["INVITING", "ONBOARDING"].includes(props.row?.status)
  }

  function handleOpenDialog() {
    log.debug("handleOpenDialog: tenant=, row=", props.tenant, props.row)
    if (!isInvitation() && props.row?.userLevel === 100) {
      setAlert({error: intl.msg("member_delete_error_owner")})
    }
    else {
      setAlert(null)
    }
  }

  function handleCloseDialog() {
    closeDialog("dlg_member_delete")
  }

  return (
    props.row &&
      <>
        <ModalDialog
          id="dlg_member_delete"
          size="md"
          keyboard="true"
          backdrop="static"
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header title={intl.msg(`member_delete${isInvitation() ? "_invitation" : ""}_title`)} description={intl.msg(`member_delete${isInvitation() ? "_invitation" : ""}_description`)} alert={alert}/>

          {
            !alert &&
              <ModalDialog.Body>

                <Alert alert={{warning: intl.msg(`member_delete${isInvitation() ? "_invitation" : ""}_text`, {displayName: isInvitation() ? props.row?.emailAddress : props.row?.displayName})}} align="center"/>

                {
                  !isInvitation() &&
                    <FormGroup className="mt-3">
                      <FormGroup.Label htmlFor="inp_newResourceOwner" text={intl.msg("member_delete_new_resource_owner")} description={intl.msg("member_delete_new_resource_owner_description")}/>
                      <FormGroup.Control>
                        <AsyncPaginate
                          id="inp_newResourceOwner"
                          className="react-select"
                          classNamePrefix="react-select"
                          cacheOptions
                          value={newResourceOwner}
                          debounceTimeout={800}
                          defaultOptions={true}
                          loadOptions={loadNewResourceOwner}
                          onChange={handleNewResourceOwnerChange}
                        />
                      </FormGroup.Control>
                    </FormGroup>
                }

              </ModalDialog.Body>
          }

          <ModalDialog.Footer>
            <ModalDialog.Footer.Controls>
              <button className="btn btn-secondary" type="button"
                      onClick={() => closeDialog("dlg_member_delete")}>{intl.msg("cancel")}</button>
              {
                (!alert && (["INVITING", "ONBOARDING"].includes(props.row.status) || newResourceOwner)) &&
                <button className="btn btn-primary" type="button"
                        onClick={handleDeleteClick}>{intl.msg("delete")}</button>
              }
            </ModalDialog.Footer.Controls>
          </ModalDialog.Footer>

        </ModalDialog>
      </>

  )
}