import React from "react"
import {useIntl, useIntlEx} from "./IntlUtils";
import {shortIdEqual} from "./StringUtils";
import {TagBox, TagLabel} from "./TagBox";
import {SUPERADMIN_USER_LEVEL, ADMIN_USER_LEVEL, BASIC_USER_LEVEL, OWNER_USER_LEVEL, RESTRICTED_USER_LEVEL, SUPERVISOR_USER_LEVEL, useSolv} from "./SolvProvider";
import getLogger from "./Logging.js"
const log = getLogger("Member")

export function useUserStatusOpts() {
  const {intl} = useIntlEx()
  const userStatusOpts = [
    {
      value: "NEW",
      label: intl.msg("status_account_new"),
    },
    {
      value: "ACTIVE",
      label: intl.msg("status_account_active"),
    },
    {
      value: "IDLE",
      label: intl.msg("status_account_inactive"),
    },
    {
      value: "DORMANT",
      label: intl.msg("status_account_dormant"),
    },
    {
      value: "BANNED",
      label: intl.msg("status_account_banned"),
    },
    {
      value: "ONBOARDING",
      label: intl.msg("status_account_onboarding"),
    },
    {
      value: "INVITING",
      label: intl.msg("status_account_inviting"),
    },
  ]
  function findUserStatusOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    if (st.startsWith("DORMANT")) {
      return userStatusOpts.find(o => o.value === "DORMANT")
    }
    else {
      return userStatusOpts.find(o => o.value === st)
    }
  }
  return ({
    userStatusOpts,
    findUserStatusOpt,
  })
}

export function useAccessLevelOpts() {
  const {intl} = useIntlEx()
  const accessLevelOpts = [
    {
      value: RESTRICTED_USER_LEVEL,
      label:
        <>
          <div>
            {intl.msg("access_level_restricted")}
          </div>
          <small>
            {intl.msg("access_level_restricted_description")}
          </small>
        </>,
      labelOnly: intl.msg("access_level_restricted"),
    },
    {
      value: BASIC_USER_LEVEL,
      label:
        <>
          <div>
            {intl.msg("access_level_basic")}
          </div>
          <small>
            {intl.msg("access_level_basic_description")}
          </small>
        </>,
      labelOnly: intl.msg("access_level_basic"),
},
    {
      value: SUPERVISOR_USER_LEVEL,
      label:
        <>
          <div>
            {intl.msg("access_level_supervisor")}
          </div>
          <small>
            {intl.msg("access_level_supervisor_description")}
          </small>
        </>,
      labelOnly: intl.msg("access_level_supervisor"),
    },
    {
      value: ADMIN_USER_LEVEL,
      label:
        <>
          <div>
            {intl.msg("access_level_admin")}
          </div>
          <small>
            {intl.msg("access_level_admin_description")}
          </small>
        </>,
      labelOnly: intl.msg("access_level_admin")
    },
    {
      value: SUPERADMIN_USER_LEVEL,
      label:
        <>
          <div>
            {intl.msg("access_level_superadmin")}
          </div>
          <small>
            {intl.msg("access_level_superadmin_description")}
          </small>
        </>,
      labelOnly: intl.msg("access_level_superadmin")
    },
    {
      value: OWNER_USER_LEVEL,
      label:
        <>
          <div>
            {intl.msg("access_level_owner")}
          </div>
          <small>
            {intl.msg("access_level_owner_description")}
          </small>
        </>,
      labelOnly: intl.msg("access_level_owner")
    }
  ]
  function findAccessLevelOpt(accessLevel) {
    return accessLevelOpts.find(o => o.value === accessLevel)
  }
  function findAvailableAccessLevelOpts(tenant, accessLevel) {
    let opts = []
    if (tenant?.accessingAs("*/MEMBER/*")) {
      switch (accessLevel) {
        case OWNER_USER_LEVEL:
          opts.push(accessLevelOpts.find(i => i.value === RESTRICTED_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === BASIC_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === SUPERVISOR_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === ADMIN_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === SUPERADMIN_USER_LEVEL))
          // opts.push(accessLevelOpts.find(i => i.value === OWNER_USER_LEVEL))
          break
        case SUPERADMIN_USER_LEVEL:
          opts.push(accessLevelOpts.find(i => i.value === RESTRICTED_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === BASIC_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === SUPERVISOR_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === ADMIN_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === SUPERADMIN_USER_LEVEL))
          break
        case ADMIN_USER_LEVEL:
          opts.push(accessLevelOpts.find(i => i.value === RESTRICTED_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === BASIC_USER_LEVEL))
          opts.push(accessLevelOpts.find(i => i.value === SUPERVISOR_USER_LEVEL))
          break
      }
    }
    else if (tenant?.accessingAs("SUPERADMIN/MANAGER/*")) {
      opts.push(accessLevelOpts.find(i => i.value === RESTRICTED_USER_LEVEL))
      opts.push(accessLevelOpts.find(i => i.value === BASIC_USER_LEVEL))
      opts.push(accessLevelOpts.find(i => i.value === SUPERVISOR_USER_LEVEL))
      opts.push(accessLevelOpts.find(i => i.value === ADMIN_USER_LEVEL))
      opts.push(accessLevelOpts.find(i => i.value === SUPERADMIN_USER_LEVEL))
    }
    else if (tenant?.accessingAs("ADMIN/MANAGER/*")) {
      opts.push(accessLevelOpts.find(i => i.value === RESTRICTED_USER_LEVEL))
      opts.push(accessLevelOpts.find(i => i.value === BASIC_USER_LEVEL))
    }
    return opts
  }
  return ({
    accessLevelOpts,
    findAccessLevelOpt,
    findAvailableAccessLevelOpts: findAvailableAccessLevelOpts
  })
}

export function useNotificationLevelOpts() {
  const {intl} = useIntlEx()
  const notificationLevelOpts = [
    {
      value: 0,
      label: <><div>{intl.msg("member_notification_level_0")}</div><small>{intl.msg("member_notification_level_0_tooltip")}</small></>,
    },
    {
      value: 10,
      label: <><div>{intl.msg("member_notification_level_10")}</div><small>{intl.msg("member_notification_level_10_tooltip")}</small></>,
    },
    {
      value: 20,
      label: <><div>{intl.msg("member_notification_level_20")}</div><small>{intl.msg("member_notification_level_20_tooltip")}</small></>,
    },
    {
      value: 30,
      label: <><div>{intl.msg("member_notification_level_30")}</div><small>{intl.msg("member_notification_level_30_tooltip")}</small></>,
    },
    {
      value: 99,
      label: <><div>{intl.msg("member_notification_level_99")}</div><small>{intl.msg("member_notification_level_99_tooltip")}</small></>,
    },
  ]
  function findNotificationLevelOpt(notificationLevel) {
    return notificationLevelOpts.find(o => o.value === Math.min(Math.max(notificationLevel, 0), 99))
  }
  function findAvailableNotificationLevelOpts(tenant, accessLevel) {
    let opts = []
    // // if (tenant?.accessingAs("SUPERVISOR/MEMBER/*") || tenant?.accessingAs("ADMIN/MANAGER/*")) {
    //   switch (accessLevel) {
    //     case OWNER_USER_LEVEL:
    //     case SUPERADMIN_USER_LEVEL:
    //     case ADMIN_USER_LEVEL:
    //     case SUPERVISOR_USER_LEVEL:
    //       opts.push(notificationLevelOpts.find(i => i.value === 0))
    //       opts.push(notificationLevelOpts.find(i => i.value === 2))
    //       opts.push(notificationLevelOpts.find(i => i.value === 4))
    //       break
    //     default:
    //       if (accessLevel > RESTRICTED_USER_LEVEL) {
    //         opts.push(notificationLevelOpts.find(i => i.value === 0))
    //         opts.push(notificationLevelOpts.find(i => i.value === 2))
    //       }
    //   }
    // // }
    return notificationLevelOpts
  }
  return ({
    notificationOpts: notificationLevelOpts,
    findNotificationLevelOpt: findNotificationLevelOpt,
    findAvailableNotificationLevelOpts: findAvailableNotificationLevelOpts
  })
}

export function useNotificationOpts() {
  const {brandId, api} = useSolv()
  const {intl} = useIntlEx()
  const notificationOpts = [
    {
      value: "ALL",
      label: "All"
    },
    {
      label: "Categories",
      options: [
        {
          value: "CRITICAL",
          label: "Critical"
        },
        {
          value: "IMPORTANT",
          label: "Important"
        },
        {
          value: "NOTICE",
          label: "Notice"
        },
        {
          value: "INFO",
          label: "Info"
        },
      ]
    },
    {
      label: "Critical Events",
      options: [
        {
          value: "AccountArchived",
          label: "Account Archived",
          category: "CRITICAL",
        },
        {
          value: "BroadcastArchived",
          label: "Broadcast Archived",
          category: "CRITICAL",
        },
        {
          value: "BroadcastRejected",
          label: "Broadcast Rejected",
          category: "CRITICAL",
        },
        {
          value: "ResponsePosted",
          label: "Response Posted",
          category: "CRITICAL",
        },
      ]
    },
    {
      label: "Important Events",
      options: [
        {
          value: "AccountModified",
          label: "Account Modified",
          category: "IMPORTANT",
        },
        {
          value: "BroadcastArchived",
          label: "Broadcast Archived",
          category: "IMPORTANT",
        },
        {
          value: "BroadcastPublished",
          label: "Broadcast Published",
          category: "IMPORTANT",
        },
        {
          value: "BroadcastSubmitted",
          label: "Broadcast Submitted",
          category: "IMPORTANT",
        },
      ]
    },
  ]
  function findNotificationOpt(eventName) {
    if (eventName === "ALL") {
      const o = notificationOpts.find(i => i.value === "ALL")
      log.debug("findNotificationOpt: ALL: o=", o)
      return o
    }
    else {
      for (const opt of notificationOpts) {
        log.debug("findNotificationOpt:", opt)
        const r = opt.options?.find(i => i.value === eventName)
        if (r) {
          return r
        }
      }
      return null
    }
  }

  function findAvailableNotificationOpts(tenant, accessLevel) {
    return notificationOpts
  }
  return ({
    status: "UNKNOWN",
    msg: ``
  })
}

export function UserStatus(props) {
  const {intl} = useIntlEx()
  const {findUserStatusOpt} = useUserStatusOpts()
  const userId = props.userId || props.row?.userId
  const statusOpt = findUserStatusOpt(props.status || props.row?.status)
  const userLevel = props.userLevel || props.row?.userLevel
  const size = props.size || "sm"
  return (
    <>
    {
      statusOpt &&
        <TagBox>
          {
            shortIdEqual(props.session?.user?.userId,userId) &&
              <TagLabel label={intl.msg("you")} className={`user-type-color-you tag-label-${size}`} tooltip={""}/>
          }
          <TagLabel label={statusOpt.value} className={`user-status-color-${statusOpt.value.toLowerCase()} tag-label-${size}`} tooltip={""}/>
          {/*{*/}
          {/*  userLevel === 100 &&*/}
          {/*    <TagLabel label={intl.msg("owner")} className={`user-type-color-owner tag-label-${size}`} tooltip={""}/>*/}
          {/*}*/}
        </TagBox>
    }
    </>
  )
}

export function AccessLevel(props) {
  const {findAccessLevelOpt} = useAccessLevelOpts()
  const accessLevelOpt = findAccessLevelOpt(props.userLevel || props.row?.userLevel)
  return (
    accessLevelOpt &&
      <span>
        {accessLevelOpt.labelOnly || accessLevelOpt.label}
      </span>
  )
}

export function UserLink(props) {
  const {intl} = useIntlEx()
  if (props && props.user && props.tenant) {
    const session = props.session
    const tenant = props.tenant
    const user = props.user
    const userId = user.userId.replace(/\-/g, "")
    const tenantId = user.tenantId.replace(/\-/g, "")
    const url = `/${tenantId}/members/${userId}`
    return (
      <div className="d-inline-block">
        <div className="d-flex flex-row align-items-center" style={{gap: "4px"}}>
          <a className="text-link" href={url} title={user.emailAddress}>
            {user.displayName || user.emailAddress}
          </a>
          <span>
            {
              session && shortIdEqual(userId, session.user.userId) &&
                <TagLabel label={intl.msg("label_you")}/>
            }
          </span>
          {
            shortIdEqual(user.tenantId, tenant.tenantId) ? (
              <></>
            ) : (
              user.userTypeId === "SYSTEM" ? (
                <TagLabel label={intl.msg(`label_${user.brandId?.toLowerCase() || "system"}`)}/>
              ) : user.userTypeId === "RESELLER" ? (
                <TagLabel label={intl.msg("label_reseller")}/>
              ) : (
                <></>
              )
            )
          }
        </div>
      </div>
    )
  }
  else {
    return (<></>)
  }
}

export function UserAndTimeLink(props) {
  const {intl} = useIntlEx()
  if (props && props.user && props.tenant) {
    const session = props.session
    const tenant = props.tenant
    const user = props.user
    let timestamp = props.timestamp || props.time || props.dateTime
    if (typeof(timestamp) === "string") {
      timestamp = Date.parse(timestamp)
    }
    return (
      <div className="d-flex flex-row flex-wrap">
        {
          timestamp &&
            <>{`${intl.dateTime(timestamp)} `}</>
        }
        <span className="text-muted">
          &nbsp;
          {
            props.by ? (
              props.by
            ) : (
              intl.msg("by")
            )
          }
          :&nbsp;
        </span>
        <UserLink session={session} tenant={tenant} user={user}/>
      </div>
    )
  }
  else {
    return (<></>)
  }
}



