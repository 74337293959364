import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup} from "../components/FormComps.js"
import {validateField, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import MainMenu from "../MainMenu"
import {AsyncPaginate} from "react-select-async-paginate";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("Region")

export default function Region(props) {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(undefined)

  const [alert, setAlert] = useAlert()

  const [regionId, setRegionId] = useState(null)
  const [regionName, setRegionName] = useState(null)
  const [isAdding, setAdding] = useState(null)

  const [touched, setTouched] = useState(false)

  const [countries, setCountries] = useState(null)
  const [currencyCode, setCurrencyCode] = useState(null)
  const [status, setStatus] = useState(null)

  const currencyCodes = ["AED","AFN","ALL","AMD","ANG","AOA","ARS","AUD","AWG","AZN","BAM","BBD","BDT","BGN","BHD","BIF","BMD","BND","BOB","BRL","BSD","BTC","BTN","BWP","BYN","BYR","BZD","CAD","CDF","CHF","CLF","CLP","CNY","COP","CRC","CUC","CUP","CVE","CZK","DJF","DKK","DOP","DZD","EGP","ERN","ETB","EUR","FJD","FKP","GBP","GEL","GGP","GHS","GIP","GMD","GNF","GTQ","GYD","HKD","HNL","HRK","HTG","HUF","IDR","ILS","IMP","INR","IQD","IRR","ISK","JEP","JMD","JOD","JPY","KES","KGS","KHR","KMF","KPW","KRW","KWD","KYD","KZT","LAK","LBP","LKR","LRD","LSL","LTL","LVL","LYD","MAD","MDL","MGA","MKD","MMK","MNT","MOP","MRO","MUR","MVR","MWK","MXN","MYR","MZN","NAD","NGN","NIO","NOK","NPR","NZD","OMR","PAB","PEN","PGK","PHP","PKR","PLN","PYG","QAR","RON","RSD","RUB","RWF","SAR","SBD","SCR","SDG","SEK","SGD","SHP","SLE","SLL","SOS","SRD","STD","SVC","SYP","SZL","THB","TJS","TMT","TND","TOP","TRY","TTD","TWD","TZS","UAH","UGX","UYU","UZS","VEF","VES","VND","VUV","WST","XAF","XAG","XAU","XCD","XDR","XOF","XPF","YER","ZAR","ZMK","ZMW","ZWL","USD"]
  const currencyCodeOpts = currencyCodes.map(c => {
    return (
      {value: c, label: c}
    )
  })

  function getAllCurrencyCodeOpts() {
    return currencyCodeOpts
  }

  function getCurrencyCodeOpt(currencyCode) {
    return currencyCodeOpts.find(c => c.value === currencyCode)
  }

  useEffect(() => {
    log.debug(">>>Z: useEffect: invoked", params.regionId)
    if (params.regionId) {
      if (params.regionId.trim().toLowerCase() === "_new") {
        setRegionId(null)
        setRegionName(null)
        setAdding(true)
      }
      else {
        setRegionId(params.regionId)
        setAdding(false)
        setBusy(intl.msg("loading"))
        api.getRegion(params.regionId)
          .then(({data}) => {
            log.debug(">>>Z: getRegion: data=", data)
            setRegionId(data.regionId)
            setRegionName(data.regionName)
            setCountries(data.countryCodes.map((c) => ({value: c, label: c})))
            setCurrencyCode(getCurrencyCodeOpt(data.currencyCode))
            setStatus(data.status)
          })
          .catch((error) => {
            setFatal(error)
          })
          .finally(() => {
            setBusy(null)
          })
      }
    }
  }, [])

  function loadCountries(search, loadedOptions, additional) {
    return api.listCountries({view: "BASIC", extraParams: "sort=countryCode:ASC", cursor: additional?.cursor, limit: 20})
      .then(({data, nextCursor}) => {
        log.debug("loadCountries: data=", data)
        let opt = data.map(c => {
          return {
            value: c.countryCode,
            label: c.countryCode,
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadRegions: res=", res)
        return res
      });
  }

  function handleRegionNameChange(e) {
    const v = e.target?.value
    setRegionName(v)
    setTouched(true)
  }

  function handleCountriesChange(v) {
    setCountries(v)
    setTouched(true)
  }

  function handleCurrencyCodeChange(o) {
    setCurrencyCode(o)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_regionId", regionName && regionName.trim().length > 0 , "required", opt)
    validateField("inp_countryCodes", countries && countries.length > 0, "required", opt)
    validateField("inp_currencyCode", currencyCode && currencyCode.value, "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        const payload = {
          regionId: regionId,
          regionName: regionName,
          countryCodes: countries.map((c) => c.value),
          currencyCode: currencyCode.value,
          status: status
        }

        if (isAdding) {
          const {data} = await api.insertRegion(payload)
        }
        else {
          const {data} = await api.updateRegion(regionId, payload)
        }

        window.location = `/sys/regions`
      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("region_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (

    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

          <FormHeader>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("region_title")} {" - " + (isAdding ? "(new)" : regionName)}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  touched &&
                    <>
                      <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                        <i className="fas fa-check"></i>{intl.msg("save")}
                      </button>
                      <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                        <i className="fas fa-times"></i>{intl.msg("cancel")}
                      </button>
                    </>
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_regionName" text={intl.msg("region_name")} description={intl.msg("region_name_description")}/>
              <FormGroup.Control>
                <div style={{maxWidth: "600px"}}>
                  <input id="inp_regionName" type="text" className="form-control" value={regionName} maxLength={200} disabled={!isEditable()} onChange={handleRegionNameChange}/>
                </div>
                <InfoBlock disabled={!isEditable()}>
                  <InfoText validate="unique">{intl.msg("region_name_helptext_unique")}</InfoText>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_countryCodes" text={intl.msg("region_country_codes")} description={intl.msg("region_country_codes_description")}/>
              <FormGroup.Control>
                <AsyncPaginate
                  id="inp_countryCodes"
                  className="react-select"
                  classNamePrefix="react-select"
                  cacheOptions
                  isMulti={true}
                  value={countries}
                  debounceTimeout={800}
                  defaultOptions={true}
                  loadOptions={loadCountries}
                  onChange={handleCountriesChange}
                  isDisabled={false}/>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

            <FormGroup>
              <FormGroup.Label htmlFor="inp_currencyCode" text={intl.msg("region_currency_code")} description={intl.msg("region_currency_code_description")}/>
              <FormGroup.Control>
                <div style={{width: "200px"}}>
                  <Select
                    id="inp_currencyCode"
                    className="react-select"
                    classNamePrefix="react-select"
                    value={currencyCode}
                    options={getAllCurrencyCodeOpts(intl)}
                    onChange={handleCurrencyCodeChange}
                    isDisabled={!isEditable()}/>
                </div>
                <InfoBlock>
                  <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
                </InfoBlock>
              </FormGroup.Control>
            </FormGroup>

          </FormBody>
        </MainContainer>

      </>

  )
}
