import React, {useEffect} from "react"
import {useLocation} from "react-router-dom";
import getLogger from "../components/Logging.js"
import "../App.css"

const log = getLogger("MicrosoftAuthCallback")

export function MicrosoftAuthCallback(props) {

  const location = useLocation()

  useEffect(() => {

  }, [])

  return (
    <>
    </>
  )
}