export const LOCALES = {
  bg: {
    engName: "Bulgarian",
    localName: "български",
    langCode: "bg",
  },
  cs: {
    engName: "Czech",
    localName: "český,",
    langCode: "cs",
  },
  da: {
    engName: "Danish",
    localName: "Dansk",
    langCode: "da",
  },
  de: {
    engName: "German",
    localName: "Deutsche",
    langCode: "de",
  },
  el: {
    engName: "Greek",
    localName: "ελληνική",
    langCode: "el",
  },
  en: {
    engName: "English",
    localName: "English",
    langCode: "en",
  },
  es: {
    engName: "Spanish",
    localName: "Español",
    langCode: "es",
  },
  fi: {
    engName: "Finish",
    localName: "Suomen",
    langCode: "fi",
  },
  fr: {
    engName: "French",
    localName: "Français",
    langCode: "fr",
  },
  hi: {
    engName: "Hindi",
    localName: "हिंदी",
    langCode: "hi",
  },
  hu: {
    engName: "Hungarian",
    localName: "Magyar",
    langCode: "hu",
  },
  it: {
    engName: "Italian",
    localName: "Italiana",
    langCode: "it",
  },
  ja: {
    engName: "Japanese",
    localName: "日本語",
    langCode: "ja",
  },
  ko: {
    engName: "Korean",
    localName: "한국어",
    langCode: "ko",
  },
  km: {
    engName: "Khmer",
    localName: "ខ្មែរ",
    langCode: "km"
  },
  lt: {
    engName: "Lithuanian",
    localName: "Lietuvių",
    langCode: "lt",
  },
  ms: {
    engName: "Malay",
    localName: "Bahasa Malaysia",
    langCode: "ms",
  },
  nl: {
    engName: "Dutch",
    localName: "Nederlandse",
    langCode: "nl",
  },
  no: {
    engName: "Norwegian",
    localName: "Norsk",
    langCode: "no",
  },
  pl: {
    engName: "Polish",
    localName: "Polski",
    langCode: "pl",
  },
  pt: {
    engName: "Portuguese",
    localName: "Português",
    langCode: "pt",
  },
  ro: {
    engName: "Romanian",
    localName: "Română",
    langCode: "ro",
  },
  sk: {
    engName: "Slovak",
    localName: "Slovenčina",
    langCode: "sk",
  },
  sv: {
    engName: "Swedish",
    localName: "Svenska",
    langCode: "sv",
  },
  th: {
    engName: "Thai",
    localName: "ภาษาไทย",
    langCode: "th",
  },
  tl: {
    engName: "Tagalog",
    localName: "Tagalog",
    langCode: "tl",
  },
  tr: {
    engName: "Turkish",
    localName: "Türkçe",
    langCode: "tr",
  },
  uk: {
    engName: "Ukrainian",
    localName: "Yкраїнська",
    langCode: "uk",
  },
  vi: {
    engName: "Vietnamese",
    localName: "Việt Nam",
    langCode: "vi",
  },
  id: {
    engName: "Indonesian",
    localName: "Bahasa Indonesia",
    langCode: "id",
  },
  ur: {
    engName: "Urdu",
    localName: "رد",
    langCode: "ur",
  },
  ga: {
    engName: "Irish",
    localName: "Gaeilge",
    langCode: "ga",
  },
  "zh-HANS": {
    engName: "Chinese (Simplified)",
    localName: "中文(簡體)",
    langCode: "zh-HANS",
  },
  "zh-HANT": {
    engName: "Chinese (Traditional)",
    localName: "中文(繁體)",
    langCode: "zh-HANT",
  },
  "or": {
    engName: "Odia",
    localName: "ଓଡ଼ିଆ",
    langCode: "or",
  },
}