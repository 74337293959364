import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useSolv, useTenant} from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils"
import PagePreview from "./PagePreview.js"
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("BroadcastPagePreview")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function BroadcastPagePreview(props) {

  const {api, session, setBusy, setFatal} = useSolv()
  const { intl } = useIntlEx()

  const params = useParams()

  const [broadcastId, setBroadcastId] = useState(params.broadcastId ? params.broadcastId : null)
  const {tenant} = useTenant(params.tenantId)
  
  const [error, setError] = useState(null)

  const [broadcastName, setBroadcastName] = useState(null)
  const [creativeType, setCreativeType] = useState("SOLV")
  const [creativeHeader, setCreativeHeader] = useState(null)
  const [creativeTitle, setCreativeTitle] = useState(null)
  const [creativeContent, setCreativeContent] = useState(null)
  const [creativeFooter, setCreativeFooter] = useState(null)
  const [creativeStyle, setCreativeStyle] = useState({})
  const [pageType, setPageType] = useState("SOLV")
  const [pageUrl, setPageUrl] = useState(null)
  const [pageHeader, setPageHeader] = useState(null)
  const [pageTitle, setPageTitle] = useState(null)
  const [pageContent, setPageContent] = useState(null)
  const [pageForm, setPageForm] = useState(null)
  const [pageFormFields, setPageFormFields] = useState(null)
  const [pageStyle, setPageStyle] = useState({})
  const [creativeImageUrl, setCreativeImageUrl] = useState(null)
  const [pageImageUrl, setPageImageUrl] = useState(null)
  const [broadcastStatus, setBroadcastStatus] = useState("DRAFT")
  const [pacingMode, setPacingMode] = useState({value: "PaceAhead", label: "PaceAhead"})
  const [feedbackEnabled, setFeedbackEnabled] = useState(true)
  const [feedbackForm, setFeedbackForm] = useState(null)
  const [feedbackFormFields, setFeedbackFormFields] = useState(null)

  useEffect(() => {
    if (tenant) {
      setBusy("Loading...")
      setCreativeHeader(tenant.creativeHeader)
      setCreativeContent(tenant.creativeContent)
      setCreativeFooter(tenant.creativeFooter)
      setPageHeader(tenant.pageHeader)
      setPageContent(tenant.pageContent)
      log.debug("Loading broadcast...")
      loadBroadcast(tenant.tenantId, broadcastId)
        .catch((err) => {
          setFatal(err)
        })
        .finally(() => {
          setBusy(null)
        })
    }
  }, [tenant])

  function loadBroadcast(tenantId, broadcastId) {
      return api.getBroadcast(tenantId, broadcastId)
        .then(({data}) => {
          log.debug("Broadcast Loaded: data=", data)
          setBroadcastName(data.broadcastName)
          setPageType(data.pageType)
          setPageHeader(data.pageHeader)
          setPageTitle(data.pageTitle)
          setPageContent(data.pageContent)
          if (data.pageForm) {
            setPageForm(data.pageForm)
            if (data.pageForm.fields) {
              setPageFormFields(data.pageForm.fields)
            }
          }
          if (data.pageUrl && data.pageUrl.trim().length > 0) {
            setPageUrl(data.pageUrl)
          }
          else {
            setPageUrl("")
          }
          let style
          if (data.pageStyle) {
            style = data.pageStyle
          }
          else {
            style = {border: {color: "#FF0000"}}
          }
          setPageStyle(data.pageStyle)
          setCreativeImageUrl(data.creativeImageUrl)
          if (data.pageImageUrl && data.pageImageUrl.trim().length > 0) {
            setPageImageUrl(data.pageImageUrl)
          }
          setBroadcastStatus(data.status)
          setPacingMode({value: data.pacingMode, label: data.pacingMode})
          setFeedbackEnabled(data.feedbackEnabled)
          if (data.feedbackForm) {
            setFeedbackForm(data.feedbackForm)
            if (data.feedbackForm.fields) {
              setFeedbackFormFields(data.feedbackForm.fields)
            }
          }
          return data
        })
        .catch((error) => {
          log.debug("Load Broadcast Failed: error=", error)  
          setError(404)
        })
  }

  function Error(props) {
    return  (
        error && <>
          <div class="jumbotron m-3">
            <center>
              <h1 style={{fontSize: "4.5rem"}}><i className="fas fa-ban"></i></h1>
              <h1 style={{fontSize: "3rem", color: "#fff", marginBottom: "30px"}}>Preview Unavailable</h1>
              <p className="lead" style={{color: "#ccc"}}>
                The broadcast you're looking for does not exist or you do not have sufficient permissions to access it
                {/* <div className="mt-2"></div>
                <a className="btn btn-primary" style={{fontSize: "1.171875rem;"}} href="javascript:window.history.back()">Go Back</a> <a href="/" className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} >Home</a> */}
              </p>
            </center>
          </div>                          
        </>
    )
  }
    
  return (
    tenant &&
    <>
      {
        error ? (
          <Error/>
        ) : (
          <div style={{backgroundColor: "white"}}>
            <PagePreview
                pageType={pageType}
                pageUrl={pageUrl}
                broadcastStatus={broadcastStatus}
                logoUrl={tenant.logoUrl}
                tenantName={tenant.tenantName}
                tenantDisplayName={tenant.displayName}
                homePage={tenant.homePage}
                contactInfo={tenant.contactInfo}
                pageTitle={pageTitle}
                pageHeader={pageHeader}
                pageContent={pageContent}
                pageFormFields={pageFormFields}
                pageStyle={pageStyle}
                pageImageUrl={pageImageUrl}
                feedbackEnabled={feedbackEnabled}
                feedbackFormFields={feedbackFormFields}
                allowedDomains={tenant.allowedDomains}
                disallowedDomains={tenant.disallowedDomains}
              />
            </div>
          )
        }
      </>
    )
}
