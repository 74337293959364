import React, { useState }  from 'react'
import { useHistory, useLocation }  from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import ReCAPTCHA from "react-google-recaptcha"
import { useSolv } from "./components/SolvProvider"
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Profile")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function Contact(props) {

  const { api, auth: { session: { user, tenant } } } = useSolv()

  const location = useLocation()
  const history = useHistory()

  const [working, setWorking] = useState(null)
  const [touched, setTouched] = useState(false)
  const [error, setError] = useState(null)

  const [fullName, setFullName] = useState()
  const [emailAddress, setEmailAddress] = useState(user.emailAddress)

  const [redirect, setRedirect] = useState(null)
  
  function findFormGroupEl(el) {
    if (el) {
      const formGroupEl = el.closest(".form-group")
      if (formGroupEl) {
        return formGroupEl
      }    
    }
    return null
  }

  function findErrorEl(el, className) {
    const parentEl = el.parentElement
    if (parentEl) {
      const childNodes = parentEl.childNodes
      for (let i = 0; i < childNodes.length; i++) {
        const childEl = childNodes[i]
        if (childEl.className === className) {
          return childEl
        }        
      }
    }
    return null
  }

  function showErrorMsg(id, errorMsg) {
    let r = true
    const el = document.getElementById(id)
    const formGroupEl = findFormGroupEl(el)
    if (formGroupEl) {
      if (errorMsg) {
        formGroupEl.classList.add("invalid")
        const errorEl = findErrorEl(el, "error")
        if (errorEl) {
          errorEl.innerText = errorMsg
        }
        r = false
      }
      else {
        formGroupEl.classList.remove("invalid")
      }
    }
    return r
  }

  function clearErrorMsg(id) {
    const el = document.getElementById(id)
    if (el) {
      const formGroupEl = el.closest(".form-group")
      if (formGroupEl) {
          formGroupEl.classList.remove("invalid")
      }    
    }    
  }

  function checkError(id, errorMsg, valid) {
    const r = showErrorMsg(id, errorMsg)
    return !valid ? valid  : r
  }

  function validateField(id, cond, errorMsg, opt) {
    if (!cond) {
      opt.valid = false
      opt.focusEl = opt.focusEl || id
      showErrorMsg(id, errorMsg)
    }
    else {
      clearErrorMsg(id)
    }
  }

  function validate() {
    
    let opt = {focusEl: null, valid: true}

    // validateField("inp_tenantName", tenantName && tenantName.trim().length > 0, "Please specify a non-empty value", opt)
    // validateField("inp_smallLogo", smallLogoData, "Please upload a logo", opt)
    // validateField("inp_largeLogo", largeLogoData, "Please upload a logo", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  const Rd = (props) => {
    window.location = props.to
  }

  function handleSaveClick() {
    
  }

  function handleReloadClick()  {
    setRedirect(`/profile`)
  }

  function handleCancelClick() {
    window.location = "/"
  }

  function handleFullNameChange(e) {
    setFullName(e.target.value)
  }

  function handleRecaptchaChange(e) {
    log.debug("handleRecaptchaChange: e=", e)
  }

  function isEditable() {
    return true
  }

  return (
    redirect ? 
      <Rd to={redirect}/>
    : (
    <>
      
      
      <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
        <p>{working}</p>
      </div>

      <main style={{paddingTop: "40px"}}>

        <div style={{margin: "auto", maxWidth: "800px", backgroundColor: "#000", borderRadius: "8px", border: "1px solid #444"}}>

          <center style={{padding: "20px 0 20px 0"}}>
            <img src="/images/solv-name-logo-200.png" style={{width: "100px", height: "auto"}}/>
          </center>
          
          <div className="container-fluid" style={{backgroundColor: "rgb(34	34	34)"}}>

            <div className="row no-gutters">

                <div className="col">

                  <center>
                    <h1 style={{fontSize: "2rem", color: "#fff", margin: "20px 0 10px 0"}}>Contact Us</h1>
                    <p className="lead" style={{color: "#999"}}>
                      We love to hear from you!. Send us a question or feedback in this form and we’ll get in touch with you as soon as possible.
                    </p>
                  </center>
    
                  <form id="frmMain" autocomplete="off">

                    <div className="form-card mt-0 pt-0" style={{border: "0"}}>
        
                      <div className="row">
          
                        <div className="col">

                          <div className="form-group row">
                            <label className="col-2 col-form-label" htmlFor="inp_tenantName">Full Name<sup>*</sup></label>
                            <div className="col-10">
                              <input id="inp_tenantName" type="text" className="form-control" value={fullName} disabled={!isEditable()} onChange={handleFullNameChange} autocomplete="no"/>
                              <small>
                                <ul className="helptext">
                                  <li className="d">Please enter your full name.</li>
                                  <li className="v v-non-empty">A non-empty value is required.</li>
                                </ul>
                              </small>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-2 col-form-label" htmlFor="inp_tenantName">Email&nbsp;Address<sup>*</sup></label>
                            <div className="col-10">
                              <input id="inp_tenantName" type="text" className="form-control" value={fullName} disabled={!isEditable()} onChange={handleFullNameChange} autocomplete="no"/>
                              <small>
                                <ul className="helptext">
                                  <li className="d">Please enter an email address we can use to contact you. If you have a SOLV account, you should ideally use the email address associated with your account.</li>
                                  <li className="v v-non-empty">A non-empty and valid email address is required.</li>
                                </ul>
                              </small>
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-2 col-form-label" htmlFor="inp_tenantName">Message<sup>*</sup></label>
                            <div className="col-10">
                              <textarea id="inp_tenantName" type="text" rows="6" className="form-control" disabled={!isEditable()} onChange={handleFullNameChange} autocomplete="no">{fullName}</textarea>
                              <small>
                                <ul className="helptext">
                                  <li className="d">Please enter the details of your inquiry or request.</li>
                                  <li className="v v-non-empty">A non-empty value is required for this field.</li>
                                </ul>
                              </small>
                            </div>
                          </div>

                          <div className="form-group row">

                            <div className="col-2"></div>

                            <div id="fg_captcha" className="col-10">
                              <ReCAPTCHA
                                sitekey="6Lfd-IwdAAAAAAjW7Z0g2TpRzTXuuFbmrpSWB87H"
                                onChange={handleRecaptchaChange}
                                theme="dark"
                              />
                              <small>Please check the CAPTCHA</small>
                            </div>

                          </div>

                          <div className="form-group row mt-2 mb-0">
                            <div className="col-2"></div>
                            <div className="col-10" style={{display: "flex", justifyContent: "flex-start"}}>
                              <button className="btn btn-primary mt-4" style={{fontSize: "20px"}}>Send</button>
                            </div>
                          </div>

                        </div>
                      </div>            
                    </div>
                  </form>
                </div>
              </div>                
            </div>
          </div>
      </main>

    </>
    ))
}