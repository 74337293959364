import React, { useState, useEffect }  from 'react'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { AlertBox } from "./AlertBox.js"
import getLogger from "./Logging.js"
import '../App.css'

const log = getLogger("ImageDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

const intFormat = new Intl.NumberFormat('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0})

export default function ImageDialog(props)  {

  const [touched, setTouched] = useState(false)
  const [working, setWorking] = useState(null)
  const [info, setInfo] = useState(null)
  const [error, setError] = useState(null)

  const [imageData, setImageData] = useState(null)
  const [imageInfo, setImageInfo] = useState(null)

  useEffect(() => {
    log.debug("useEffect called: props=", props)
    setImageInfo(props.imageInfo)
  },[props])
  
  async function handleClick(e) {

    setError(null)
    setInfo(null)

    // let el = document.getElementById(`inp_${props.id}`)

    // log.debug("handleClick: el=", el)

    // const info = JSON.parse(el.dataset.info)

    // setImageInfo(info)

    log.debug("handleClick: info=", imageInfo, imageInfo.fps <= 24)

    if (imageInfo.error) {
      const error = imageInfo.error
      if (error == "ANIMATED_GIF_ENDLESS_LOOP") {
        setError("Invalid Image - Animation loops indefinitely")
      }
      else if (error == "ANIMATED_GIF_FPS") {
        setError("Invalid Image - Animation exceeds 24 frames per second (fps)")
      }
      else if (error == "ANIMATED_GIF_DURATION") {
        setError("Invalid Image - Animation exceeds maximum duration")
      }
      else if (error == "ANIMATED_GIF_SIZE") {
        setError("Invalid Image - File is too large")
      }
      else if (error == "COMPRESSION_FAILED") {
        setError("Invalid Image - File is too large")
      }
      
    }
    else if (imageInfo.warning) {
      const warning = imageInfo.warning
      if (warning == "COMPRESSED") {
        setInfo("We had to compress your image to make it fit requirements. Please make sure the result is acceptable to you.")
      }
      else if (warning == "SCALED") {
        setInfo("We had to scale your image to make it fit requirements. Please make sure the result is acceptable to you.")
      }
      else if (warning == "COMPRESSED_SCALED") {
        setInfo("We had to compress and scale your image to make it fit requirements. Please make sure the result is acceptable to you.")
      }
    }

    setImageData(imageInfo.dataUrl)

  }
  
  function handleApplyClick(e) {
    props.setTargetImageData(imageData)
    props.setTouched(true)
    return true
  }

  function isEditable() {
    return true
  }

  return ( 

    <>
      <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
        <p>{working}</p>
      </div>

      <a style={{display: "none"}} id={`inp_${props.id}`} href="src/components/ImageDialog#" data-toggle="modal" data-target={`#${props.id}`} onClick={handleClick}/>
    
      <div className="modal fade" id={`${props.id}`} tabIndex="-1" role="dialog" aria-labelledby={props.id} aria-hidden="true">
        <div className="modal-dialog modal-lg modal-dialog-scrollable" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{props.title}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <section className="main">

                    <div className="container-fluid">

                      <div className="row">

                        <div className="col">

                          <center>

                            {
                              error ? (
                                <div style={{width: "fit-content"}}>
                                  <AlertBox type="danger">{error}</AlertBox>
                                </div>
                              ) : info ? (
                                <div style={{width: "fit-content"}}>
                                  <AlertBox type="attention">{info}</AlertBox>
                                </div>
                              ) : (
                                <></>
                              ) 
                            }                    

                            {
                              imageInfo &&
                                <div className="row">
                                  <div className="col-sm-6">
                                    <div className="image-backdrop mt-3" style={{width: `${imageInfo.width + 2}px`, height: `${imageInfo.height + 2}px`}}>
                                      <img style={{width: `${imageInfo.width}px`, height: `${imageInfo.height}px`, objectFit: "contain"}} src={imageData}/>
                                    </div>
                                  </div>
                                  <div className="col-sm-6">
                                    <table cellPadding="4" className="mt-3">

                                      <tr>
                                        <td className="text-muted">
                                          Image Property
                                        </td>
                                        <td className="text-muted talign-center" width="120">
                                          Your File
                                        </td>
                                        <td className="text-muted">
                                          Requirement
                                        </td>
                                      </tr>

                                      <tr>
                                        <td colSpan="3" style={{border: "0", borderBottom: "1px solid var(--form-divider-line-color)"}}>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="text-muted">
                                          File size (KB)
                                        </td>
                                        <td className="talign-right pr-4">
                                          <span>
                                            {
                                              imageInfo.compressedFileSize ? intFormat.format(imageInfo.compressedFileSize / 1000) : intFormat.format(imageInfo.fileSize / 1000)
                                            }
                                          </span>
                                          <span key="key_fileSizeIcon">
                                            {
                                              imageInfo.fileSize <= imageInfo.maxFileSize ? <span className="text-success ml-1">&#10003;</span> :
                                                imageInfo.compressedFileSize <= imageInfo.maxFileSize ? <span className="text-warning ml-1">&#10003;(compressed)</span> :
                                                  <span className="text-danger ml-1">&#10060;</span>
                                            }
                                          </span>
                                        </td>
                                        <td>
                                            <span key="key_maxFileSizeIcon"><i className="fas fa-less-than-equal mr-1"></i>{intFormat.format(imageInfo.maxFileSize / 1000)}</span>
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="text-muted">
                                          Width (px)
                                        </td>
                                        <td className="talign-right pr-4">
                                          {
                                            intFormat.format(imageInfo.scaledWidth)
                                          }
                                          {
                                            imageInfo.naturalWidth == imageInfo.width ? <span className="text-success ml-1">&#10003;</span> :
                                              <span className="text-warning ml-1">&#10003;(scaled)</span>
                                          }
                                        </td>
                                        <td>
                                          <i className="fas fa-less-than-equal mr-1"></i>
                                          {
                                            intFormat.format(imageInfo.width)
                                          }
                                        </td>
                                      </tr>

                                      <tr>
                                        <td className="text-muted">
                                          Height (px)
                                        </td>
                                        <td className="talign-right pr-4">
                                          {
                                            intFormat.format(imageInfo.scaledHeight)
                                          }
                                          {
                                            imageInfo.naturalHeight == imageInfo.height ? <span className="text-success ml-1">&#10003;</span> :
                                              <span className="text-warning ml-1">&#10003;(scaled)</span>
                                          }
                                        </td>
                                        <td>
                                          <i className="fas fa-less-than-equal mr-1"></i>
                                          {
                                            intFormat.format(imageInfo.height)
                                          }
                                        </td>
                                      </tr>

                                      {
                                        imageInfo.isAnimated &&
                                          <>

                                            <tr>
                                              <td className="text-muted">
                                                Animation Loop
                                              </td>
                                              <td className="talign-right pr-4">
                                                {
                                                  imageInfo.loopCount == 0 ? "Endless" : intFormat.format(imageInfo.loopCount)
                                                }
                                                {
                                                  imageInfo.loopCount == 0 ? <span className="text-danger ml-1">&#10060;</span> :
                                                    imageInfo.loopCount <= 3 ? <span className="text-success ml-1">&#10003;</span> :
                                                      <span className="text-danger ml-1">&#10060;</span>
                                                }
                                              </td>
                                              <td>
                                                {intFormat.format(3)}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td className="text-muted">
                                                Animation Frame Rate (FPS)
                                              </td>
                                              <td className="talign-right pr-4">
                                                {
                                                  intFormat.format(imageInfo.fps)
                                                }
                                                {
                                                  imageInfo.fps <= 24 ? <span className="text-success ml-1">&#10003;</span> :
                                                    <span className="text-danger ml-1">&#10060;</span>
                                                }
                                              </td>
                                              <td>
                                                {intFormat.format(24)}
                                              </td>
                                            </tr>

                                            <tr>
                                              <td className="text-muted">
                                                Animation Duration (seconds)
                                              </td>
                                              <td className="talign-right pr-4">
                                                {
                                                  intFormat.format(imageInfo.duration)
                                                }
                                                {
                                                  imageInfo.duration <= 15 ? <span className="text-success ml-1">&#10003;</span> :
                                                    <span className="text-danger ml-1">&#10060;</span>
                                                }
                                              </td>
                                              <td>
                                                {intFormat.format(15)}
                                              </td>
                                            </tr>

                                          </>

                                      }

                                    </table>
                                  </div>
                                </div>
                            }

                          </center>                        
                                                  
                        </div>  
                      
                      </div>  

                    </div>  

              </section>
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">Cancel</button>
              { 
                !error &&
                  <button type="button" className="btn btn-danger" data-dismiss="modal" onClick={handleApplyClick}>Apply</button>
              }
            </div>            
          </div>
        </div>
      </div> 
    </>
  )
}