import * as loglevel from 'loglevel'

export default function getLogger(name) {

    // const l = loglevel.getLogger(name)
    const l  = loglevel

    // if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    //     l.setLevel("TRACE")
    // }
    // else {
    //     l.setLevel("INFO")
    // }

    const log = {
        trace: function(...args) {
            l.trace(`T [${name}]:`, ...args)
        },
        debug: function(...args) {
            l.debug(`D [${name}]:`, ...args)
        },
        warn: function(...args) {
            l.warn(`W [${name}]:`, ...args)
        },
        info: function(...args) {
            l.info(`I [${name}]:`, ...args)
        },
        error: function(...args) {
            l.error(`E [${name}]:`, ...args)
        },
        setLevel: function(level) {
            l.setLevel(level)
        }
    }

    return log
}