import React, {useState, useEffect, useReducer} from 'react'
import { useParams}  from 'react-router-dom'
import { default as Select } from "react-select"
import {useSolv, useTenant} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {FormHeader, FormBody, MainContainer, useAlert, FormGroup} from "../components/FormComps.js"
import {validateField, showError, clearAllErrors, InfoBlock, InfoText} from '../components/ValidationUtils.js';
import MainMenu from "../MainMenu"
import SystemTabs from "./SystemTabs"
import getLogger from "../components/Logging.js"
import '../App.css'
import {AsyncPaginate} from "react-select-async-paginate";

const log = getLogger("Currency")

export default function Currency(props) {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useAlert()

  const [currencyCode, setCurrencyCode] = useState(null)
  const [currencyName, setCurrencyName] = useState(null)
  const [usdMultiplier, setUsdMultiplier] = useState(null)
  const [paymentProviders, setPaymentProviders] = useState(null)
  const [isAdding, setAdding] = useState(null)

  const [touched, setTouched] = useState(false)

  const [status, setStatus] = useState(null)

  const paymentProviderOpts = [
    {
      value: "BRAINTREE",
      label: intl.msg(`payment_provider_braintree`)
    },
    {
      value: "PAYPAL",
      label: intl.msg(`payment_provider_paypal`)
    },
    {
      value: "STRIPE",
      label: intl.msg(`payment_provider_stripe`)
    },
  ]

  useEffect(() => {
    if (params.currencyCode) {
      if (params.currencyCode.trim().toLowerCase() === "_new") {
        setCurrencyCode(null)
        setCurrencyName(null)
        setUsdMultiplier(null)
        setAdding(true)
      }
      else {
        setCurrencyCode(params.currencyCode)
        setAdding(false)
      }
    }
  }, [])

  useEffect(() => {

    log.debug("useEffect: invoked")

    if (currencyCode) {
      setBusy(intl.msg("loading"))
      api.getCurrency(currencyCode)
        .then(({data}) => {
          log.debug("getCurrency: data=", data)
          setCurrencyCode(data.currencyCode)
          setCurrencyName(data.currencyName)
          setPaymentProviders(data.paymentProviders.map(p => {
            return (
              {
                value: p,
                label: intl.msg(`payment_provider_${p.toLowerCase()}`)
              }
            )
          }))
          setUsdMultiplier(data.usdMultiplier)
          setStatus(data.status)
        })
        .catch((error) => {
          setFatal(error)
        })
        .finally(() => {
          setBusy(null)
        })
    }

  }, [currencyCode]);

  function handleCurrencyNameChange(e) {
    const v = e.target?.value
    setCurrencyName(v)
    setTouched(true)
  }

  function handlePaymentProvidersChange(v) {
    setPaymentProviders(v)
    setTouched(true)
  }

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_currencyName", currencyName && currencyName.trim().length > 0, "required", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid

  }

  async function handleSaveClick() {

    log.debug("handleSaveClick: invoked" )

    if (validate()) {

      setBusy(intl.msg("saving"))

      try {

        const payload = {
          currencyName: currencyName,
          paymentProviders: paymentProviders.map((p) => p.value),
        }

        const {data} = await api.updateCurrency(currencyCode, payload)

        window.location = `/sys/currencies`
      }
      catch (error) {
        handleError(error)
      }
      finally {
        setBusy(null)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }

  }

  function handleCancelClick() {
    window.location.reload()
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("country_error_duplicate"), field: "inp_regionId", constraint: "unique"})
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isEditable() {
    return user.isSystem() && user.isAdminOrAbove()
  }

  return (
    tenant &&
    <>
      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("currency")} {" - " + (isAdding ? "(new)" : currencyCode)}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              {
                touched &&
                  <>
                    <button key="key_btn_save" className="btn btn-primary" onClick={handleSaveClick}>
                      <i className="fas fa-check"></i>{intl.msg("save")}
                    </button>
                    <button key="key_btn_reset" className="btn btn-secondary" onClick={handleCancelClick}>
                      <i className="fas fa-times"></i>{intl.msg("cancel")}
                    </button>
                  </>
              }
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_currencyCode" text={intl.msg("currency_code")} description={intl.msg("currency_code_description")}/>
            <FormGroup.Controls>
              <input id="inp_currencyCode" type="text" className="form-control" value={currencyCode} disabled={true}/>
              <InfoBlock>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_currencyName" text={intl.msg("currency_name")} description={intl.msg("currency_name_description")}/>
            <FormGroup.Controls>
              <input id="inp_countryName" type="text" className="form-control" value={currencyName} disabled={!isEditable()} onChange={handleCurrencyNameChange}/>
              <InfoBlock>
                <InfoText validate="required">{intl.msg("helptext_required")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_paymentProviders" text={intl.msg("currency_payment_providers")} description={intl.msg("currency_payment_providers_description")}/>
            <FormGroup.Controls>
              <div style={{maxWidth: "400px"}}>
                <Select
                  id="inp_paymentProviders"
                  className="react-select"
                  classNamePrefix="react-select"
                  isMulti
                  isClearable="true"
                  value={paymentProviders}
                  placeholder=""
                  options={paymentProviderOpts}
                  onChange={handlePaymentProvidersChange}
                  isDisabled={!isEditable()}/>
              </div>
              <InfoBlock>
                <InfoText validate="required">{intl.msg("helptext_optional")}</InfoText>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

          <FormGroup>
            <FormGroup.Label htmlFor="inp_usdMultiplier" text={intl.msg("usd_multiplier")} description={intl.msg("usd_multiplier_description")}/>
            <FormGroup.Controls>
              <input id="inp_usdMultiplier" type="text" className="form-control" value={intl.num(usdMultiplier)} disabled={true}/>
              <InfoBlock>
              </InfoBlock>
            </FormGroup.Controls>
          </FormGroup>

        </FormBody>
      </MainContainer>

    </>

  )
}
