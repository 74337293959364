import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import './App.css'

export default function Page500() {

  return (
    <Fragment>
        <main>

          <div class="jumbotron m-3">
            <center>
              <h1 style={{color: "red", fontSize: "4.5rem"}}>
                <i className="fas fa-exclamation-triangle"></i>
              </h1>
              <h1 style={{fontSize: "3rem", color: "#fff", marginBottom: "30px"}}>Server Error</h1>
              <p className="lead" style={{color: "#ccc"}}>
                Something went wrong and we're looking into the problem. Please check back later.
                <div className="mt-4"></div>
                <a className="btn btn-primary" style={{fontSize: "1.171875rem;"}} href="javascript:window.history.back()">Go Back</a> <a href="/" className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} >Home</a>
              </p>
            </center>
          </div>            

        </main>
      </Fragment>
    )
}
