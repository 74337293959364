import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import './App.css'

export default function Page503() {

  return (
    <Fragment>
        <main>

          <div class="jumbotron m-3">
            <center>
              <h1 style={{color: "orange", fontSize: "4.5rem"}}><i className="fas fa-hard-hat"></i></h1>
              <h1 style={{fontSize: "3rem", color: "#fff", marginBottom: "30px"}}>Service Unavailable</h1>
              <p className="lead" style={{color: "#ccc"}}>
                SOLV is currently down for maintenance. Sorry for the inconvenience. We will be back very shortly.
                <div className="mt-4"></div>
                <a className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} href="https://console.solv.technology">Retry</a> <a href="https://help.solv.technology" className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} >Help</a>
              </p>
            </center>
          </div>            

        </main>
      </Fragment>
    )
}
