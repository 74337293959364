const intFormat = new Intl.NumberFormat('en-US',{minimumFractionDigits: 0, maximumFractionDigits: 0})

export const externalTooltipHandler = (context) => {
    // Tooltip Element
    const {chart, tooltip} = context;

    // log.debug("TOOLTIP: context=", context)

    const tooltipEl = getOrCreateTooltip(chart);
  
    // Hide if no tooltip
    if (tooltip.opacity === 0) {
      tooltipEl.style.opacity = 0;
      return;
    }
  
    // Set Text
    if (tooltip.body) {
      let v = 0
      let dataPoints = tooltip.dataPoints[0]
      if (dataPoints.dataset.label == "Spent") {
        v = intFormat.format(dataPoints.raw, {minimumFractionDigits: 2, maximumFractionDigits: 2})   
      }
      else {
        v = dataPoints.formattedValue   
      } 
      tooltipEl.innerText = dataPoints.label + ": " + v
    }
  
    const {offsetLeft: positionX, offsetTop: positionY} = chart.canvas;
  
    // Display, position, and set styles for font
    tooltipEl.style.opacity = 1;
    tooltipEl.style.left = positionX + tooltip.caretX + 'px';
    tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    tooltipEl.style.font = tooltip.options.bodyFont.string;
    tooltipEl.style.padding = tooltip.options.padding + 'px ' + tooltip.options.padding + 'px';
  };

  export const getOrCreateTooltip = (chart) => {
    let tooltipEl = chart.canvas.parentNode.querySelector('div');
  
    if (!tooltipEl) {
      tooltipEl = document.createElement('div');
      tooltipEl.style.background = 'rgba(0, 0, 0, 0.7)';
      tooltipEl.style.borderRadius = '3px';
      tooltipEl.style.color = 'white';
      tooltipEl.style.opacity = 1;
      tooltipEl.style.pointerEvents = 'none';
      tooltipEl.style.position = 'absolute';
      tooltipEl.style.transform = 'translate(-50%, 0)';
      tooltipEl.style.transition = 'all .1s ease';
  
      // const table = document.createElement('table');
      // table.style.margin = '0px';
  
      // tooltipEl.appendChild(table);
      chart.canvas.parentNode.appendChild(tooltipEl);
    }
  
    return tooltipEl;
  }  
