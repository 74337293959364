import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useSolv, useTenant} from "./components/SolvProvider";
import CreativePreview from "./CreativePreview.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {useIntlEx} from "./components/IntlUtils";

const log = getLogger("BroadcastPagePreview")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function BroadcastCreativePreview(props) {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()

  const [broadcastId, setBroadcastId] = useState(params.broadcastId ? params.broadcastId : null)
  const {tenant} = useTenant(params.tenantId)

  const [broadcastName, setBroadcastName] = useState(null)
  const [creativeType, setCreativeType] = useState("SOLV")
  const [creativeHeader, setCreativeHeader] = useState(null)
  const [creativeTitle, setCreativeTitle] = useState(null)
  const [creativeContent, setCreativeContent] = useState(null)
  const [creativeFooter, setCreativeFooter] = useState(null)
  const [creativeStyle, setCreativeStyle] = useState({})
  const [pageType, setPageType] = useState("SOLV")
  const [pageUrl, setPageUrl] = useState(null)
  const [pageHeader, setPageHeader] = useState(null)
  const [pageTitle, setPageTitle] = useState(null)
  const [pageContent, setPageContent] = useState(null)
  const [pageStyle, setPageStyle] = useState({})
  const [creativePhotoData, setCreativePhotoData] = useState(null)
  const [pagePhotoData, setPagePhotoData] = useState(null)
  const [targetBudget, setTargetBudget] = useState(100)
  const [targetLocation1, setTargetLocation1] = useState(null)
  const [targetLocation2, setTargetLocation2] = useState(null)
  const [targetLocation3, setTargetLocation3] = useState(null)
  const [targetLocation4, setTargetLocation4] = useState(null)
  const [broadcastStatus, setBroadcastStatus] = useState("DRAFT")
  const [pacingMode, setPacingMode] = useState({value: "PaceAhead", label: "PaceAhead"})
  const [feedbackEnabled, setFeedbackEnabled] = useState(true)
  const [broadcastCreatedOn, setBroadcastCreatedOn] = useState(null)
  const [broadcastCreatedBy, setBroadcastCreatedBy] = useState(null)
  const [broadcastModifiedOn, setBroadcastModifiedOn] = useState(null)
  const [broadcastModifiedBy, setBroadcastModifiedBy] = useState(null)
  const [broadcastPublishedOn, setBroadcastPublishedOn] = useState(null)
  const [broadcastPublishedBy, setBroadcastPublishedBy] = useState(null)
  const [broadcastPaymentDetails, setBroadcastPaymentDetails] = useState(null)
  const [popEstimations, setPopEstimations] = useState({totalPop: -1, popReached: -1, popReachedPct: -1, budgetToReach100Pct: -1})
  const [publishDialogState, setPublishDialogState] = useState("confirm")
  const [paypalError, setPaypalError] = useState(null)
  const [allTimeStats, setAllTimeStats] = useState(null)

  // function loadTenant(tenantId) {
  //   return api.getTenant(user.tenantId)
  //     .then(({data}) => {
  //       setTenant(data)
  //       return data
  //     })
  //     .catch((error) => {
  //       log.debug("Load Tenant failed: error=", error)
  //     })
  // }

  function loadBroadcast(tenantId, broadcastId) {
      return api.getBroadcast(broadcastId)
        .then(({data}) => {
          log.debug("Broadcast Loaded: data=", data)
          setBroadcastName(data.broadcastName)
          setCreativeType(data.creativeType)
          setCreativeHeader(data.creativeHeader)
          setCreativeTitle(data.creativeTitle)
          setCreativeContent(data.creativeContent)
          setCreativeFooter(data.creativeFooter)
          let style
          if (data.creativeStyle) {
            style = data.creativeStyle
          }
          else {
            style = {border: {color: "#FF0000"}}
          }
          setCreativeStyle(data.creativeStyle)

          setPageType(data.pageType)
          setPageHeader(data.pageHeader)
          setPageTitle(data.pageTitle)
          setPageContent(data.pageContent)
          if (data.pageUrl && data.pageUrl.trim().length > 0) {
            setPageUrl(data.pageUrl)
          }
          else {
            setPageUrl("")
          }
          if (data.pageStyle) {
            style = data.pageStyle
          }
          else {
            style = {border: {color: "#FF0000"}}
          }
          setPageStyle(data.pageStyle)

          setCreativePhotoData(data.creativePhotoData)
          if (data.pagePhotoData && data.pagePhotoData.trim().length > 0) {
            setPagePhotoData(data.pagePhotoData)
          }


          setBroadcastStatus(data.status)
          setPacingMode({value: data.pacingMode, label: data.pacingMode})
          setBroadcastCreatedOn(dayjs(data.createdOn))
          setBroadcastCreatedBy(data.createdBy)
          setBroadcastModifiedOn(dayjs(data.modifiedOn))
          setBroadcastModifiedBy(data.modifiedBy)
          setBroadcastPublishedOn(dayjs(data.publishedOn))
          setBroadcastPublishedBy(data.publishedBy)
          setFeedbackEnabled(data.feedbackEnabled)
          setBroadcastPaymentDetails(data.paymentDetails)
          return data
        })
        .catch(function (error) {
          log.debug("Load Broadcast Failed: error=", error)  
        })
  }

  useEffect(() => {
    setBusy(intl.msg("loading"))
    loadBroadcast(tenant.tenantId, broadcastId)
      .catch((err) => {
        setFatal(err)
      })
      .finally(function() {
        setBusy(null)
      })
  }, [tenant]);
    
  return (
    <Fragment>
        <div style={{backgroundColor: "white"}}>
            <CreativePreview
                tenantName={tenant.tenantName}
                creativeType={creativeType}
                creativeHeader={creativeHeader ? creativeHeader : ""}
                creativeTitle={creativeTitle}
                creativeContent={creativeContent}
                creativeFooter={creativeFooter}
                creativeStyle={creativeStyle}
                logoUrl={tenant.logoUrl}
                photoData={creativePhotoData}
                allowedDomains={tenant.allowedDomains}
                disallowedDomains={tenant.disallowedDomains}
            />
          </div>
      </Fragment>
    )
}
