import React, { useState, useEffect }  from 'react'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { TagBox, TagLabel } from "./TagBox.js"
import getLogger from "./Logging.js"
import styles from "./AccountUtils.module.css"
import {useSolv} from "./SolvProvider";
import {useIntlEx} from "./IntlUtils";
import {shortIdEqual} from "./StringUtils";

const log = getLogger("AccountUtils")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

function getStatusName(status) {
  switch (status) {
    case "NEW":
    case "ACTIVE":
    case "BANNED":
    case "BLOCKED":
    case "INACTIVE":
    case "INVITING":
    case "ONBOARDING":
      return status.toLowerCase()
    default:
      if (status?.startsWith("UNUSED")) {
        return "unused"
      }
      else if (status?.startsWith("DORMANT")) {
        return "dormant"
      }
      else {
        return "unknown"
      }
  }
}

export function useAccountStatusName() {

}

export function useAccountStatusOpts() {
  const {intl} = useIntlEx()
  const accountStatusOpts = [
    {
      value: "NEW",
      label: intl.msg("status_account_new"),
    },
    {
      value: "ACTIVE",
      label: intl.msg("status_account_active"),
    },
    {
      value: "INACTIVE",
      label: intl.msg("status_account_inactive"),
    },
    {
      value: "DORMANT",
      label: intl.msg("status_account_dormant"),
    },
    {
      value: "UNUSED",
      label: intl.msg("status_account_unused"),
    },
    {
      value: "BANNED",
      label: intl.msg("status_account_banned"),
    },
    {
      value: "ONBOARDING",
      label: intl.msg("status_account_onboarding"),
    },
    {
      value: "INVITING",
      label: intl.msg("status_account_inviting"),
    },
  ]
  function findAccountStatusOpt(status) {
    const st = status?.toUpperCase()
    if (!st) {
      return null
    }
    if (st.startsWith("DORMANT")) {
      return accountStatusOpts.find(o => o.value === "DORMANT")
    }
    else if (st.startsWith("UNUSED")) {
      return accountStatusOpts.find(o => o.value === "UNUSED")
    }
    else {
      return accountStatusOpts.find(o => o.value === st)
    }
  }
  return ({
    accountStatusOpts,
    findAccountStatusOpt,
  })
}

export function AccountStatus(props) {
  let status

  if ("string" === typeof(props)) {
    status = props
  }
  else {
    status = props.status || props.tenantStatus
  }
  if (!status) {
    return (<></>)
  }
  let statusLabel = getStatusName(status)
  return (
    <TagBox>
      <TagLabel label={statusLabel} className={styles[`account-status-color-${statusLabel}`]} tooltip={statusLabel}/>
    </TagBox>
  )
}

export function mkHref({suffix, tenant, user}) {
  if (user) {
    if (shortIdEqual(user.tenantId, tenant.tenantId)) {
      return suffix
    }
    else {
      return `/${tenant.tenantId.replaceAll("-", "")}${suffix}`
    }
  }
  else {
    if (tenant && tenant.accessingAs("*/MEMBER/*")) {
      return suffix
    }
    else {
      return `/${tenant.tenantId.replaceAll("-", "")}${suffix}`
    }
  }
}

export function useResellerType(props) {

  const {session} = useSolv()
  const {intl} = useIntlEx()

  let resellerTypeOpts = [
    {
      value: "MASTER",
      label: intl.msg(`reseller_type_master`),
    },
    {
      value: "SUPER",
      label: intl.msg(`reseller_type_super`),
    },
    {
      value: "SUB",
      label: intl.msg(`reseller_type_sub`),
    },
    {
      value: "STANDALONE",
      label: intl.msg(`reseller_type_standalone`),
    }
  ]

  resellerTypeOpts.sort((a, b) => a.label.localeCompare(b.label))

  function getResellerType(tenant) {
    let n = null
    if (tenant && tenant.tenantTypeId === "RESELLER") {
      if (tenant.parentId) {
        if (tenant.hasSubTenants()) {
          n = "SUPER"
        }
        else {
          n = "SUB"
        }
      }
      else if (tenant.hasSubTenants()) {
        n = "MASTER"
      }
      else {
        n = "STANDALONE"
      }
    }
    return n
  }

  function getAvailableResellerTypeOpts(tenant) {
    switch (getResellerType(tenant)) {
      case "MASTER":
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE")
        ]
      case "SUPER":
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE"),
          findResellerTypeOpt("SUPER"),
        ]
      case "SUB":
        return [
          findResellerTypeOpt("STANDALONE"),
          findResellerTypeOpt("SUB"),
        ]
      default:
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE"),
        ]
    }
  }

  function getInvitableResellerTypeOpts(tenant) {
    switch (tenant?.tenantTypeId) {
      case "SYSTEM":
        return [
          findResellerTypeOpt("MASTER"),
          findResellerTypeOpt("STANDALONE")
        ]
      // case "RESELLER":
      //   switch (getResellerType(tenant)) {
      //     case "MASTER":
      //       log.debug("getInvitableResellerTypeOpts: MASTER: ", tenant.maxDepth)
      //       if (tenant.maxDepth > 1) {
      //         return [
      //           findResellerTypeOpt("SUB"),
      //           findResellerTypeOpt("SUPER"),
      //         ]
      //       }
      //     default:
      //       return []
      //   }
      default:
        return []
    }
  }

  function findResellerTypeOpt(resellerType) {
    if (!resellerType) {
      return null
    }
    const st = resellerType.toUpperCase()
    return resellerTypeOpts.find(i => i.value?.toUpperCase() === st)
  }

  return {
    getResellerType,
    resellerTypeOpts,
    getAvailableResellerTypeOpts,
    getInvitableResellerTypeOpts,
    findResellerTypeOpt
  }

}

export function hasChildTenants(tenant) {
  return (tenant?.maxDepth ? tenant?.maxDepth > (tenant?.parentPath ? (tenant?.parentPath.split(".").length - 1) : 0) : false)
}

export const PATH_PAT = /^\/?(?:([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32}))\/([^_]+)(?:\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32}|_[a-zA-Z0-9_]+))?$/;

export const PATH_START_PAT = /^\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32})/;

export const PATH_END_PAT = /\/([a-f0-9]{8}(?:-[a-f0-9]{4}){3}-[a-f0-9]{12}|[a-f0-9]{32}|_[a-zA-Z0-9_]+)$/;

export const TENANT_PATH_PAT = /^\/(?:(?:[0-9a-fA-F]{8}(?:-[0-9a-fA-F]{4}){3}-[0-9a-fA-F]{12})|[0-9a-fA-F]{32})/;

export function parsePath(path) {
  const match = path.match(PATH_PAT)
  log.debug(`parsePath: path=${path}, match=${match}`)
  return ({
    tenantId: match[1] ? match[1].replace(/-/g, '') : null,
    documentPath: match[2] ? match[2].replace(/\/$/, '') : null,
    documentId: match[3] ? match[3].replace(/_/g, '') : null
  })
}

export function EnsureAccessible(props) {

  const {setFatal} = useSolv()
  const [tenant, setTenant] = useState(null)

  useEffect(() => {
    if (props.tenant) {
      setTenant(props.tenant)
    }
  }, [props.tenant])

  useEffect(() => {
    if (tenant) {
      if (props.accessingAs) {
        if (!tenant.accessingAs(props.accessingAs)) {
          setFatal("DENIED_ACCESS")
        }
      }
    }
  }, [tenant])

}

export function TenantLink(props) {
  const {intl} = useIntlEx()
  const tenantId = props?.tenant && props.tenant.tenantId ? props.tenant.tenantId : props?.tenantId ? props.tenantId : null
  const tenantName = props?.tenant && props.tenant.tenantName ? props.tenant.tenantName : props?.tenantName ? props.tenantName : null
  if (tenantId && tenantName) {
    return (
      <a className="text-link" href={`/${tenantId.replace(/\-/g, "")}${props.suffix ? props.suffix : ""}`}>
        {tenantName}
      </a>
    )
  }
  else {
    return (<></>)
  }
}