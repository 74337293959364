import React, {useEffect, useRef, useState} from 'react'
import {googleLogout} from '@react-oauth/google';
import {useResizeDetector} from "react-resize-detector";
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils.js"
import {AboutSolvDialog} from '../AboutSolvDialog.js'
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("ExpressPublishMenu")

export default function ExpressPublishMenu(props) {

    const {brandId, session, signout, setBusy} = useSolv()
    const {intl } = useIntlEx()

    const [tenant, setTenant] = useState(null)

    const { width, height, ref } = useResizeDetector()
    const homeLinkRef = useRef()
    const logoRef = useRef()

    useEffect(() => {
        if (props) {
            log.debug("useEffect: tenant=", props.tenant)
            setTenant(props.tenant)
        }
    },[])

    function handleSignout() {
        setBusy(intl.msg("working"))
        signout()
            .then((data) => {
                log.debug("handleSignout: data=", data)
                googleLogout()
            })
            .catch((err) => {
                log.error(err)
            })
            .finally(() => {
                setBusy(null)
            })
    }

    function logo(colormono) {
        const theme = window.getComputedStyle(document.documentElement).getPropertyValue("--theme")?.trim()?.toLowerCase() || "dark"
        // if (homeLinkRef.current) {
        //     // log.debug("homeLink=", homeLinkRef.current.classList.contains("brand-link-active"))
        //     if (homeLinkRef.current.classList.contains("brand-link-active")) {
        //         return `https://sassets.solv.technology/brand/${brandId.toLowerCase()}-logo-color-${theme}-md-c.png`
        //     }
        // }
        return `https://sassets.solv.technology/brand/${brandId.toLowerCase()}-logo-${colormono}-${theme}-md-c.png`
    }

    useEffect(() => {
        log.debug("height=", height)
        document.querySelector(':root').style.setProperty("--main-menu-height", height)
    }, [height])


    return (
        <>
            <nav ref={ref} className="main-menu fixed-top">
                {
                    <>
                        <div className="items">

                            <ul className="items" style={{flex: "1", justifyContent: "left"}}>

                                <li className="brand-item">
                                    <img ref={logoRef} src={logo("color")} width="44" height="14"/>
                                </li>

                            </ul>

                            <ul className="items">
                                <li className="menu-item help-item">
                                    <a href="#" className="dropdown-toggle dropdown-caret-off"  title={intl.msg("help_tooltip")} data-toggle="dropdown" data-offset="0,2" aria-haspopup="true" aria-expanded="false">
                                        <div className="round-item">
                                            ?
                                        </div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="mni_help">
                                        <a className="dropdown-item" href="https://help.solv.technology/category/using-solv" target="_blank">{intl.msg("hc_using_solv")}</a>
                                        <a className="dropdown-item" href="https://help.solv.technology/category/faq" target="_blank">{intl.msg("hc_faq")}</a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="https://help.solv.technology" target="_blank">{intl.msg("hc_help_center")}</a>
                                        <div className="dropdown-divider"></div>
                                        {/*<ContactSupportLink user={user} tenant={tenant} className="dropdown-item"/>*/}
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#" data-toggle="modal" data-target="#dlg_about_solv">
                      <span className="mr-1">
                        {intl.msg("about_solv")}
                      </span>
                                        </a>
                                    </div>
                                </li>

                                <li className="menu-item user-item">
                                    <a href="#" className="dropdown-toggle dropdown-caret-off"  title={session?.user?.displayName} data-toggle="dropdown" data-offset="0,2" aria-haspopup="true" aria-expanded="false">
                                        <div className="round-item">
                                            {
                                                session?.user?.imageUrl ? (
                                                    <img src={session?.user?.imageUrl} style={{width: "18px", height: "18px", borderRadius: "50%"}}/>
                                                ) : (
                                                    <i className="fas fa-user" style={{fontSize: "8pt"}}></i>
                                                )
                                            }
                                        </div>
                                    </a>
                                    <div className="dropdown-menu" aria-labelledby="mni_user">
                                        <a className="dropdown-item" href="/me">
                                            <span>{intl.msg("profile")}:</span>
                                            <span className="ml-1 text-muted">{session?.user?.displayName}</span></a>
                                        <div className="dropdown-divider"></div>
                                        <a className="dropdown-item" href="#" onClick={handleSignout}>{intl.msg("signout")}</a>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </>
                }
            </nav>
            <div className="main-menu-push" style={{height: height}}></div>
            <AboutSolvDialog/>
        </>
    )
}
