import React, { useState, useEffect } from "react"
import {mkExtraParams} from "./TableComps";
import getLogger from "../components/Logging.js"
import {AsyncPaginate} from "react-select-async-paginate";
import {useSolv} from "./SolvProvider";
import {useIntlEx} from "./IntlUtils";
const log = getLogger("PageDialog")

export function SelectReseller(props) {

  const {api} = useSolv()
  const {intl} = useIntlEx()

  const searchField = props.searchField ? props.searchField : "tenantName"
  const valueField = props.valueField ? props.valueField : "resellerId"
  const labelField = props.labelField ? props.labelField : "tenantName"
  const [value, setValue] = useState(null)

  useEffect(() => {
    if (props.value) {
      setValue(props.value)
    }
  }, [props])

  function loadResellers(search, loadedOptions, additional) {
    log.debug("loadResellers: props, search=", props, search)
    return api.listResellers({tenantId: props.tenant.tenantId, extraParams: mkExtraParams({filterParams: {[searchField]: search?.trim()}, sortParams: {[searchField]: "ASC"}}), cursor: additional?.cursor
    })
      .then(({data, nextCursor}) => {
        log.debug("loadResellers: data=", data, nextCursor)
        let opt = data.map(c => {
          return ({...c, value: c[valueField], label: c[labelField]})
        });
        log.debug("loadResellers: includeSelf=", props.includeSelf)
        if (props.includeSelf) {
          opt.unshift({
            ...props.tenant, value: props.tenant[valueField], label: `${props.tenant[labelField]} (${intl.msg("you")})`
          })
        }
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadResellers: res=", res)
        return res
      })
    }

    function handleResellerChange(v) {
      setValue(v)
      if (props.onChange) {
        props.onChange(v)
      }
    }

    return (
      <>
        <AsyncPaginate
          id={props.id || "inp_resellers"}
          className="react-select"
          classNamePrefix="react-select"
          isClearable={props.isClearable}
          value={value}
          debounceTimeout={800}
          defaultOptions={true}
          loadOptions={loadResellers}
          onChange={handleResellerChange}
          isDisabled={props.isDisabled ? props.isDisabled : false}/>
      </>
    )
  }