import React, { createContext,  useState, useEffect } from "react"
import { default as Select } from "react-select"
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear'
import 'react-datepicker/dist/react-datepicker.css'
import getLogger from "./components/Logging.js"
import './App.css'
import {useIntlEx} from "./components/IntlUtils";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)

const log = getLogger("ReportDateRangeSelect")

export function useReportDateRangeOpts(props) {

  const today = dayjs()
  const startOfToday = today.startOf("day")
  const endOfToday = today.endOf("day")

  const {intl} = useIntlEx()

  const dateRangeOpts = [
    {
      label: intl.msg("last_7_days"),
      value: "Last7Days",
      startTime: startOfToday.subtract(7, "day").valueOf(),
      endTime: endOfToday.valueOf(),
    },
    {
      label: intl.msg("last_30_days"),
      value: "Last30Days",
      startTime: startOfToday.subtract(30, "day").valueOf(),
      endTime: endOfToday.valueOf(),
    },
    {
      label: intl.msg("last_60_days"),
      value: "Last60Days",
      startTime: startOfToday.subtract(60, "day").valueOf(),
      endTime: endOfToday.valueOf(),
    },
    {
      label: intl.msg("last_90_days"),
      value: "Last90Days",
      startTime: startOfToday.subtract(90, "day").valueOf(),
      endTime: endOfToday.valueOf(),
    },
    {
      label: intl.msg("this_year"),
      value: "ThisYear",
      startTime: today.startOf("year").valueOf(),
      endTime: endOfToday.valueOf(),
    },
    {
      label: intl.msg("last_year"),
      value: "LastYear",
      startTime: today.subtract(1, "year").startOf("year").toDate().getTime(),
      endTime: endOfToday.subtract(1, "year").endOf("year").toDate().getTime(),
    },
  ]

  function findDateRangeOpt(value) {
    if (!value) {
      return null
    }
    const result = dateRangeOpts.find(o => o.value === value)
    if (result) {
      return result
    }
    else {
      return dateRangeOpts[0]
    }
  }

  return ({
    dateRangeOpts,
    findDateRangeOpt
  })

}

export const ReportDateRangeSelect = (props) => {

  const {intl} = useIntlEx()

  const [dateRange, setDateRange] = useState(null)

  const today = dayjs()
  const startOfToday = today.startOf("day")
  const endOfToday = today.endOf("day")

  const dateRangeOpts = useReportDateRangeOpts()

  useEffect(() => {
    if (props && props.initialValue) {
      handleDateRangeChange(dateRangeOpts.findDateRangeOpt(props.initialValue))
    }
    else {
      handleDateRangeChange(dateRangeOpts.dateRangeOpts[0])
    }
  }, [])

  function handleDateRangeChange(v) {
      setDateRange(v)
      if (props.onChange) {
        props.onChange(v)
      }
  }

  return (
    <Select
        id={props.id ? props.id : "inp_dateRanges"}
        className="react-select"
        classNamePrefix="react-select"
        cacheOptions
        value={props.value || dateRange}
        defaultValue={dateRangeOpts.dateRangeOpts[0]}
        placeholder={props.placeholder}
        isClearable={props.isClearable}
        options={dateRangeOpts.dateRangeOpts}
        menuPortalTarget={document.body}
        onChange={handleDateRangeChange}
        isDisabled={props.isDisabled}
    />
  )
}