import React, {useState, useRef} from "react"

export function SecretText(props) {

    const [visible, setVisible] = useState(false)
    const ref = useRef()

    function handleClick() {
        setVisible((prev) => !prev)
    }

    return (
        props.text &&
            <span ref={ref} className={"" + props.className || ""} style={{cursor: "pointer"}} onClick={handleClick} title={`Click to ${visible ? "hide" : "show"}`}>
                {
                    visible ? (
                        props.text
                    ) : (
                        "*".repeat(props.text.length)
                    )
                }
            </span>
    )
}