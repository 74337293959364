import React, {Fragment, useState}  from 'react'
import {useHistory}  from 'react-router-dom'
import {useInfiniteQuery} from 'react-query'
import useIntersectionObserver from './useIntersectionObserver.js'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear'
import 'react-datepicker/dist/react-datepicker.css'
import { useSolv } from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer} from "./components/FormComps.js"
import SortableColumn from "./components/SortableColumn"
import Page403 from "./Page403"
import { ReportDateRangeSelect } from "./ReportDateRangeSelect"
import { openDialog } from "./components/DialogUtils";
import ExportToFileDialog from "./components/ExportToFileDialog"
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Bills")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)

export default function Bills() {

  const { api, auth: { session: { user, tenant } }, setFatal, setBusy } = useSolv()

  const { intl } = useIntlEx()

  const history = useHistory()

  const [error, setError] = useState(null)

  const [transactionDateRange, setTransactionDateRange] = useState(null)
  const [transactionSortSpec, setTransactionSortSpec] = useState({field: "createdOn", direction: "DESC"})
  const [total, setTotal] = useState(0.0)

  const fetchBills = async ({ pageParam = null }) => {

    let url = `/v1/tenants/${user.tenantId}/bills/?startTime=${transactionDateRange.startTime}&endTime=${transactionDateRange.endTime}&sort=${transactionSortSpec.field}:${transactionSortSpec.direction}&limit=0` + (pageParam ? `&cursor=${pageParam}` : "")

    log.debug("fetchBills: url=", url)
    
    const data = await api.get(url)

    log.debug("fetchBills: data=", data)

    if (data && data.data) {
      setTotal(data.data.reduce((t, a) => t + a.amount, 0))
    }

    return data
  }

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['bills', transactionDateRange, transactionSortSpec], fetchBills, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor
    },
    enabled: (transactionDateRange != null)
  })  

  const loadMoreButtonRef = React.useRef()  

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  function handleRowClick(url) {
    history.push(url)
  }

  function BilledItem({row}) {

    return (
      row.refType ? (
        <>
          {
            "PUBLISH_BROADCAST" === row.refType ? (
              `Broadcast ("${row.broadcastName}")`
            ) : "TOP_UP" === row.refType ? (
              "Top Up CreditTransactions"
            ) : (
              <></>
            )

          }
        </>
      ) : (
        <></>
      )
    )
  }

  function handleTransactionDateRangeChange(v) {
    if (v) {
      setTransactionDateRange(v)
    }
  }

  async function doExport() {
    return await api.exportBills({startTime: transactionDateRange.startTime, endTime: transactionDateRange.endTime})
  }

  return (

      user.isAdminOrAbove() ? (
        <Fragment>

          <MainMenu/>
          
          <MainContainer>

            <FormHeader>
              <FormHeader.Toolbar>
                <FormHeader.Toolbar.Title>
                  {intl.msg("bills_title")}
                </FormHeader.Toolbar.Title>
                <FormHeader.Toolbar.Controls>
                  <div style={{width: "200px"}}>
                    <ReportDateRangeSelect id="inp_transactionDateRange" onChange={handleTransactionDateRangeChange}/>
                  </div>
                  {
                    user.isOwner() && (total > 0) &&
                      <button className="btn btn-secondary ml-1" type="button" title={intl.msg("export")} onClick={() => openDialog("dlg_bills_export")}>
                        <i className="fas fa-download" style={{padding:"0", marginRight:"-2px"}}></i>
                      </button>
                  }
                </FormHeader.Toolbar.Controls>
              </FormHeader.Toolbar>

              { 
                error &&
                  <FormHeader.Alerts>
                    <div className="alert alert-dismissible alert-danger">
                      <span><i class="fas fa-exclamation-triangle"></i>&nbsp;{error}</span>
                    </div>
                  </FormHeader.Alerts>                     
              }

            </FormHeader>  

            <FormBody>

              <div className="row">
                <div className="col">

                  
                  <table className="table table-dark table-striped table-bordered table-hover dir">
                    <thead className="thead-dark">
                      <tr>
                        <th scope="col" className="col-clickable">
                          <SortableColumn label="Order Date" field="createdOn" sortSpec={transactionSortSpec} setSortSpec={setTransactionSortSpec}/>
                        </th>
                        <th scope="col">
                          Order #
                        </th>
                        <th scope="col">
                          Description
                        </th>
                        <th scope="col" style={{textAlign: "right"}}>
                          {`Amount (${user.isSystemUser() ? "USD" : tenant.currencyCode})`}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                    {
                      status === 'loading' ? (
                        <tr>
                          <td colSpan="3"><p>Loading...</p></td>
                        </tr>
                      ) : status === 'error' ? (
                        <tr>
                          <td colSpan="3"><p>ERROR!</p></td>
                        </tr>
                      ) : data ? (
                          // func(data)
                          data.pages.map((group, i) => (
                            <React.Fragment key={i}>
                              {group.data.map(row => (
                                <tr key={row.tenantBillId}>
                                  <td>
                                    <div title={dayjs(row.createdOn).format("YYYY-MM-DD HH:mm:ss")}>
                                      {dayjs(row.createdOn).format("YYYY-MM-DD")}
                                    </div>
                                  </td>
                                  <td>
                                    {row.tenantBillId}
                                  </td>
                                  <td>
                                    <span>
                                      <BilledItem row={row}/>
                                    </span>
                                  </td>
                                  <td style={{textAlign: "right"}}>
                                    <span>
                                      {intl.num(row.amount)}
                                    </span>
                                  </td>
                                </tr>                          
                              ))}
                            </React.Fragment>
                          ))
                      ) : ""
                    }
                    </tbody>
                    <tfoot>
                    <tr>
                      <th colSpan="3">
                      </th>
                      <th style={{textAlign: "right"}}>
                        {intl.msg("sum_total")}&nbsp;{intl.num(total)}
                      </th>
                    </tr>
                    </tfoot>
                  </table>
                  <div>
                    { 
                      hasNextPage ?
                        <button
                          className="btn btn-secondary"
                          ref={loadMoreButtonRef}
                          onClick={() => fetchNextPage()}
                          disabled={!hasNextPage || isFetchingNextPage}>
                          { 
                            isFetchingNextPage
                              ? <span>Loading more...</span>
                              : <span>Load More</span>
                          }
                        </button>
                        : ""
                    }
                  </div>
                  <div>
                    {isFetching && !isFetchingNextPage ? 'Background Updating...' : null}
                  </div>              
                </div>
              </div>
            </FormBody>

          </MainContainer>
          <ExportToFileDialog
            type="bills"
            fileName={`solv-billing-${intl.date(new Date(), "ISO-COMPACT")}`}
            onExport={doExport}
          />
        </Fragment>
      ) : (
        <Page403/>
      )
    )
}