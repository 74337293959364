import React, {useEffect, useState} from 'react'
import {useSolv} from "./components/SolvProvider";
import {useIntlEx} from "./components/IntlUtils.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import getLogger from "./components/Logging.js"
import {Alert, FormGroup, useAlert} from "./components/FormComps";
import './App.css'
import {mkHref} from "./components/AccountUtils";
import {MarkdownTextArea} from "./components/MarkdownTextArea";
import {InfoBlock, InfoText} from "./components/ValidationUtils";
import {is} from "date-fns/locale";
import {shortIdEqual} from "./components/StringUtils";

const log = getLogger("AccountDeleteDialog")

export default function AccountDeleteDialog(props)  {

  const {api, session, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()

  const [tenant, setTenant] = useState(null)
  const [row, setRow] = useState(null)

  const [reason, setReason] = useState(null)

  useEffect(() => {
    log.debug("useEffect: invoked")
    if (props.tenant) {
      log.debug("useEffect: tenant=", props.tenant)
      setTenant(props.tenant)
    }
    if (props.row) {
      log.debug("useEffect: row=", props.row)
      setRow(props.row)
    }
  }, [props])

  function handleReasonChange(e) {
    setReason(e.target.value)
  }

  function handleDeleteClick() {
    setBusy(intl.msg("deleting"))
    const tenantTypeId = tenant.tenantTypeId
    api.deleteTenant(tenant.tenantId, {reason: reason})
      .then(({data}) => {
        log.debug("handleDeleteClick: data=", data)
        window.location = tenantTypeId === "CLIENT" ? "/clients" : (tenantTypeId === "RESELLER") ? "/resellers" : "/"
      })
      .catch((error) => {
        handleError(error)
        // log.debug("handleDeleteClick: Error deleting invitation")
        // setAlert({error: intl.msg("error_failed")})
      })
      .finally(() => {
        setBusy(null)
      })
  }

  // function isOwner() {
  //   return (shortIdEqual(row.tenantId, session?.user?.tenantId))
  // }

  function handleOpenDialog() {
    log.debug("handleOpenDialog: tenant=, row=", props.tenant, props.row)
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_account_delete")
  }

  function handleError(error) {
    if (error) {
      if (error.code) {
        switch (error.code) {
          case "ALREADY_DELETED":
            setAlert({error: intl.msg("account_delete_error_already_deleted")})
            break
          case "ILLEGAL_HAS_CLIENTS":
            setAlert({error: intl.msg("account_delete_error_has_clients")})
            break
          case "ILLEGAL_HAS_SUBTENANTS":
            setAlert({error: intl.msg("account_delete_error_has_subtenants")})
            break
          case "ILLEGAL_HAS_UNSPENT_CREDITS":
            setAlert({error: intl.msg("account_delete_error_has_unspent_credits")})
            break
          case "ILLEGAL_HAS_ACTIVE_BROADCASTS":
            setAlert({error: intl.msg("account_delete_error_has_active_broadcasts")})
            break
          default:
            setAlert({error: intl.msg("error_failed")})
        }
      }
      else {
        setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function isInvitation() {
    return ["INVITING", "ONBOARDING"].includes(props.tenant.status)
  }

  return (
    props.tenant &&
    <>
      <ModalDialog
        id="dlg_account_delete"
        size="xl"
        keyboard="true"
        backdrop="static"
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
      >

        <ModalDialog.Header
          title={intl.msg(isInvitation() ? "account_invitation_delete_title" : "account_delete_title")}
          alert={alert}
        />

        {
          !alert &&
            <ModalDialog.Body>

              <Alert alert={{warning: intl.msg(isInvitation() ? "account_invitation_delete_text" : "account_delete_text", {tenantName: props.tenant?.tenantName})}} align="center"/>

              {
                !isInvitation() &&
                  <FormGroup className="mt-3">
                    <FormGroup.Label text={intl.msg("account_delete_reason")} description={intl.msg("account_delete_reason_description")}/>
                    <FormGroup.Controls>
                      <MarkdownTextArea id="inp_reason" value={reason} onChange={handleReasonChange}>
                        <InfoBlock>
                          <InfoText>{intl.msg("helptext_you_can_use_markdown")}</InfoText>
                        </InfoBlock>
                      </MarkdownTextArea>
                    </FormGroup.Controls>
                  </FormGroup>
              }

            </ModalDialog.Body>
        }

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <button className="btn btn-secondary" type="button" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
            {
              !alert && (isInvitation() || reason) &&
                <button className="btn btn-primary" type="button" onClick={handleDeleteClick}>{intl.msg("delete")}</button>
            }
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
    </>
  )
}