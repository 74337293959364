import React, { useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { AlertBox } from "./components/AlertBox.js"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("BroadcastDeleteDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function CustomFieldDeleteDialog(props)  {

  const { intl } = useIntlEx()

  const [touched, setTouched] = useState(false)
  const [working, setWorking] = useState(null)
  const [info, setInfo] = useState(null)
  const [error, setError] = useState(null)
  
  useEffect(() => {
  }, []);    

  function handleDeleteClick() {
    let index = props.fields.findIndex(e => e.id === props.field.id)
    log.debug("handleDeleteClick: ", index, props.field.id)
    props.setFields([
      ...props.fields.slice(0, index),
      ...props.fields.slice(index + 1),
    ])      
    props.setTouched(true)
  }

  return ( 

    <>
      <div id="overlay" style={working ? {display:"flex"} : {display:"none"}}>
        <p>{working}</p>
      </div>
    
      <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-labelledby={props.id} aria-hidden="true">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title">{intl.msg("template_field_delete_title", {type: props.dialogType})}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <section className="main">
                <div className="row">
                  <div className="col">
                    <div className="alert alert-danger" style={error ? {display: "block"} : {display: "none"}}>
                      <span>{error}</span>
                    </div>
                    <center>
                      {
                        !error &&
                          <AlertBox type="warning">{intl.msg("template_field_delete_text", {type: props.dialogType, fieldLabel: props.field.label})}</AlertBox>
                      }
                    </center>  
                  </div>  
                </div>
              </section>
            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{intl.msg("cancel")}</button>
              { 
                !error &&
                  <button type="button" class="btn btn-danger" data-dismiss="modal" onClick={handleDeleteClick}>{intl.msg("delete")}</button>
              }
            </div>            
          </div>
        </div>
      </div> 
    </>
  )
}