import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import { BrowserRouter } from "react-router-dom"
import * as serviceWorker from './serviceWorker'
import log, * as loglevel from 'loglevel'
import {SolvProvider} from "./components/SolvProvider";

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
    loglevel.setLevel("DEBUG")
}
else {
    loglevel.setLevel("INFO")
}

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <SolvProvider>
        <App />
      </SolvProvider>
    </BrowserRouter>

  </React.StrictMode>,
  document.getElementById('root')
);

serviceWorker.unregister();
