import React  from 'react'
import { useIntlEx } from "./IntlUtils.js"
import { TagLabel } from './TagBox'
import getLogger from "./Logging.js"
import {useSolv} from "./SolvProvider";
import {Markdown} from "./MarkdownUtils";
import {Alert} from "./FormComps";

const log = getLogger("BroadcastUtils")

export function BroadcastStatusTagLabel(props) {
  const { intl } = useIntlEx()
  const user = props.user
  const broadcast = props.broadcast
  let realStatus = (broadcast ? broadcast.status : props.status)?.toLowerCase()
  if (realStatus) {
    const displayStatus = getBroadcastDisplayStatus(realStatus, user)
    let title = intl.msg(`status_broadcast_${displayStatus}`)
    let txt = intl.msg(`status_broadcast_${displayStatus}`)
    let pct = getPctDelivered(broadcast, intl)
    if (pct) {
      txt = txt + " "  + intl.int(Math.min(pct, 99.00)) + "%"
    }
    return (
      displayStatus &&
        <TagLabel
          label={txt}
          title={title}
          className={"broadcast-status-color-" + displayStatus + (props.size ? ` tag-label-${props.size}` : "") + (props.className ? ` ${props.className}` : "")}/>
    )
  }
  else {
    return <></>
  }
}

function getBroadcastDisplayStatus(status, user) {
  let s = status?.trim()?.toLowerCase()
  if (!user?.isSystemUser()) {
    switch (s) {
      case "published":
      case "queued":
      case "generated":
      case "imported":
      case "failed_import":
        return "awaiting_stats"
      default:
        return s
    }
  }
  else {
    return s
  }
}


export function getPctDelivered(broadcast, intl) {
  if (broadcast && broadcast.status && broadcast.adViews && broadcast.targetAdViews && ("STARTED" === broadcast.status)) {
    return intl.num(broadcast.adViews < broadcast.targetAdViews ? Math.min((broadcast.adViews + 0.0) / (broadcast.targetAdViews + 0.0) * 100, 99.0) : 99.0)
  }
  else {
    return null
  }
}

export function getClickActionOpts(tenant, intl) {
  log.debug("getClickActionOpts: tenant=", tenant)
  let opts = []
  opts.push({
    label: <><div>{intl.msg("broadcast_page_type_solv_page")}</div><small>{intl.msg("broadcast_page_type_solv_page_tooltip")}</small></>,
    value: "SOLV",
    displayLabel: intl.msg("broadcast_page_type_solv_page"),
  })
  if (tenant.homePage && tenant.homePage.trim().length > 0) {
    opts.push({
      label: <><div>{intl.msg("broadcast_page_type_home_page")}</div><small>{intl.msg("broadcast_page_type_home_page_tooltip", {url: tenant.homePage})}</small></>,
      value: "HOME",
      displayLabel: intl.msg("broadcast_page_type_home_page"),
    })
  }
  if (tenant.clickUrls && tenant.clickUrls.length > 0) {
    tenant.clickUrls.forEach((clickUrl, index) => {
      opts.push({
        label: <><div>{intl.msg("broadcast_page_type_tenant_page", {name: clickUrl.name})}</div><small>{intl.msg("broadcast_page_type_tenant_page_tooltip", {url: clickUrl.url})}</small></>,
        value: clickUrl.id,
        displayLabel: intl.msg("broadcast_page_type_tenant_page", {name: clickUrl.name}),
      })
    })
  }
  opts.push({
    label: <><div>{intl.msg("broadcast_page_type_external_page")}</div><small>{intl.msg("broadcast_page_type_external_page_tooltip")}</small></>,
    value: "EXTERNAL",
    displayLabel: intl.msg("broadcast_page_type_external_page"),
  })
  opts.push({
    label: <><div>{intl.msg("broadcast_page_type_whatsapp_chat")}</div><small>{intl.msg("broadcast_page_type_whatsapp_chat_tooltip")}</small></>,
    value: "WHATSAPP",
    displayLabel: intl.msg("broadcast_page_type_whatsapp_chat"),
  })
  return opts
}

export function useBroadcastClickActionOpts(tenant) {
  const {intl} = useIntlEx()
  const broadcastClickActionOpts = []
  broadcastClickActionOpts.push({
    label: <><div>{intl.msg("broadcast_page_type_solv_page")}</div><small>{intl.msg("broadcast_page_type_solv_page_tooltip")}</small></>,
    value: "SOLV",
    displayLabel: intl.msg("broadcast_page_type_solv_page"),
  })
  if (tenant.homePage && tenant.homePage.trim().length > 0) {
    broadcastClickActionOpts.push({
      label: <><div>{intl.msg("broadcast_page_type_home_page")}</div><small>{intl.msg("broadcast_page_type_home_page_tooltip", {url: tenant.homePage})}</small></>,
      value: "HOME",
      displayLabel: intl.msg("broadcast_page_type_home_page"),
    })
  }
  if (tenant.clickUrls && tenant.clickUrls.length > 0) {
    tenant.clickUrls.forEach((clickUrl) => {
      broadcastClickActionOpts.push({
        label: <><div>{intl.msg("broadcast_page_type_tenant_page", {name: clickUrl.name})}</div><small>{intl.msg("broadcast_page_type_tenant_page_tooltip", {url: clickUrl.url})}</small></>,
        value: clickUrl.id,
        displayLabel: intl.msg("broadcast_page_type_tenant_page"),
      })
    })
  }
  broadcastClickActionOpts.push({
    label: <><div>{intl.msg("broadcast_page_type_external_page")}</div><small>{intl.msg("broadcast_page_type_external_page_tooltip")}</small></>,
    value: "EXTERNAL",
    displayLabel: intl.msg("broadcast_page_type_external_page"),
  })
  broadcastClickActionOpts.push({
    label: <><div>{intl.msg("broadcast_page_type_whatsapp_chat")}</div><small>{intl.msg("broadcast_page_type_whatsapp_chat_tooltip")}</small></>,
    value: "WHATSAPP",
    displayLabel: intl.msg("broadcast_page_type_whatsapp_chat"),
  })
  function findBroadcastClickActionOpt(clickAction) {
    if (!clickAction) {
      return null
    }
    const v = clickAction.toUpperCase()
    return broadcastClickActionOpts.find(o => o.value.toUpperCase() === v)
  }
  return ({
    broadcastClickActionOpts,
    findBroadcastClickActionOpt
  })
}

export function useBroadcastStatusOpts(props) {
  const {session:{user}} = useSolv()
  const {intl} = useIntlEx()
  let broadcastStatusOpts = [
    {
      value: "DRAFT",
      label: intl.msg("status_broadcast_draft"),
    },
  ]
  if (user?.isSystemUser()) {
    broadcastStatusOpts.push(
      {
        value: "PUBLISHED",
        label: intl.msg("status_broadcast_published"),
      }
    )
    broadcastStatusOpts.push(
      {
        value: "IMPORTED",
        label: intl.msg("status_broadcast_imported"),
      }
    )
    broadcastStatusOpts.push(
      {
        value: "FAILED_IMPORT",
        label: intl.msg("status_broadcast_failed_import"),
      }
    )
  }
  else {
    broadcastStatusOpts.push(
      {
        value: "PUBLISHED\tIMPORTED\tFAILED_IMPORT",
        label: intl.msg("status_broadcast_awaiting_stats"),
      }
    )
  }

  broadcastStatusOpts.push({
    value: "STARTED",
    label: intl.msg("status_broadcast_started"),
  })

  broadcastStatusOpts.push({
    value: "ENDED",
    label: intl.msg("status_broadcast_ended"),
  })

  function findBroadcastStatusOpt(status) {
    if (!status) {
      return null
    }
    const st = status.toUpperCase()
    return broadcastStatusOpts.find(i => i.value?.toUpperCase() === st)
  }

  return {
    broadcastStatusOpts,
    findBroadcastStatusOpt
  }
}

export function getClickActionOpt(clickAction, tenant, intl) {
  return getClickActionOpts(tenant, intl).find(i => i.value === clickAction)
}

export function getWhatsAppPhoneNoFromPageUrl(url) {
  try {
    const u = new URL(url)
    return u.pathname.replace(/^\/*/, '').trim()
  }
  catch (err) {
    return null
  }
}

export function getPageUrlFromWhatsAppPhoneNo(whatsAppPhoneNo) {
  return `https://wa.me/${encodeURIComponent(whatsAppPhoneNo)}`
}

export function getTenantPredefinedClickUrl(tenant, id) {
  return tenant.clickUrls.find(i => i.id === id)?.url
}

// export function BroadcastIssue(props) {
//
//   const {brandId} = useSolv()
//   const {intl} = useIntlEx()
//   const markdown = new Markdown(null, null, null, null, true)
//
//   return (
//       (props.issue) ? (
//           <>
//             <Alert type="warning" title={intl.msg(`broadcast_issue_${props.issue?.type?.toLowerCase()}_title`, {brandName: intl.msg(`label_${brandId.toLowerCase()}`)})}>
//               {
//                 props.issue?.description &&
//                   <>
//                     <hr/>
//                     <p>
//                       <div dangerouslySetInnerHTML={{__html: markdown.toHtml(props.issue.description)}}/>
//                     </p>
//                   </>
//               }
//               {
//                 props.canRefund &&
//                   <p>{intl.msg(`broadcast_issue_${props.issue?.type?.toLowerCase()}_actions`, {canRefund: props.canRefund})}</p>
//               }
//             </Alert>
//           </>
//       ) : (
//           <></>
//       )
//   )
// }

export function getAdSystemName(adSystemId) {
  if ("AMOBEE" === adSystemId) {
    return ("Nexxen")
  }
  else {
    return adSystemId
  }
}
