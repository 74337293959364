import React from "react";
import MicrosoftLogin from "react-microsoft-login"
import {useIntlEx} from "./IntlUtils"
import {useSolv} from "./SolvProvider"
import getLogger from "../components/Logging.js"
import "../App.css"

const log = getLogger("MicrosoftButton")

export function MicrosoftButton(props) {

  const {env} = useSolv()
  const {intl} = useIntlEx()

  async function handleResponse(err, data) {
    if (err) {
      props.onError(err)
    }
    else {
      props.onSuccess(data)
    }
  }

  return (
    env.MICROSOFT_CLIENT_ID &&
      <div>
        <MicrosoftLogin
          clientId={env.MICROSOFT_CLIENT_ID}
          authCallback={handleResponse}
          redirectUri={`${env.BASE_URL}/authcb/ms/`}
          graphScopes={["user.read"]}
          forceRedirectStrategy={false}
        >
        <div style={{cursor: "pointer", width: "210px", height: "44px", backgroundColor: "rgb(60, 60, 60)", display: "inline-flex", alignItems: "center", color: "rgb(255, 255, 255)", boxShadow: "rgba(0, 0, 0, 0.24) 0px 2px 2px 0px, rgba(0, 0, 0, 0.24) 0px 0px 1px 0px", padding: "0px", borderRadius: "5px", border: "1px solid transparent", fontSize: "14px", fontWeight: "500", fontFamily: "Roboto, sans-serif"}}>
          <div style={{display: "flex", alignItems: "center", marginRight: "0px", background: "rgb(60, 60, 60)", padding: "10px", borderRadius: "2px", width: "45px", height: "42px"}}>
            <img src="/images/ms-symbollockup_mssymbol_19.png" style={{width: "18px", height: "auto", padding: "0"}}/>
          </div>
          <span style={{padding: "10px 10px 10px 0px", fontWeight: "700"}}>{props.buttonText}</span>
        </div>
        </MicrosoftLogin>
      </div>
  )
}