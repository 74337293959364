import React from "react"
import getLogger from "../components/Logging"
const log = getLogger("ReactUtils")

export function simpleReducer(state, action) {
    // log.debug("simpleReducer 1: status=, action=", state, action)
    state = {...state, ...action}
    // log.debug("simpleReducer 2: status=, action=", state, action)
    return state
}

export function booleanPropIsTrue(prop) {
    switch (typeof(prop)) {
        case "boolean":
            // log.debug("booleanPropIsTrue: 1: ", prop === true)
            return (prop === true)
        case "string":
            switch (prop.toUpperCase()) {
                case "TRUE":
                    // log.debug("booleanPropIsTrue: 2: ", true)
                    return true
                case "YES":
                    // log.debug("booleanPropIsTrue: 3: ", true)
                    return true
            }
    }
    // log.debug("booleanPropIsTrue: 4:", false)
    return false
}