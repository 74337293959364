import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import React from "react";
import {useIntlEx} from "./IntlUtils";

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

const dateFormat = Intl.DateTimeFormat("en-GB", { dateStyle: "medium", timeStyle: "long" })

export function describeDateTime(d) {
    if (d) {
        return dayjs(d).fromNow() + ` (${dateFormat.format(d)})`
    }
    else {
        return ""
    }
}

export function HumanTimestamp(props) {
  const {intl} = useIntlEx()
  return (
    props.timestamp || props.time ? (
      <a href="#" className="display-only" title={intl.dateTime(props.timestamp || props.time)}>
        {intl.fmNowRel(props.timestamp || props.time)}
      </a>
    ) : (
      <>
        {props.else}
      </>
    )
  )
}