import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { Alert } from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {clearAllErrors, InfoBlock, InfoText, validateField} from "./components/ValidationUtils";
import NumericInput from "react-numeric-input";
import {default as Select} from "react-select";

const log = getLogger("BroadcastRefundDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function BroadcastRefundDialog(props)  {

  const { api, setBusy } = useSolv()
  const { intl } = useIntlEx()

  const [tenant, setTenant] = useState(null)
  const [broadcastId, setBroadcastId] = useState(null)
  const [broadcastName, setBroadcastName] = useState(null)
  const [maxCredits, setMaxCredits] = useState(0)

  const [credits, setCredits] = useState(null)
  const [reason, setReason] = useState(null)
  const [reasonOpts, setReasonOpts] = useState(null)

  const [alert, setAlert] = useState(null)
  const [touched, setTouched] = useState(null)

  useEffect(() => {
    const opts =[
      {
        value: "FAILED_DELIVERY",
        label: "Failed delivery",
      },
      {
        value: "UNDER_DELIVERY",
        label: "Under delivery",
      },
    ]
    setReasonOpts(opts)
    setReason(opts.find(i => i.value === "UNDER_DELIVERY"))
  }, [])

  useEffect(() => {
    if (props) {
      log.debug("useEffect: props=", props)
      if (props.tenant) {
        setTenant(props.tenant)
      }
      if (props.broadcastId) {
        setBroadcastName(props.broadcastId)
      }
      if (props.broadcastName) {
        setBroadcastName(props.broadcastName)
      }
      if (props.maxCredits) {
        setMaxCredits(props.maxCredits)
        setCredits(props.maxCredits)
      }
    }
  }, [props])

  function validate() {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    validateField("inp_credits", credits && credits <= maxCredits, "valid", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }
  function handleRefundClick() {
    log.debug("handleDeleteClick: invoked")
    setBusy("Deleting...")
    if (validate()) {
      try {
        const payload = {
          credits: credits,
          reason: reason.value,
        }
        api.refundBroadcast(tenant.tenantId, props.broadcastId, payload)
          .then((data) => {
            window.location.reload()
          })
          .catch((error) => {
            setAlert({error: intl.msg("broadcast_refund_failed")})
          })
          .finally(() => {
            setBusy(null)
          })
      }
      catch (error) {
        log.error("Error!!!!", error)
      }
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }
  }

  function handleCreditsChange(e) {
    setCredits(e)
    setTouched(true)
  }

  function handleReasonChange(v) {
    setReason(v)
    setTouched(true)
  }

  function handleOpenDialog() {
    setTouched(false)
  }

  function handleCloseDialog() {
    closeDialog("dlg_broadcast_refund")
  }

  return (

      <ModalDialog
        dialogId="dlg_broadcast_refund"
        header={<h1>{intl.msg("broadcast_refund_title", {broadcastName: broadcastName})}</h1>}
        size="md"
        keyboard={"true"}
        backdrop={props.backdrop || "static"}
        alert={alert}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
        footer={
          <>
            <button className="btn btn-primary" onClick={handleRefundClick}>{intl.msg("refund")}</button>
            <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
          </>
        }>
        <div className="row">
          <div className="col">
            {/*<center>*/}
            {/*  {*/}
            {/*    !alert &&*/}
            {/*      <div className="alert alert-warning mb-0">*/}
            {/*        <i className="fas fa-exclamation-triangle mr-2"></i>*/}
            {/*        {intl.msg("broadcast_delete_text_1", {broadcastName: props.broadcastName})}*/}
            {/*      </div>*/}
            {/*  }*/}
            {/*</center>*/}
            <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor="inp_credits">{intl.msg("broadcast_refund_credits")}</label>
              <div className="col-sm-9">
                <div style={{maxWidth: "200px"}}>
                  <NumericInput id="inp_credits" className="form-control" style={false} min={0} precision={0} disabled={false} value={credits} onChange={handleCreditsChange}/>
                </div>
                <InfoBlock>
                  <InfoText>{intl.msg("broadcast_refund_credits_description")}</InfoText>
                  <InfoText validate="valid">{intl.msg("broadcast_refund_credits_helptext_valid", {maxCredits: maxCredits})}</InfoText>
                </InfoBlock>
              </div>
            </div>
            <div className="form-group row">
              <label className="col-sm-3 col-form-label" htmlFor="inp_reason">{intl.msg("broadcast_refund_reason")}</label>
              <div className="col-sm-9">
                <Select
                    id="inp_credits_topup_reason"
                    className="react-select"
                    classNamePrefix="react-select"
                    value={reason}
                    options={reasonOpts}
                    onChange={handleReasonChange}
                    isDisabled={false}/>
                <InfoBlock>
                  <InfoText>{intl.msg("broadcast_refund_reason_description")}</InfoText>
                </InfoBlock>
              </div>
            </div>
          </div>
        </div>
      </ModalDialog>
  )
}