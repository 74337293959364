import React, {useEffect, useState} from "react"
import {FormHeader} from "./components/FormComps"
import {useIntlEx} from "./components/IntlUtils";
import {useLocation} from "react-router-dom";
import getLogger from "./components/Logging.js"
import {uuidRegex} from "./components/CommonUtils";
import './App.css'

const log = getLogger("AccountTabs")

// const idRegex =  /[0-9A-Fa-f]{10,}/g

export default function AccountTabs(props) {

  const [tenant, setTenant] = useState(null)
  const {intl } = useIntlEx()

  const location = useLocation()

  const [tabs, setTabs] = useState(null)
  const [currentTab, setCurrentTab] = useState(null)

  const cmp = location.pathname.split("/").filter(i => i.trim().length > 0)
  const currentPage = cmp.findLast(i => !i.match(uuidRegex) && !i.startsWith("_"))

  useEffect(() => {
    log.debug("useEffect: invoked: currentPage=", currentPage)
    if (props && props.tenant) {

      const t = props.tenant
      log.debug("useEffect: tenant=", t)

      setTenant(t)

      const tabs = []

      if (!t.accessingAs("*/MEMBER/*")) {

        const tenantId = t.tenantId?.replace(/\-/g, "")

        tabs.push({
          id: "account",
          href: `/${tenantId}`
        })

        if (t.accessingAs("*/MANAGER/*") && (t.status !== "INVITING")) {


          if (t.isReseller()) {

            tabs.push({
              id: "clients",
              href: `/${tenantId}/clients`
            })

            if (t.hasSubTenants()) {
              tabs.push({
                id: "resellers",
                href: `/${tenantId}/resellers`
              })
            }

          }

          tabs.push({
            id: "broadcasts",
            href: `/${tenantId}/broadcasts`
          })

          // tabs.push({
          //   id: "responses",
          //   href: `/${tenantId}/responses`
          // })
          //
          // tabs.push({
          //   id: "templates",
          //   href: `/${tenantId}/templates`
          // })

          tabs.push({
            id: "members",
            href: `/${tenantId}/members`
          })

          if (t.isClient()) {
            tabs.push({
              id: "credits",
              href: `/${tenantId}/credits`
            })
          }

          if (t.isReseller() || t.isSystem()) {
            tabs.push({
              id: "refcodes",
              href: `/${tenantId}/refcodes`
            })
          }

          // tabs.push({
          //   id: "income",
          //   href: `/${tenantId}/income`
          // })
        }

        setTabs(tabs)
        setCurrentTab(tabs.find(i => i.id === currentPage) || tabs[0])

      }

    }
  }, [props])

  log.debug("cmp=, currentPage=, currentTab=", cmp, currentPage, currentTab)

  function Tab({tab}) {
    const href = `/${cmp[0]}/${cmp[1]}${tab.href}`
    let title = intl.msg(tab.id)
    return (
      <>
        <FormHeader.Tab id={tab.id} href={tab.href} title={title} active={currentTab.id === tab.id}/>
      </>
    )
  }

  return (
    tenant && tabs && tabs.length > 0 &&
      <FormHeader.Tabs title={tenant.tenantName}>
        {
          tenant.status !== "INVITING" &&
            tabs.map(tab => {
              return <Tab tab={tab}/>
            })
        }
      </FormHeader.Tabs>
  )
}

export function spliTenantUrl(location) {
  if (location && location.pathname) {
    const cmp = location.pathname.split("/").filter(i => i.trim().length > 0)
    const currentPage = cmp.findLast(i => !i.match(uuidRegex) && !i.startsWith("_"))
    return {
      components: cmp,
      currentPage: currentPage
    }
  }
}

export function replaceTenantUrlDocId(location, replacement) {
  if (location && location.pathname) {
    const cmp = location.pathname.split("/").filter(i => i.trim().length > 0)
    const lastCmp = cmp.at(-1)
    if (!replacement) {
      replacement = ""
    }
    if (replacement.length > 0) {
      replacement = `/${replacement}`
    }
    if (lastCmp.match(uuidRegex) || lastCmp.startsWith("_")) {
      return "/" + cmp.slice(0, -1).join("/") + replacement
    }
  }
  return location.pathname
}

export function mkAccountDocUrl(user, tenant, docType, docId) {
  let suffix = `/${docType}${docId ? `/${docId}` : ""}`
  log.debug("mkAccountDocUrl: user=, tenant=", user, tenant)
  if (user && tenant) {
    if (user.tenantId?.replace(/\-/g, "") === tenant.tenantId?.replace(/\-/g, "")) {
      return suffix
    }
    else {
      return `/${tenant.tenantId}${suffix}`
    }
  }
  else {
    return suffix
  }
}

export function AccountLink(props) {
  const url = mkAccountDocUrl(props.user, props.tenant, props.docType, props.docId)
  return (
    <a className="text-link" href={url}>{props.children}</a>
  )
}