import React, {useState, useEffect, useRef, forwardRef, useImperativeHandle} from "react"
import { useIntlEx } from "./IntlUtils.js"
import { LOCALES } from "../i18n/locales.js"
import getLogger from "./Logging.js"
import styles from "./I18nInput.module.css"
import useStateRef from "react-usestateref";

const log = getLogger("I18nInput")

export const I18nInput = forwardRef((props, ref)  => {

  const { intl } = useIntlEx()

  // log.debug(">>>Z: init: props=", props, typeof(props.values) === "object" ? props.values.hasOwnProperty(props.lang || "en") ? props.values[props.lang || "en"] : "" : "")

  // const [lang, setLang, langRef] = useStateRef(props.lang || "en")
  // const [values, setValues, valuesRef] = useStateRef(props.values)
  // const [value, setValue, valueRef] = useStateRef(typeof(props.values) === "object" ? props.values.hasOwnProperty(props.lang || "en") ? props.values[props.lang || "en"] : "X1" : "X2")

  const [value, setValue, valueRef] = useStateRef(props.value)
  const [lang, setLang, langRef] = useStateRef(props.value?.lang || "en")

  const inputRef = useRef()

  useEffect(() => {
    log.debug(">>>Z: useEffect: props=", props)
    // if (props.lang && props.values) {
    //   setLang(props.lang)
    //   setValues(props.values)
    //   // setValue(typeof (props.values) === "object" ? props.values.hasOwnProperty(props.lang || "en") ? props.values[props.lang || "en"] : "X1" : "X2")
    // }
    // if (props.value) {
    //   // setValue(props.value)
    //   setLang(props.value.lang || "en")
    // }
    setValue(props.value)
  }, [props]);

  function getValue() {
    log.debug(">>>Z: getValue: ", valueRef.current)
    const l = langRef.current || "en"
    if (valueRef.current && valueRef.current.hasOwnProperty(l)) {
      return valueRef.current[l]
    }
    else {
      return ""
    }
  }

  useImperativeHandle(ref, () => ({
    setValue(value) {
      // handleValuesChange({target:{value: values}})
      // fireEvents({target:{value: values}})
    }
  }));

  function fireEvents(e) {
    if (props.onChange) {
      props.onChange(e)
    }
  }

  function handleValueChange(e) {
    log.debug(">>>Z: handleValueClick: ", e.target.value)
    if (typeof(props.onChange) === "function") {
      props.onChange({...value, [lang]: e.target.value})
    }
  }

  function handleLangClick(l) {
    log.debug(">>>Z: handleLangClick: ", l)
    setLang(l)
    // if (typeof(props.onLangChange) === "function") {
    //   props.onChange(l)
    // }
    inputRef.current.focus()
  }

  return (
    <>
      <div className={styles.container} style={{width: props.width ? props.width : "auto"}}>
        <input ref={inputRef} id={props.id} className={`${styles.input} ${props.className ? props.className : ""}`} key={props.key} type="text" placeholder={props.placeholder} value={getValue()} onChange={handleValueChange}/>
        <div className={styles.langs} style={{width: "100%", whiteSpace: "nowrap", height: "36px", maxHeight: "36px", overflow: "scroll"}}>
          {
            Object.keys(LOCALES).map ((l) => {
              return (
                // <div style={{position: "relative"}}>
                  <div className={`${styles.lang} ${l === lang ? styles.selected : ""} ${value?.hasOwnProperty(l) ? styles.filled : ""}`}>
                    <button type="button" className={`${styles.lang} ${l === lang ? styles.selected : ""} ${value?.hasOwnProperty(l) ? styles.filled : ""}`} onClick={() => handleLangClick(l)}>{l.toLowerCase()}</button>
                  </div>
                // </div>
              )
            })}
        </div>
      </div>

    </>
  )

})

export function I18nName(name, lang) {
  let n = ""
  if (name) {
    if (name.hasOwnProperty(lang)) {
      n = n[lang]
    }
    else if (name.hasOwnProperty("en")) {
      n = n["en"]
    }
  }
  return (
    <>{n}</>
  )
}