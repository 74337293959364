import React, {useEffect, useState} from "react"
import {useLocation} from "react-router-dom";
import {FormHeader} from "../components/FormComps"
import {useIntlEx} from "../components/IntlUtils";
import getLogger from "../components/Logging.js"
import {uuidRegex} from "../components/CommonUtils";
import '../App.css'

const log = getLogger("SystemTabs")

const countryCodeRegex =  /[A-Z][A-Z]/g

export default function SystemTabs(props) {

  const {intl } = useIntlEx()

  const location = useLocation()

  const [tabs, setTabs] = useState(null)
  const [currentTab, setCurrentTab] = useState(null)

  const cmp = location.pathname.split("/").filter(i => i.trim().length > 0)
  const currentPage = cmp.findLast(i => !i.match(uuidRegex) && !i.startsWith("_") && !i.match(countryCodeRegex))

  let locationPat = /^\/sys\/regions\/([A-Za-z][A-Za-z])\/locations(\/?.*)/

  useEffect(() => {

    log.debug("useEffect: invoked")

    const tabs = []

    tabs.push({
      id: "status",
      href: `/sys`
    })

    // tabs.push({
    //   id: "represellers",
    //   href: `/sys/represellers`
    // })

    tabs.push({
      id: "regions",
      href: `/sys/regions`
    })

    const pathname = window.location.pathname
    const mat = pathname.match(locationPat)
    log.debug("pathname: mat=", mat)
    if (mat && mat.length > 1) {
      tabs.push({
        id: "locations",
        href: `/sys/regions/${mat[1]}/locations`
      })
    }
    else {
      tabs.push({
        id: "locations",
        href: `/sys/regions/AU/locations`
      })
    }

    tabs.push({
      id: "industries",
      href: `/sys/industries`
    })

    tabs.push({
      id: "currencies",
      href: `/sys/currencies`
    })

    setTabs(tabs)
    setCurrentTab(tabs.find(i => i.id === currentPage) || tabs[0])

  }, [props])

  log.debug("cmp=, currentPage=, currentTab=", cmp, currentPage, currentTab)

  function Tab({tab}) {
    const href = `/${cmp[0]}/${cmp[1]}${tab.href}`
    let title = intl.msg(tab.id)
    return (
      <>
        <FormHeader.Tab id={tab.id} href={tab.href} title={title} active={currentTab.id === tab.id}/>
      </>
    )
  }

  return (
      tabs && tabs.length > 0 &&
      <FormHeader.Tabs title={intl.msg("system")}>
        {
          tabs.map(tab => {
            return <Tab tab={tab}/>
          })
        }
      </FormHeader.Tabs>
  )
}
