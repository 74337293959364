import React, { Fragment, useState, useRef, useEffect, useLayoutEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import {useSolv, useTenant} from "../components/SolvProvider"
import { useIntlEx } from "../components/IntlUtils.js"
import {useLocation, useParams} from "react-router-dom";
import useQueryString from "use-query-string";
import {FormBody, FormHeader, MainContainer, useAlert} from "../components/FormComps";
import {ImageDropBox, processImageFile} from "../components/ImageUtils";
import {clearAllErrors, clearError, InfoBlock, InfoText, validateField, validateUrl} from "../components/ValidationUtils";
import ImageDialog from "../components/ImageDialog";
import AccountTabs from "../AccountTabs";
import ExpressPublishMenu from "./ExpressPublishMenu";
import getLogger from "../components/Logging.js"
import "../App.css"
import {default as Select} from "react-select";
import {getClickActionOpt, getClickActionOpts} from "../components/BroadcastUtils";
import {AsyncPaginate, reduceGroupedOptions} from "react-select-async-paginate";
import NumericInput from "react-numeric-input";
import {BroadcastPublishDialog} from "../BroadcastPublishDialog";
import {openDialog, openDialogCurried} from "../components/DialogUtils";
import {isBlankString} from "../components/StringUtils";

const log = getLogger("ExpressPublish")

const MAX_LOCATION_COUNT = 10

export default function ExpressPublish() {

  const {api, auth, session, env, refreshSignin, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const location = useLocation()
  const params = useParams()
  const [queryString] = useQueryString(location)

  const {tenant} = useTenant(params.tenantId)
  const [alert, setAlert] = useAlert()

  const [touched, setTouched] = useState(null)

  const broadcastTimeOpts = [
    {
      value: "ASAP", label: intl.msg("broadcast_duration_asap")
    },
    {
      value: "3DAYS", label: intl.msg("broadcast_duration_3_days")
    },
    {
      value: "5DAYS", label: intl.msg("broadcast_duration_5_days")
    },
    {
      value: "1WEEKS", label: intl.msg("broadcast_duration_1_weeks")
    },
    {
      value: "2WEEKS", label: intl.msg("broadcast_duration_2_weeks")
    },
    {
      value: "3WEEKS", label: intl.msg("broadcast_duration_3_weeks")
    },
    {
      value: "4WEEKS", label: intl.msg("broadcast_duration_4_weeks")
    }
  ]

  function findOptByValue(opts, value, defaultValue) {
    if (opts && value) {
      const v = opts.find(a => a.value === value)
      return v ? v : defaultValue
    }
    else {
      return defaultValue
    }
  }

  const [broadcastId, setBroadcastId] = useState(null)
  const [broadcastName, setBroadcastName] = useState(null)
  const [creativeType, setCreativeType] = useState("IMAGE")
  const [creativeImageUrl, setCreativeImageUrl] = useState(null)
  const [creativeImageInfo, setCreativeImageInfo] = useState(null)
  const [clickAction, setClickAction] = useState()
  const [pageType, setPageType] = useState(null)
  const [pageUrl, setPageUrl] = useState(null)
  const [whatsAppPhoneNo, setWhatsAppPhoneNo] = useState(null)
  const [targetBudget, setTargetBudget] = useState(1)
  const [targetLocations, setTargetLocations] = useState(null)
  const [targetStartTime, setTargetStartTime] = useState(findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0]))
  const [targetEndTime, setTargetEndTime] = useState(findOptByValue(broadcastTimeOpts, "ASAP", broadcastTimeOpts[0]))
  const [targetAdViews, setTargetAdViews] = useState(null)
  const [broadcastUsdMultiplier, setBroadcastUsdMultiplier] = useState(null)
  const [popEstimations, setPopEstimations] = useState({totalPop: -1, popReached: -1, popReachedPct: -1, budgetToReach100Pct: -1})

  const creativeImagePickerDialogRef = useRef(null)

  // useEffect(() => {
  //
  //   if (!auth.isAuthenticated) {
  //     log.debug("useEffect: Unauthenticated")
  //     setBusy(intl.msg("working"))
  //     const {signature, ...query} = queryString
  //
  //     const payload = {
  //       idProvider: "SIGNED_REQUEST",
  //       apiKey: params.apiKey,
  //       urlPath: location.pathname,
  //       urlQuery: query,
  //       signature: signature
  //     }
  //
  //     log.debug("useEffect: signin: payload=", payload)
  //
  //     auth.signin(payload)
  //       .then(({data}) => {
  //         log.debug("signin: res=", data)
  //         window.location.reload()
  //       })
  //       .catch((err) => {
  //       })
  //       .finally(() => {
  //         setBusy(null)
  //       })
  //   }
  //   else {
  //     log.debug("useEffect: Authenticated")
  //   }
  // }, []);

  useEffect(() => {
    log.debug("useEffect: invoked")
  }, [])

  useEffect(() => {
    log.debug("Tenant updated: tenant=", tenant)
    if (tenant) {
      handleClickActionChange(getClickActionOpt("EXTERNAL", tenant, intl))
      api.createUuid()
        .then(({data}) => {
          log.debug("createBroadcastId: data=", data)
          setBroadcastId(data.uuid)
        })
        .catch((err) => {
          setFatal(err)
        })
    }
  }, [tenant])

  useEffect(() => {
    calcPopEstimations()
  },[tenant, creativeType, targetBudget, targetLocations, tenant, broadcastUsdMultiplier])

  function calcPopEstimations() {

    if (tenant) {
      let ttlPop = 0

      let locations = []
      if (targetLocations) {
        locations = targetLocations
      }

      locations.forEach(l => ttlPop = ttlPop + (l.pop > 0 ? l.pop : (l.pop < 0) ? 0 : 1000))

      log.debug("calcPopEstimations: tenant=", tenant)

      const usdMultiplier = broadcastUsdMultiplier ? broadcastUsdMultiplier : (tenant.region.currency.usdMultiplier ? tenant.region.currency.usdMultiplier : 1.00)

      log.debug("calcPopEstimations: usdMultiplier=", usdMultiplier)

      const adSystemProps = tenant.props?.adSystem ? tenant.props.adSystem["AMOBEE"] : null

      log.debug("calcPopEstimations: adSystemProps=", adSystemProps)

      const cpm = adSystemProps ? (adSystemProps["cpms"] ? adSystemProps["cpms"][creativeType] : 1.0) : 1.0

      log.debug("calcPopEstimations: cpm=", cpm)

      const impressionsPerUsd = 1000 / cpm

      log.debug("cpm=, impressionsPerUsd=", cpm, impressionsPerUsd)

      let ttlImpressions = Math.trunc(targetBudget * usdMultiplier / cpm * 1000)
      let pctReach = ttlImpressions / ttlPop * 100
      let budgetToReach100Pct = ttlPop / impressionsPerUsd / usdMultiplier

      const r = {
        ttlPop: ttlPop,
        ttlImpressions: ttlImpressions,
        pctReach: pctReach,
        budgetToReach100Pct: budgetToReach100Pct
      }
      setPopEstimations(r)

      setTargetAdViews(ttlImpressions)

      log.debug("calcPopEstimations: r=", r)
    }
  }

  async function handleCreativeImageUrlChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      let file = e.target.files[0]
      let result

      setBusy(intl.msg("processing"))

      try {
        result = await processImageFile({
          file: file,
          maxFileSize: "IMAGE" === creativeType ? 1_000_000 : 1_000_000,
          width: "IMAGE" === creativeType ? 300 : 124,
          height: "IMAGE" === creativeType ? 250 : 164,
        })
      }
      catch (ex) {
        setAlert({type: "error", text: intl.msg("error_image_process_failed")})
      }
      finally {
        setBusy(null)
      }

      if (result.error) {
        setCreativeImageInfo(result)
        const el = document.getElementById("inp_dlg_creativeImagePicker")
        el.click()
      }
      else {
        setCreativeImageUrl(result.dataUrl)
        setTouched(true)
      }

      clearError("inp_creativePhoto")
    }
  }

  function handleCreativePhotoClear() {
    setCreativeImageUrl(null)
    setTouched(true)
  }

  function handleClickActionChange(v) {
    setClickAction(v)
    setPageType(v.value)
    setWhatsAppPhoneNo(null)
    setPageUrl(null)
    setTouched(true)
  }

  function handleWatsAppPhoneNoChange(e) {
    let value = e?.target?.value
    if (value) {
      value = value.replace(/[^0-9]/g, "")
    }
    setWhatsAppPhoneNo(value)
    setPageUrl(`https://wa.me/${encodeURIComponent(value)}`)
  }

  function handleExtPageUrlChange(e) {
    const url = e.target.value
    setPageUrl(url)
    setTouched(true)
    // if (validateUrl(url, {relaxed: true, allowedDomains: tenant.allowedDomains})) {
    //   setExtPageLoading("LOADING")
    // }
  }

  function addExtPageUrlHttps() {
    if (pageUrl && pageUrl.trim().length > 0) {
      try {
        if (pageUrl.startsWith("http://")) {
          setPageUrl((prev) => `https://${prev.substring(7)}`)
        }
        else if (!pageUrl.startsWith("https://")) {
          setPageUrl((prev) => `https://${prev}`)
        }
      }
      catch (e) {
      }
    }
  }

  function handleExternalPageUrlBlur() {
    addExtPageUrlHttps()
  }

  function handleExternalPageUrlKeyDown(e) {
    if (e.key === "Enter") {
      addExtPageUrlHttps()
    }
  }

  function loadTargetLocations(search, loadedOptions, additional) { //, loadedOptions, { cursor }) {

    log.debug("loadTargetLocations:", search, loadedOptions, additional)

    return api.listLocationsByRegion(tenant.regionId, search, "BASIC", "displayName:ASC", additional?.cursor, 50)
        .then(({data, nextCursor}) => {
          log.debug("loadTargetLocations: data=", data)
          let opt = data.map(c => {
            return {
              label: c.ttdType,
              options: [
                {
                  value: c.locationId,
                  label: c.displayName,
                  pop: c.pop
                }
              ],
            }
          })
          let res = {
            options: opt,
          }
          if (nextCursor) {
            res = {
              ...res,
              hasMore: true,
              additional: {
                cursor: nextCursor
              }
            }
          }
          log.debug("loadTargetLocations: res=", res)
          return res
        })
        .catch((error) => {
          log.debug("Error loading location", error)
        })
  }

  function handleTargetBudgetChange(value) {
    setTargetBudget(value)
    setTouched(true)
  }

  function handleTargetLocationsChange(value) {
    log.debug("handleTargetLocationsChange: value=", value)
    setTargetLocations(value)
    setTouched(true)
  }

  function handleTargetEndTimeChange(v) {
    setTargetEndTime(v)
    setTouched(true)
  }

  function handlePublishClick(e) {
    if (validate(e)) {
      log.debug("handlePublish: validated")
      openDialog("dlg_publish")
    }
    else {
      setAlert({error: intl.msg("error_invalid_form")})
    }
  }

  function validate(e) {

    let opt = {focusEl: null, valid: true}

    clearAllErrors("frmMain")

    // if (isAddMode) {
    //   validateField("inp_broadcastTemplate", broadcastTemplate, "required", opt)
    // }

    // validateField("inp_broadcastName", props.broadcastName && props.broadcastName.trim().length > 0, "required", opt)
    //
    // if ("SOLV" === creativeType) {
    //   validateField("inp_creativeHeader", props.creativeHeader && props.creativeHeader.trim().length > 0, "required", opt)
    //   validateField("inp_creativeTitle", props.creativeTitle && props.creativeTitle.trim().length > 0, "required", opt)
    //
    //   if (props.creativeFormFields) {
    //     props.creativeFormFields.forEach(f => {
    //       if ("REQUIRED" === f.input) {
    //         validateField(`inp_creativeForm_${f.label.replace(/[\W_]+/g,'')}`, !isBlankString(f.value), "required", opt)
    //       }
    //     })
    //   }
    //   else {
    //     opt.valid = false
    //   }
    //
    // }

    validateField("inp_creativePhoto", creativeImageUrl, "required", opt)

    switch (pageType) {
      // case "SOLV":
      //   validateField("inp_pageHeader", pageHeader && pageHeader.trim().length > 0, "required", opt)
      //   validateField("inp_pageTitle", pageTitle && pageTitle.trim().length > 0, "required", opt)
      //   validateField("inp_pagePhoto", pageImageUrl, "required", opt)
      //
      //   if (pageFormFields) {
      //     pageFormFields.forEach(f => {
      //       if ("REQUIRED" === f.input) {
      //         validateField(`inp_pageForm_${f.label.replace(/[\W_]+/g,'')}`, !isBlankString(f.value), "required", opt)
      //       }
      //     })
      //   }
      //   else {
      //     opt.valid = false
      //   }
      //   break

      case "EXTERNAL":
        if (pageUrl && pageUrl.trim().length > 0) {
          try {
            const url = new URL(pageUrl)
            if (tenant.allowedDomains && tenant.allowedDomains.length > 0) {
              const domain = url.hostname.trim().toUpperCase()
              if (!tenant.allowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
                validateField("inp_pageUrl", false, "whitelist", opt)
              }
            }
            if (tenant.disallowedDomains && tenant.disallowedDomains.length > 0) {
              const domain = url.hostname.trim().toUpperCase()
              if (tenant.disallowedDomains.find(u => domain.endsWith(u.trim().toUpperCase()))) {
                validateField("inp_pageUrl", false, "whitelist", opt)
              }
            }
          }
          catch (ex) {
            validateField("inp_pageUrl", false, "valid", opt)
          }
        }
        else {
          validateField("inp_pageUrl", false, "required", opt)
        }
        break

      case "WHATSAPP":
        if (whatsAppPhoneNo && whatsAppPhoneNo.trim().length > 0) {
          validateField("inp_whatsAppPhoneNo", validateUrl(pageUrl), "valid", opt)
        }
        else {
          validateField("inp_whatsAppPhoneNo", false, "valid", opt)
        }
        break

    }

    if (targetLocations) {
      if (targetLocations.length <= 0) {
        validateField("inp_targetLocations", false, "min-value", opt)
      }
      else if (targetLocations.length > MAX_LOCATION_COUNT) {
        validateField("inp_targetLocations", false, "max-value", opt)
      }
    }
    else {
      validateField("inp_targetLocations", false, "required", opt)
    }

    validateField("inp_targetBudget", targetBudget && targetBudget >= 1.00, "min-value", opt)

    log.debug("VALIDATE: opt=", opt)

    if (opt.focusEl) {
      log.debug("VALIDATE: focus")
      if (e) {
        e.preventDefault()
      }
      document.getElementById(opt.focusEl).focus()
    }

    return opt.valid
  }

  function mkDomainListText(allowedDomains) {
    if (allowedDomains.length > 1) {
      return (
          <>
            one of {
            allowedDomains.map((s, i) => {
              return (
                  <><code>{s}</code>{i < (allowedDomains.length - 1) ? ", " : ""}</>
              )
            })
          }
          </>
      )
    }
    else {
      return allowedDomains[0]
    }
  }

  function canEdit() {
    return true
  }

  function canPublish() {
    return tenant && broadcastId
  }

  return (
    (tenant && session) &&
      <>

        <MainContainer tenant={tenant} menu={ExpressPublishMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("broadcast")}
              </FormHeader.Toolbar.Title>

              <FormHeader.Toolbar.Controls>

                {
                  canPublish() &&
                    <button key="key_btn_publish" className="btn btn-primary" onClick={handlePublishClick}>
                      <i className="fas fa-paper-plane"></i>
                      {intl.msg("publish")}
                    </button>
                }

              </FormHeader.Toolbar.Controls>

            </FormHeader.Toolbar>

            <FormHeader.Alert alert={alert}/>

          </FormHeader>

          <FormBody>

           <form id="frmMain" autoComplete="off">

             <div className="row">

               <div className="col-sm-7">

                 <div className="form-card">

                   <div className={`form-group`}>
                     <label className="col-sm-3 col-form-label" htmlFor="inp_creativePhoto">{intl.msg("broadcast_message_custom_image_message_type_photo")}:</label>
                     <div className="col-sm-9">
                       <ImageDropBox
                           id="inp_creativePhoto"
                           image={creativeImageUrl}
                           imageClassName={"primary-photo-custom"}
                           width={300}
                           height={250}
                           onChange={handleCreativeImageUrlChange}
                           onClear={handleCreativePhotoClear}
                           disabled={!canEdit()}/>
                       <InfoBlock>
                         <InfoText>{intl.msg("broadcast_message_custom_image_message_type_photo_helptext_1")}</InfoText>
                         <InfoText disabled={!touched} validate="valid">{intl.msg("broadcast_message_custom_image_message_type_photo_helptext_valid")}</InfoText>
                         <InfoText disabled={!touched} validate="required">{intl.msg("helptext_required")}</InfoText>
                       </InfoBlock>
                     </div>
                   </div>

                   <div className="form-group">
                     <label className="col-sm-3 col-form-label col-form-label" htmlFor="inp_clickAction">
                       {intl.msg("broadcast_click_action")}:
                     </label>
                     <div className="col-sm-9">
                       <div className="d-flex flex-row justify-content-between gap-2 w-100">
                         <div className="w-100">
                           <Select
                               id="inp_clickAction"
                               className="react-select react-select__absolute"
                               classNamePrefix="react-select"
                               value={clickAction}
                               options={getClickActionOpts(tenant, intl, ["EXTERNAL", "HOME", "WHATSAPP"])}
                               onChange={handleClickActionChange}
                               isDisabled={!canEdit()}
                           />
                         </div>
                         {
                             "HOME" === pageType &&
                             <div key="key_div_homeUrl">
                               <button key="key_btn_homeUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to view this page" onClick={(e) => window.open(tenant.homePage)}><i className="fas fa-external-link-alt"></i></button>
                             </div>
                         }
                       </div>
                       <InfoBlock>
                         <InfoText>{intl.msg("broadcast_click_action_helptext_1")}</InfoText>
                       </InfoBlock>
                     </div>
                   </div>

                   {
                     "EXTERNAL" === pageType ? (
                         <>
                           <div className="form-group">
                             <label className="col-sm-3 col-form-label" htmlFor="inp-pageUrl">
                               {intl.msg("broadcast_page_solv_page_page_type_page_url")}:
                             </label>
                             <div className="col-sm-9">
                               <div className="d-flex gap-2">
                                 <input id="inp_pageUrl" name="pageUrl" type="text" className="form-control" value={pageUrl} disabled={!canEdit} onChange={handleExtPageUrlChange} onBlur={handleExternalPageUrlBlur} onKeyDown={handleExternalPageUrlKeyDown} autoComplete="no"/>
                                 <div key="key_div_externalUrl">
                                   <button key="key_btn_externalUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to view this page" disabled={!validateUrl(pageUrl, {relaxed: true})} onClick={(e) => window.open(pageUrl)}><i className="fas fa-external-link-alt"></i></button>
                                 </div>
                               </div>
                               <InfoBlock>
                                 <InfoText>{intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_1")}</InfoText>
                                 <InfoText validate="valid">{intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_valid")}</InfoText>
                                 {
                                   (tenant.allowedDomains && tenant.allowedDomains.length > 0) &&
                                     <InfoText validate="whitelist">{intl.msg("broadcast_page_solv_page_page_type_page_url_helptext_match", {allowedDomains: mkDomainListText(tenant.allowedDomains)})}</InfoText>
                                 }
                               </InfoBlock>
                             </div>
                           </div>
                         </>
                     ) : "WHATSAPP" === pageType ? (
                         <>
                           <div className="form-group">
                             <label className="col-sm-3 col-form-label" htmlFor="inp_whatsappPhoneNo">
                               {intl.msg("broadcast_whatsapp_phone_no")}:
                             </label>
                             <div className="col-sm-9">
                               <div className="input-group">
                                 <div style={{width: "200px"}}>
                                   <input id="inp_whatsappPhoneNo" type="text" className="form-control" maxLength={15} disabled={!canEdit()} value={whatsAppPhoneNo} onChange={handleWatsAppPhoneNoChange}/>
                                 </div>
                                 <div key="key_div_whatsAppUrl" className="input-group-append">
                                   <button key="key_btn_whatsAppUrl" className="btn btn-secondary" style={{borderTop: "1px solid #555", borderRight: "1px solid #555", borderBottom: "1px solid #555", width: "41px"}} type="button" title="Click to test chatting with this number" disabled={!validateUrl(pageUrl, {relaxed: true})} onClick={(e) => window.open(pageUrl)}><i className="fas fa-external-link-alt"></i></button>
                                 </div>
                               </div>
                               <InfoBlock>
                                 <InfoText>{intl.msg("broadcast_whatsapp_phone_no_helptext_1")}</InfoText>
                                 <InfoText validate="valid">{intl.msg("broadcast_whatsapp_phone_no_helptext_valid")}</InfoText>
                               </InfoBlock>
                             </div>
                           </div>
                         </>
                     ) : (
                         <></>
                     )
                   }

                 </div>
               </div>

             </div>

             <div className="row">

               <div className="col-sm-7">

                 <div className="form-group">
                   <label className="col-sm-3 col-form-label" htmlFor="inp_targetLocations">{intl.msg("broadcast_targets_locations")}:</label>
                   <div className="col-sm-9">
                     <AsyncPaginate
                         id="inp_targetLocations"
                         className="react-select"
                         classNamePrefix="react-select"
                         cacheOptions
                         isMulti
                         isClearable
                         value={targetLocations}
                         debounceTimeout={800}
                         defaultOptions={true}
                         loadOptions={loadTargetLocations}
                         reduceOptions={reduceGroupedOptions}
                         // components = {{LocationSelectOptions}}
                         onChange={handleTargetLocationsChange}
                         isDisabled={!canEdit()}
                         style={{paddingBottom: "4px"}}
                     />
                     <InfoBlock>
                       <InfoText>{intl.msg("broadcast_targets_locations_helptext_1")}</InfoText>
                       <InfoText disabled={!touched} validate="min-value">{intl.msg("broadcast_targets_locations_helptext_min_value")}</InfoText>
                       <InfoText disabled={!touched} validate="max-value">{intl.msg("broadcast_targets_locations_helptext_max_value", {maxValue: MAX_LOCATION_COUNT})}</InfoText>
                     </InfoBlock>
                   </div>
                 </div>

                 <div className="form-group">
                   <label className="col-sm-3 col-form-label" htmlFor="inp_targetBudget">{intl.msg("broadcast_targets_budget")}:</label>
                   <div className="col-sm-9">
                     <div style={{display: "flex", alignItems: "center", width: "120px"}}>
                       <NumericInput id="inp_targetBudget" className="form-control" style={false} min={10} precision={2} disabled={!canEdit()} value={targetBudget} onChange={handleTargetBudgetChange}/>
                       <div className="ml-2">{tenant.region.currencyCode ? tenant.region.currencyCode : "USD"}</div>
                     </div>
                     <InfoBlock>
                       <InfoText>{intl.msg("broadcast_targets_budget_helptext_1")}</InfoText>
                       <InfoText disabled={!touched} validate="min-value">{intl.msg("broadcast_targets_budget_helptext_min_value", {minValue: "1.00", currency: tenant.region.currencyCode})}</InfoText>
                     </InfoBlock>
                   </div>
                 </div>

                 <div className="form-group">
                   <label className="col-sm-3 col-form-label" htmlFor="inp_targetEndTime">{intl.msg("broadcast_duration")}:</label>
                   <div className="col-sm-9">
                     <div style={{width: "200px"}}>
                       <Select
                           id="inp_end_time"
                           className="react-select"
                           classNamePrefix="react-select"
                           value={targetEndTime}
                           options={broadcastTimeOpts}
                           onChange={handleTargetEndTimeChange}
                           isDisabled={!canEdit()}
                       />
                     </div>
                     <InfoBlock>
                       <InfoText>{intl.msg("broadcast_duration_helptext_1")}</InfoText>
                     </InfoBlock>
                   </div>
                 </div>

               </div>

               <div className="col-sm-5">

                 <div style={{position: "sticky", top: "200px"}}>
                   <div className="live-preview-section-divider">{intl.msg("broadcast_estimated_reach_section")}</div>
                   {
                     popEstimations && popEstimations.ttlPop && popEstimations.ttlPop > 0 ?
                         <>
                           <table className="popEstimations" style={{marginBottom: "10px"}}>
                             <tr>
                               <td className="label">{intl.msg("broadcast_estimated_reach_helptext_1")}:
                               </td>
                               <td className="value talign-right">{intl.int(popEstimations.ttlPop)}</td>
                               <td></td>
                             </tr>
                             <tr>
                               <td className="label">{intl.msg("broadcast_estimated_reach_helptext_2")}:
                               </td>
                               <td className="value talign-right">{intl.int(popEstimations.ttlImpressions)}</td>
                             </tr>
                             <tr>
                               <td className="label">{intl.msg("broadcast_estimated_reach_helptext_3")}:
                               </td>
                               <td className={(popEstimations.pctReach > 100.00 ? "text-danger" : "") + " value talign-right"}>{intl.num(popEstimations.pctReach)}</td>
                               <td className="value">%</td>
                             </tr>
                             <tr>
                               <td className="label">{intl.msg("broadcast_estimated_reach_helptext_4")}</td>
                               <td className="value talign-right" style={{whiteSpace: "nowrap"}}>{intl.num(popEstimations.budgetToReach100Pct)}</td>
                               <td className="value">{tenant.region.currencyCode ? tenant.region.currencyCode : "USD"}</td>
                             </tr>
                           </table>
                           <small>
                             <ol style={{padding: "0 0 0 18px"}}>
                               <li>{intl.msg("broadcast_estimated_reach_note_1")}</li>
                               <li>{intl.msg("broadcast_estimated_reach_note_2")}</li>
                               <li>{intl.msg("broadcast_estimated_reach_note_3")}</li>
                               <li>{intl.msg("broadcast_estimated_reach_note_4")}</li>
                             </ol>
                           </small>
                         </>
                         : <small>{intl.msg("broadcast_estimated_reach_no_location_selected")}</small>
                   }
                 </div>
               </div>

             </div>

           </form>

          </FormBody>
        </MainContainer>

        <BroadcastPublishDialog
          tenant={tenant}
          broadcastId={broadcastId}
          broadcastName={broadcastName}
          creativeImageUrl={creativeImageUrl}
          pageUrl={pageUrl}
          clickAction={clickAction}
          targetLocations={targetLocations}
          targetBudget={targetBudget}
          targetStartTime={targetStartTime}
          targetEndTime={targetEndTime}
          popEstimations={popEstimations}
        />

      </>
  )

}
