import React, { Fragment, useState, useEffect }  from 'react'
import 'react-datepicker/dist/react-datepicker.css'
import { useSolv } from "./SolvProvider"
import { useIntlEx } from "./IntlUtils.js"
import getLogger from "./Logging.js"
import { ModalDialog, closeDialog } from "./DialogUtils"
import { fileExport } from "./FileUtils";
import {Alert, FormGroup} from "./FormComps"
import '../App.css'
import {getAdSystemName} from "./BroadcastComps";

const log = getLogger("ExportToFileDialogs")

export default function ExportToFileDialog(props)  {

  const {setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [touched, setTouched] = useState(false)

  const [fileName, setFileName] = useState(null)
  const [fileExt, setFileExt] = useState(null)

  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props.fileName) {
      setFileName(props.fileName)
    }
    if (props.fileExt) {
      if (props.fileExt.startsWith(".")) {
        setFileExt(props.fileExt.substring(1))
      }
      else {
        setFileExt(props.fileExt)
      }
    }
  }, [props])

  async function handleExportClick() {
    setBusy(intl.msg("exporting"))
    try {
      const data = await props.onExport()
      fileExport(data, `${fileName}.${fileExt}`)
      closeDialog(`dlg_${props.type}_export`)
    }
    catch (ex) {
      setAlert({error: intl.msg("error_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  function handleOpenDialog() {
  }

  function handleCloseDialog() {
    closeDialog(props.id || `dlg_${props.type}_export`)
  }

  return (
      <>
        <ModalDialog
          dialogId={props.id || `dlg_${props.type}_export`}
          size={props.size || "md"}
          keyboard={"true"}
          backdrop={props.backdrop || "static"}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialog}>

          <ModalDialog.Header
            title={intl.msg(`${props.type}_export_title`)}
            description={intl.msg(`${props.type}_export_subtitle`)}
            alert={alert}/>

          <ModalDialog.Body>

            <p className="">{intl.msg(`${props.type}_export_text`)}</p>

            <FormGroup>
              <FormGroup.Label description={intl.msg(`${props.type}_export_file_name_description`)}>
                {intl.msg(`${props.type}_export_file_name`)}
              </FormGroup.Label>
              <FormGroup.Control>
                {/*<div style={{width: "fit-content", maxWidth: "200px"}}>*/}
                  <input id="inp_exportFileName" name="exportFileName" type="text" className="form-control" value={fileName} disabled={false} onChange={(e) => {setFileName(e.target.value);setTouched(true)}} autoComplete="no"/>
                {/*</div>*/}
              </FormGroup.Control>
            </FormGroup>

          </ModalDialog.Body>

          <ModalDialog.Footer>
            <ModalDialog.Footer.Controls>
              <button type="button" className="btn btn-secondary" onClick={handleCloseDialog}>
                {intl.msg("cancel")}
              </button>
              {
                fileName && fileName.trim().length > 0 &&
                <button type="button" className="btn btn-primary" onClick={handleExportClick}>
                  {intl.msg("export")}
                </button>
              }
            </ModalDialog.Footer.Controls>
          </ModalDialog.Footer>

        </ModalDialog>
      </>
  )
}