import React from "react"
import {useIntlEx} from "../components/IntlUtils";
import {PageDialogSidePanel} from "../components/PageDialog";

export function PageDialogHeader(props) {
  return (
    <>
      <img src="https://sassets.petsolv.com/brand/petsolv-logo-color-sm-c.png" width="auto" height="36"/>
    </>
  )
}

export function PageDialogFooter(props) {
  return (
      <>
        <div>
          &copy; <a className="text-link" href="https://www.solv.technology" title="SOLV Technology PTE PTD" target="_blank">SOLV Technology</a>
        </div>
        <div>
          <a className="text-link mr-3" href="https://www.petsolv.com" title="PetSOLV Home Page" target="_blank">www</a>
          <a className="text-link" href="https://petsolv.tawk.help" title="Support and Knowledgebase" target="_blank">help</a>
        </div>
      </>
  )
}

export function PageDialogSider() {
  const {intl} = useIntlEx()
  return (
      <PageDialogSidePanel>
        <img src="https://sassets.petsolv.com/brand/petsolv-hero-2.png" style={{width: "150px", height: "174px", marginBottom: "18px"}}/>
        <h4>{intl.msg("petsolv_pagedialog_sider_title")}</h4>
        <small className="mt-3">{intl.msg("petsolv_pagedialog_sider_text")}</small>
        <div className="mt-3 w-100">
          <i className="fas fa-caret-right mr-1"></i><a className="text-link" href="https://www.petsolv.com" target="_blank">{intl.msg("solv_pagedialog_sider_ctoa")}</a>
        </div>
      </PageDialogSidePanel>
  )
}