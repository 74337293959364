import React, { Fragment, Component, useState, useRef, useEffect }  from 'react'
import './App.css'

export default function Page404() {

  return (
    <Fragment>
        <main>

          <div class="jumbotron m-3">
            <center>
              <h1 style={{fontSize: "4.5rem"}}><i class="far fa-frown-open"></i></h1>
              <h1 style={{fontSize: "2.5rem", color: "#999"}}>404</h1>
              <h1 style={{fontSize: "3rem", color: "#fff", marginBottom: "30px"}}>Not Found</h1>
              <p className="lead" style={{color: "#ccc"}}>
                The page you're looking for does not exist or an error has occurred.<br/>
                <div className="mt-4"></div>
                <a className="btn btn-primary" style={{fontSize: "1.171875rem;"}} href="javascript:window.history.back()">Go Back</a> <a href="/" className="btn btn-secondary" style={{fontSize: "1.171875rem;"}} >Home</a>
              </p>
            </center>
          </div>            

        </main>
      </Fragment>
    )
}
