import React, {useEffect, useState} from 'react'
import axios from 'axios'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {Alert, FormGroup, useAlert} from "./components/FormComps"
import { useIntlEx } from "./components/IntlUtils.js"
import {useSolv} from "./components/SolvProvider";
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {mkHref} from "./components/AccountUtils";
import {default as Select} from "react-select";
import getLogger from "./components/Logging.js"
import './App.css'
import {MarkdownTextArea} from "./components/MarkdownTextArea";
import {InfoBlock, InfoText} from "./components/ValidationUtils";
import ToggleButton from "./components/ToggleButton";

const log = getLogger("BroadcastStopDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function BroadcastStopDialog(props)  {

  const {api, session, setBusy} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert()
  const [touched, setTouched] = useState(false)

  const postActionOpts = [
    {
      label: <><div>{intl.msg("broadcast_stop_post_action_mark_complete")}</div><small>{intl.msg("broadcast_stop_post_action_mark_complete_description")}</small></>,
      value: "COMPLETE"
    },
    {
      label: <><div>{intl.msg("broadcast_stop_post_action_mark_end")}</div><small>{intl.msg("broadcast_stop_post_action_mark_end_description")}</small></>,
      value: "END"
    },
    {
      label: <><div>{intl.msg("broadcast_stop_post_action_stop_with_refund")}</div><small>{intl.msg("broadcast_stop_post_action_stop_with_refund_description")}</small></>,
      value: "REFUND"
    },
    {
      label: <><div>{intl.msg("broadcast_stop_post_action_stop_only")}</div><small>{intl.msg("broadcast_stop_post_action_stop_only_description")}</small></>,
      value: "STOP"
    },
  ]

  const [postAction, setPostAction] = useState(null)
  const [description, setDescription] = useState(null)
  const [sendNotification, setSendNotification] = useState(true)

  function handleStopClick() {
    log.debug("handleStopClick: invoked")
    try {
      let payload = {}
      if (isAccessingAsManager()) {
        payload = {
          postAction: postAction.value,
          description: description,
          sendNotification: sendNotification,
        }
      }
      setBusy(intl.msg("working"))
      api.stopBroadcast(props.tenant.tenantId, props.broadcastId, payload)
        .then((data) => {
          window.location = mkHref({suffix: `/broadcasts`, tenant: props.tenant, user: session.user})
        })
        .catch((error) => {
          handleError(error)
          setAlert({error: intl.msg("error_failed")})
        })
        .finally(() => {
          setBusy(null)
        })
    }
    catch (error) {
      log.error(error)
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleError(error) {
    if (error && error.code) {
      log.debug(">>>handleError", error.code)
      switch (error.code) {
        case "ALREADY_REFUNDED":
          setAlert({error:intl.msg("broadcast_stop_error_already_refunded")})
          break
        case "UNKNOWN_BROADCAST":
          setAlert({error:intl.msg("broadcast_stop_error_unknown_broadcast")})
          break
        default:
          setAlert({error:intl.msg("error_failed")})
      }
    }
    else {
      setAlert({error:intl.msg("error_failed")})
    }
  }

  function handleOpenDialog() {
    log.debug(">>>Z: accessingAs", props.tenant.accessingAs(), isAccessingAsManager())
    setAlert(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_broadcast_refund")
  }

  function handlePostActionChange(v) {
    setPostAction(v)
    setTouched(true)
  }

  function handleDescriptionChange(e) {
    setDescription(e.target.value)
    setTouched(true)
  }

  function handleSendNotificationChange(v) {
    setSendNotification(v)
    setTouched(true)
  }

  function isAccessingAsMember() {
    return props.tenant.accessingAs("*/MEMBER/*")
  }

  function isAccessingAsManager() {
    return props.tenant.accessingAs("*/MANAGER/*")
  }

  return (

    props.tenant &&
      <ModalDialog
        dialogId="dlg_broadcast_stop"
        size={isAccessingAsMember() ? "md" : "xl"} keyboard={"true"} backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}>

        <ModalDialog.Header
          title={intl.msg("broadcast_stop_title")}
          description={intl.msg("broadcast_stop_description")}
          alert={alert}
        />

        <ModalDialog.Body>

          <Alert alert={{warning: intl.msg(`broadcast_stop_text_${isAccessingAsManager() ? "manager" : "member"}`, {broadcastName: props.broadcastName})}} align="center"/>


          {
            isAccessingAsManager() &&
              <>
                <FormGroup className="mt-3">
                  <FormGroup.Label htmlFor="inp_post_action" text={intl.msg("broadcast_stop_post_action")} description={intl.msg("broadcast_stop_post_action_description")}/>
                  <FormGroup.Controls>
                    <div style={{width: "100%"}}>
                      <Select
                        id="inp_post_action"
                        className="react-select"
                        classNamePrefix="react-select"
                        value={postAction}
                        options={postActionOpts}
                        isClearable={false}
                        isSearchable={false}
                        onChange={handlePostActionChange}/>
                    </div>
                  </FormGroup.Controls>
                </FormGroup>

                {
                  ["STOP", "REFUND"].includes(postAction?.value) &&
                    <FormGroup>
                      <FormGroup.Label htmlFor="inp_description" text={intl.msg("broadcast_stop_description")} description={intl.msg("broadcast_stop_description_description")}/>
                      <FormGroup.Controls>
                        <MarkdownTextArea id="inp_description" value={description} onChange={handleDescriptionChange}>
                          <InfoBlock>
                            <InfoText>{intl.msg("helptext_you_can_use_markdown")}</InfoText>
                          </InfoBlock>
                        </MarkdownTextArea>
                      </FormGroup.Controls>
                    </FormGroup>
                }

                <FormGroup>
                  <FormGroup.Label htmlFor="inp_sendNotification" text={intl.msg("broadcast_stop_send_notification")} description={intl.msg("broadcast_stop_send_notification_description")}/>
                  <FormGroup.Controls>
                    <ToggleButton
                      id="inp_sendNotification"
                      checked={sendNotification}
                      onChange={handleSendNotificationChange}
                      onLabel={intl.msg("yes")}
                      offLabel={intl.msg("no")}/>
                    <InfoBlock>
                    </InfoBlock>
                  </FormGroup.Controls>
                </FormGroup>

              </>
          }
        </ModalDialog.Body>

        <ModalDialog.Footer>
          <ModalDialog.Footer.Controls>
            <button type="button" className="btn btn-secondary" data-dismiss="modal">
              {intl.msg("cancel")}
            </button>
            {
              (isAccessingAsMember() || (postAction && (["COMPLETE", "END"].includes(postAction.value) || description))) &&
                <button type="button" className="btn btn-danger" onClick={handleStopClick}>
                  {intl.msg("stop")}
                </button>
            }
          </ModalDialog.Footer.Controls>
        </ModalDialog.Footer>

      </ModalDialog>
  )
}