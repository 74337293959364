import React, { Fragment, useState, useEffect }  from 'react'
import {useHistory, useParams} from 'react-router-dom'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear'
import 'react-datepicker/dist/react-datepicker.css'
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer} from "./components/FormComps.js"
import { useIntlEx } from "./components/IntlUtils.js"
import {useSolv, useTenant} from "./components/SolvProvider";
import AccountTabs from "./AccountTabs";
import {DataTable, useCols} from "./components/TableComps";
import {HumanTimestamp} from "./components/DateUtils";
import {mkHref, TenantLink} from "./components/AccountUtils";
import {TagLabel} from "./components/TagBox";
import {Widget, WidgetGroup, WidgetMetric} from "./components/Widget";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Incomes")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(quarterOfYear)

export default function Incomes() {

  const {api, session: {user}} = useSolv()
  const {intl} = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [total, setTotal] = useState({income: 0.0, revenue: 0.0})
  const [dateRange, setDateRange] = useState("")

  const [alert, setAlert] = useState(null)

  const {cols} = useCols({
    specs: [
      {
        field: "createdOn",
        label: intl.msg("created"),
        filter: {
          type: "select:reportDateRange",
          required: true,
          default: "Last30Days"
        },
        sort: "DESC",
        align: "left",
      },
      {
        field: "refTenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "description",
        label: intl.msg("description"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "commission",
        label: intl.msg("commission_pct"),
        filter: null,
        sort: "",
        align: "right",
      },
      {
        field: "revenue",
        label: intl.msg("revenue"),
        filter: null,
        sort: "",
        align: "right",
      },
      {
        field: "income",
        label: intl.msg("income"),
        filter: null,
        sort: "",
        align: "right",
        visible: () => tenant?.isSystem()
      },
    ],
    storageItemName: "responses",
  })

  async function handleFetchDataPage({pageParam: cursor}) {
    try {
      let tenantId = null
      let refTenantId = null
      if (tenant.isClient()) {
        tenantId = user.tenantId
        refTenantId = tenant.tenantId
      }
      else {
        tenantId = tenant.tenantId
        refTenantId = null
      }
      const res = await api.listIncomes({tenantId: tenantId, refTenantId: refTenantId, extraParams: cols.stringify(), cursor: cursor})
      // if (res && res.data && Array.isArray(res.data)) {
      //   const ttl = res.data.reduce((acc, cur) => {
      //     return acc + (cur.income ? cur.income : 0.0);
      //   }, 0.0)
      //   setTotal(ttl)
      // }
      // else {
      //   setTotal(0.0)
      // }
      return res
    }
    catch (err) {
      setAlert({error: intl.msg("error_failed")})
    }
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "createdOn":
        n = <HumanTimestamp timestamp={row.createdOn} else={intl.msg("never")}/>
        break
      case "refTenantName":
        n = n = <TenantLink tenantId={row.refTenantId} tenantName={row.refTenantName} suffix={`/income`}/>
        break
      // case "broadcastName":
      //   n = row.broadcastId && <span><a className="text-link" href={`/${row.broadcastTenantId}/broadcasts/${row.broadcastId}`}>{intl.msg("broadcast")} "{row.broadcastName}"</a></span>
      //   break
      case "description":
        n = <span>{row.description}</span>
        break
      case "commission":
        n = <span>{intl.num(row.commission)}</span>
        break
      case "revenue":
        n = <span>{intl.num(row.revenue)}</span>
        break
      case "income":
        n = <span>{intl.num(row.income)}</span>
        break
    }
    return (n)
  }

  function handleRowClick(row) {
    window.location = mkHref({suffix: `/credits/${row.creditTransactionId}`, tenant: tenant})
  }

  async function handleFilterChange(col, value) {
    let tenantId = null
    let refTenantId = null
    if (tenant.isClient()) {
      tenantId = user.tenantId
      refTenantId = tenant.tenantId
    }
    else {
      tenantId = tenant.tenantId
      refTenantId = null
    }
    const {data} = await api.getTotalIncome({tenantId: tenantId, refTenantId: refTenantId, startTime: value.startTime, endTime: value.endTime})
    log.debug(">>>Z: handleFilterChange: data=", data)
    setTotal(data || {income: 0.0, revenue: 0.0})
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={["*/*/RESELLER", "*/*/SYSTEM"]}>
          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("revenue")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>
          <FormBody>
            <div className="row no-gutters justify-content-center">
              <IncomeWidget revenue={total?.revenue} income={user.isSystem() ? total?.income : null} subtitle={dateRange} currencyCode={tenant.isSystem() ? "USD" : tenant.currencyCode}/>
            </div>
            <div className="row">
              <div className="col pt-4">
                <DataTable
                  tenant={tenant}
                  cols={cols}
                  resourceName="incomes"
                  dataKey="tenantIncomeId"
                  onFetchDataPage={handleFetchDataPage}
                  onRenderDataCol={handleRenderDataCol}
                  onClickDataRow={handleRowClick}
                  onFilterChange={handleFilterChange}
                />
              </div>
            </div>
          </FormBody>
        </MainContainer>
      </>
  )
}

function IncomeWidget(props) {
  log.debug(">>>Z: IncomeWidget: props=", props)
  const {intl} = useIntlEx()
  return (
      <WidgetGroup>
        <Widget title={intl.msg("revenue")}  subtitle={props.subtitle}>
          <WidgetMetric>
            {intl.num(props.revenue, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
            <small className="pl-1">{props.currencyCode}</small>
          </WidgetMetric>
        </Widget>
        {
          props.income &&
            <Widget title={intl.msg("income")}  subtitle={props.subtitle}>
              <WidgetMetric>
                {intl.num(props.income ? props.income : 0.0, {minimumFractionDigits: 2, maximumFractionDigits: 2})}
                <small className="pl-1">{props.currencyCode}</small>
              </WidgetMetric>
            </Widget>
        }
      </WidgetGroup>
  )
}
