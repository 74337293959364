import React, { Fragment, useState, useRef, useEffect }  from 'react'
import { useParams, useHistory, useLocation, Redirect }  from 'react-router-dom'
import { useMutation } from 'react-query'
import { default as Select } from "react-select";
import {useSolv, useTenant} from "../components/SolvProvider"
import { useIntlEx } from "../components/IntlUtils.js"
import { usePrefs } from "../components/PrefUtils.js"
import { validateField, showError, clearAllErrors, validateUrl} from "../components/ValidationUtils.js"
import { LOCALES } from "../i18n/locales"
import getLogger from "../components/Logging.js"
import '../App.css'
import {PageDialogFooter, PageDialogHeader} from "./PageDialogUtils";
import {PageDialog, PageDialogContainer, PageDialogMainPanel} from "../components/PageDialog";
import {MainContainer, useAlert} from "../components/FormComps";

const log = getLogger("Onboarding")

export default function Onboarding(props) {

  const {api, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [alert, setAlert] = useAlert(null)

  const {tenant} = useTenant()

  useEffect(() => {
    loadOnboardingInfo()
  }, [])

  async function loadOnboardingInfo() {

    setBusy(intl.msg("loading"))

    api.getOnboardingInfo()
      .then(({data}) => {
        log.debug("getOnboardingInfo: data=", data)
          completeOnboarding()
      })
      .catch((error) => {
        log.debug("useEffect: !!error=", error)
        setFatal("OOPS")
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function completeOnboarding() {
    log.debug("completeOnboarding: invoked")
    return api.updateOnboarded()
      .then(({data}) => {
        log.debug("completeOnboarding: updateOnboarded: res=", data)
        window.location = "/"
      })
      .catch((error) => {
        setAlert({error: intl.msg("error_failed")})
      })
  }

  return (
    <>
      <MainContainer>
      </MainContainer>
    </>
  )
}