import React  from 'react'
import '../App.css'

export default function SortableColumn(props) {

    function renderSort(sortSpec, field) {
      if (sortSpec.field === field) {
        if (sortSpec.direction === "ASC") {
          return <span>&#9651;</span>   
        }
        else {
          return <span>&#9661;</span>   
        }
      }
      else {
        return <span></span>
      }
    }
    
    function handleColumnClick(sortSpec, field) {
      if (sortSpec.field === field) {
        if (sortSpec.direction === "ASC") {
          props.setSortSpec(prev => {return {field: field, direction: "DESC"}})
        }
        else {
          props.setSortSpec(prev => {return {field: field, direction: "ASC"}})
        }
      } 
      else {
        props.setSortSpec(prev => {return {field: field, direction: "ASC"}})
      }   
    }

    return (
        <label className="sortable-column" onClick={() => handleColumnClick(props.sortSpec, props.field)}>{props.label} {renderSort(props.sortSpec, props.field)}</label>
    )

}
