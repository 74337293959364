import React, {Fragment, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import {useSolv} from "../components/SolvProvider"
import MainMenu from "../MainMenu"
import {FormHeader, FormBody, MainContainer, SectionHeader} from "../components/FormComps.js"
import { useIntlEx } from "../components/IntlUtils.js"
import getLogger from "../components/Logging.js"
import SystemTabs from "./SystemTabs"
import {SecretText} from "../components/SecretText";
import "../App.css"
import styles from "./SysStatus.module.css"

const log = getLogger("SysStatus")

export default function SysStatus() {

  const {api, env, session: {user, tenant}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const [alert, setAlert] = useState(null)

  useEffect(() => {
      log.debug("useEffect: env=", env, Object.entries(env))
      if (!user.isSystem()) {
          setFatal("DENIED_ACCESS")
      }
  },[])

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
            <SystemTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("system_status")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className={`form-card ${styles.container}`}>

              <div>
                <SectionHeader title="Environment"/>

                <div>
                  <ul className={styles["bullet-list"]}>
                    {
                      Object.entries(env).map(e => {
                        return (
                          e[0].indexOf("SECRET") !== -1 ? (
                            <li><span style={{color: "var(--form-text-color)"}}>{e[0]}:</span> <SecretText text={e[1]}/></li>
                          ) : (
                            <li><span style={{color: "var(--form-text-color)"}}>{e[0]}:</span> {e[1]}</li>
                          )
                        )
                      })
                    }
                   </ul>
                </div>
              </div>

            </div>


          </FormBody>

        </MainContainer>

      </>
    )
}