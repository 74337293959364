import React, { Fragment, useState, useEffect }  from 'react'
import { useSolv } from "./components/SolvProvider"
import { useIntlEx } from "./components/IntlUtils"
import { AlertBox } from "./components/AlertBox"
import getLogger from "./components/Logging.js"
import './App.css'
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {Alert} from "./components/FormComps";

const log = getLogger("TemplateSetDefaultDialog")

export default function TemplateSetDefaultDialog(props)  {

  const {api, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [tenant, setTenant] = useState(null)
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(null)
  const [broadcastTemplateName, setBroadcastTemplateName] = useState(null)

  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
      }
      if (props.broadcastTemplateId) {
        setBroadcastTemplateId(props.broadcastTemplateId)
      }
      if (props.broadcastTemplateName) {
        setBroadcastTemplateName(props.broadcastTemplateName)
      }
    }
  }, [props])
  function handleSetDefaultClick() {
    setBusy(intl.msg("working"))
    api.setDefaultBroadcastTemplate(tenant.tenantId, broadcastTemplateId)
      .then(({data}) => {
        log.debug("Set Default broadcast Template1: data=", data)
        window.location = `/${tenant.tenantId}/templates/${broadcastTemplateId}`
      })
      .catch ((error) => {
        log.debug("Error setting default template", error)
        setAlert({error: intl.msg("error_failed")})
      })
      .finally(() => {
        setBusy(null)
      })
  }

  function handleOpenDialog() {
    setAlert(null)
    setBusy(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_template_set_default")
  }

  return (

    tenant &&
      <ModalDialog
        dialogId="dlg_template_set_default"
        header={<h1>{intl.msg("template_set_default_title")}</h1>}
        footer={
          <>
            <button type="button" className="btn btn-danger" onClick={handleSetDefaultClick}>{intl.msg("template_set_default")}</button>
            <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
          </>
        }
        size="md"
        keyboard={"true"}
        backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
        alert={alert}>
        <center>
          {
            !alert &&
              <div className="">
                <Alert type="attention">{intl.msg("template_set_default_text_1", {templateName: props.broadcastTemplateName})}</Alert>
                <p className="mt-3">{intl.msg("template_set_default_text_2")}</p>
              </div>
          }
        </center>
      </ModalDialog>
  )
}