import React, { useState, useEffect }  from 'react'
import { useSolv } from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'

// const APP_VER = process.env.REACT_APP_VERSION

const log = getLogger("AboutDialog")

export function AboutSolvDialog(props)  {

  const { env } = useSolv()
  const { intl } = useIntlEx()

  // log.debug("env=", env)

  return (

      <div className="modal fade" id="dlg_about_solv" tabIndex="-1" role="dialog" aria-labelledby="dlg_about_solv" aria-hidden="true">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            {/* <div className="modal-header">
              <h5 className="modal-title">{intl.fm("about_title")}</h5>
              <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">&times;</span>
              </button>
            </div> */}
            <div className="modal-body">
              <div className="row" style={{margin: "20px 0"}}>
                <div className="col">

                  <div style={{display: "flex"}}>
                    
                    {/* <div style={{backgroundColor: "black", border: "2px solid #aaa", borderRadius: "20px", padding: "4px 20px 4px 20px", width: "172px"}}> */}
                    <div style={{display: "flex", alignItems: "center", justifyContent: "center", backgroundColor: "black", border: "2px solid #aaa", borderRadius: "20px", padding: "4px 20px 4px 20px", maxWidth: "152px"}}>
                      <img src="https://sassets.solv.technology/brand/solv-logo-with-tagline-color-dark-md-c.png" width="auto" height="60"/>
                    </div>
                    
                    <div style={{paddingLeft: "20px"}}>
                      
                      <div style={{fontSize: "30px", fontWeight: "700"}}>
                        {intl.msg("app_name")}
                      </div>
                      <div style={{fontSize: "20px", fontWeight: "500"}}>
                        {intl.msg("app_version", {version: env.APP_VERSION})}
                      </div>
                                            
                      <div style={{display: "flex", marginTop: "44px"}}>
                        <div style={{color: "#ccc", marginTop: "4px"}}>
                          {intl.msg("footer_terms")}
                          <span style={{color: "#555", padding:"0 10px"}}>|</span>
                        </div>
                        <div style={{color: "#ccc", marginTop: "4px"}}>
                          {intl.msg("footer_privacy")}
                          <span style={{color: "#555", padding:"0 10px"}}>|</span>
                        </div>
                        <div style={{color: "#ccc", marginTop: "4px"}}>
                          {intl.msg("footer_contact")}
                        </div>
                      </div>

                      <div style={{marginTop: "10px"}}>
                        {intl.msg("footer_copyright")}
                      </div>
                    
                    </div>

                  </div>  

                </div>
              </div>              
            </div>
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" data-dismiss="modal">{intl.msg("ok")}</button>
            </div>            
          </div>
        </div>
      </div> 
   )
}