import React, {Fragment, useEffect, useState} from 'react'
import {useHistory, useParams} from 'react-router-dom'
import { useInfiniteQuery, useQueryClient } from 'react-query'
import { v4 as uuidv4 } from 'uuid'
import {useSolv, useTenant} from "../components/SolvProvider"
import MainMenu from "../MainMenu"
import {FormHeader, FormBody, MainContainer} from "../components/FormComps.js"
import SortableColumn from "../components/SortableColumn"
import { useIntlEx } from "../components/IntlUtils"
import useIntersectionObserver from '../useIntersectionObserver.js'
import {SearchInput} from "../components/SearchInput";
import SystemTabs from "./SystemTabs";
import getLogger from "../components/Logging.js"
import '../App.css'

const log = getLogger("RepresentativeResellers")

export default function RepResellers() {

  const {api, session: {user}, setFatal, setBusy} = useSolv()
  const {intl } = useIntlEx()

  const history = useHistory()
  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [search, setSearch] = useState(null)
  const [sortSpec, setSortSpec] = useState({field: "currencyCode", direction: "ASC"})

  const [alert, setAlert] = useState(null)

  const [reloadId, setReloadId] = useState(null)

  const queryClient = useQueryClient()

  const fetchIndustries = async ({ pageParam = null }) => {
    return api.listIndustries(search, sortSpec, pageParam, 50)
  }

  const {
    status,
    data,
    isFetching,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useInfiniteQuery(['industries', search, sortSpec, reloadId], fetchIndustries, {
    getNextPageParam: (lastPage, pages) => {
      return lastPage.nextCursor
    },
    enabled: !!tenant
  })

  const loadMoreButtonRef = React.useRef()

  useIntersectionObserver({
    target: loadMoreButtonRef,
    onIntersect: fetchNextPage,
    enabled: hasNextPage,
  })

  function handleSearchClick(v) {
    setSearch(v)
  }

  function handleRowClick(regionId) {
    window.location = `/sys/regions/${regionId}`
  }

  function handleRefreshClick() {
    setReloadId(Math.random())
    queryClient.invalidateQueries("members")
  }

  return (

    tenant &&
    <>

      <MainContainer tenant={tenant} menu={MainMenu} allowIfAccessingAs={"*/*/SYSTEM"}>

        <FormHeader>
          <SystemTabs/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("represellers")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>

              <div className="form-group">
                <SearchInput value={search} onSearch={handleSearchClick}/>
              </div>

              <button className="btn btn-secondary" type="button" title={intl.msg("tooltip_refresh_list")} onClick={handleRefreshClick}>
                <i className="fas fa-redo"></i>
              </button>

              {
                user.isSystem() && user.isAdminOrAbove() &&
                  <button className="btn btn-primary" type="button" title={intl.msg("new")} onClick={handleRefreshClick}>
                    <i className="fas fa-plus"></i>
                  </button>
              }

            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>

        <FormBody>

          <div className="row">
            <div className="col">
              <table className="table table-dark table-striped table-bordered table-hover dir">
                <thead>
                <tr>
                  <th scope="col" className="col-clickable">
                    <SortableColumn label={intl.msg("reseller")} field="resellerTenantName" sortSpec={sortSpec} setSortSpec={setSortSpec}/>
                  </th>
                </tr>
                </thead>
                <tbody>
                {
                  status === 'loading' ? (
                    <tr>
                      <td colSpan="3">{intl.msg("loading")}</td>
                    </tr>
                  ) : status === 'error' ? (
                    <tr>
                      <td className="text-danger" colSpan="3">{intl.msg("error_failed")}</td>
                    </tr>
                  ) : data ? (
                    // func(data)
                    data.pages.map((group, i) => (
                      <React.Fragment key={uuidv4()}>
                        {group.data.map(row => (
                          <tr key={row.industryId}>
                            <td>
                              {row.industryId}
                            </td>
                          </tr>
                        ))}
                      </React.Fragment>
                    ))
                  ) : ""
                }
                </tbody>
              </table>
              <div>
                {
                  hasNextPage ?
                    <button
                      className="btn btn-secondary"
                      ref={loadMoreButtonRef}
                      onClick={() => fetchNextPage()}
                      disabled={!hasNextPage || isFetchingNextPage}>
                      {
                        isFetchingNextPage
                          ? <span>{intl.msg("loading")}</span>
                          : <span>{intl.msg("load_more")}</span>
                      }
                    </button>
                    : ""
                }
              </div>
              <div>
                {/* {isFetching && !isFetchingNextPage ? intl.fm("refreshing") : null} */}
              </div>
            </div>
          </div>
        </FormBody>
      </MainContainer>

    </>
  )
}