import React, {Fragment, useState, useRef, useEffect} from 'react'
import {useParams}  from 'react-router-dom'
import 'react-datepicker/dist/react-datepicker.css'
import {useSolv, useTenant} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer} from "./components/FormComps.js"
import ExportToFileDialog from "./components/ExportToFileDialog";
import {openDialog, openDialogCurried} from "./components/DialogUtils";
import AccountTabs from "./AccountTabs";
import CreditsExtendExpiryDialog from "./CreditsExtendExpiryDialog";
import CreditsTopupDialog from "./CreditsTopupDialog";
import {Widget, WidgetMetric} from "./components/Widget";
import {mkHref, TenantLink} from "./components/AccountUtils";
import {DataTable, useCols} from "./components/TableComps";
import {HumanTimestamp} from "./components/DateUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("CreditTransactions")

export default function CreditTransactions(props) {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert, setAlert] = useState(null)

  const [selectedRow, setSelectedRow] = useState(null)

  const {cols} = useCols({
    specs: [
      {
        field: "createdOn",
        label: intl.msg("created"),
        filter: {
          type: "select:reportDateRange",
          required: true,
          default: "Last30Days"
        },
        sort: "DESC",
        align: "left",
      },
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "",
        align: "left",
        visible: function() {
          return tenant && tenant.accessingAs("*/MEMBER/SYSTEM,RESELLER")
        },
      },
      {
        field: "creditTransactionId",
        label: intl.msg("credits_transaction_id"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "description",
        label: intl.msg("description"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "credits",
        label: intl.msg("credits"),
        filter: null,
        sort: "",
        align: "left",
      },
    ],
    storageItemName: "responses",
  })

  useEffect(() => {
    if (tenant) {
      if (CreditsExpiring(tenant.credits)) {
        setAlert({error: intl.msg("credits_alert_expiring", {accessType: tenant.accessInfo?.accessType, canExtend: canExtendCredits(tenant, user).toString(), expireDays: intl.fmDaysFrom(tenant.credits.expiresOn), a: chunk => <a className="text-link" href="#" onClick={openDialogCurried("dlg_credits_extend_expiry")}>{chunk}</a>})})
      }
    }
  }, [tenant])

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listCreditTransactions({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "createdOn":
        n = <HumanTimestamp timestamp={row.createdOn} else={intl.msg("never")}/>
        break
      case "tenantName":
        n = n = <TenantLink tenant={row} suffix={`/credits`}/>
        break
      case "creditTransactionId":
        n = <span>{row.creditTransactionId}</span>
        break
      case "description":
        n = <TransactionDescription row={row}/>
        break
      case "credits":
        n = <span>{intl.num(row.credits, "ACCOUNTING")}</span>
        break
    }
    return (
        n
    )
  }

  function handleRowClick(row) {
    setSelectedRow(row)
    window.location = mkHref({suffix: `/credits/${row.creditTransactionId}`, tenant: tenant})
  }

  function TransactionDescription({row}) {
    return row.description
  }

  async function handleExport() {
    return api.exportCreditTransactions({tenantId: tenant.tenantId, extraParams: cols.stringify()})
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu}>

          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("credits")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  !tenant.isSystem() && tenant.accessingAs(["SUPERADMIN/MEMBER/CLIENT", "ADMIN/MANAGER/SYSTEM"]) &&
                    <>
                      <a className={`btn ${canExtendCredits() ? "btn-secondary" : "btn-primary"}`} href="#" onClick={() => openDialog("dlg_credits_topup")}>
                        <i className="fas fa-plus" style={{marginRight: "6px"}}></i>
                        {intl.msg("credits_top_up")}
                      </a>
                    </>
                }
                {
                  canExtendCredits() &&
                    <CreditsExtendButton className="ml-2" tenant={tenant} mode="BUTTON"/>
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>

          <FormBody>

            <div className="row no-gutters">
              <div className="col">

                {
                  tenant.isClient() &&
                    <div className="row no-gutters justify-content-center">
                      <CreditsWidget tenant={tenant}/>
                    </div>
                }

                <div className={`row ${tenant.isClient() ? "mt-4" : ""}`}>
                  <div className="col">
                    <DataTable
                      tenant={tenant}
                      cols={cols}
                      resourceName="creditTransactions"
                      dataKey="creditTransactionId"
                      onFetchDataPage={handleFetchDataPage}
                      onRenderDataCol={handleRenderDataCol}
                      onClickDataRow={handleRowClick}
                    />
                  </div>
                </div>
              </div>
            </div>

          </FormBody>

        </MainContainer>
        <CreditsTopupDialog
            key={"topup"}
            tenant={tenant}/>
        <CreditsExtendExpiryDialog
            key={"extend"}
            tenant={tenant}/>
        <ExportToFileDialog
            key="download"
            type="credits"
            fileName={`solv-credit-transactions-${intl.date(new Date(), "ISO-COMPACT")}`}
            onExport={handleExport}/>
      </>

  )
}

export function CreditsExpiring(credits) {
  let expiring = false
  if (credits && credits.amount > 0 && credits.expiresOn) {
    switch (credits.status) {
      case "EXPIRING_3":
        expiring = true
        break
      case "EXPIRING_2":
        expiring = true
        break
      case "EXPIRING_1":
        expiring = true
        break
      default:
        expiring = false
    }
  }
  return expiring
}

export function CreditsExpiringText(props) {
  const {intl} = useIntlEx()
  let { credits } = props
  let cls = ""
  let el = ""
  let expiring = CreditsExpiring(credits)
  if (expiring) {
    switch (credits.status) {
      case "EXPIRING_3":
        cls = "text-danger"
        break
      case "EXPIRING_2":
        cls = "text-warning"
        break
      case "EXPIRING_1":
        cls = "text-warning"
        break
      default:
        cls = ""
    }
    el = <>{intl.msg("credits_expires_days", {expireDays: intl.fmDaysFrom(credits.expiresOn)})}</>
    return (
        <span className={cls} title={intl.dateTime(credits.expiresOn)}>
        {el}
      </span>
    )
  }
  else if (props.alt) {
    return <span>
      {props.alt}
    </span>
  }
  else {
    return <></>
  }
}

export function CreditsExtendButton(props) {
  const {intl} = useIntlEx()
  const tenant = props.tenant
  const mode = props.mode || "BUTTON"
  return (
      tenant && CreditsExpiring(tenant.credits) && tenant.accessingAs("ADMIN/*/*")) ? (
      // tenant && (tenant.accessingAs("ADMIN/*/SYSTEM") || (isCreditsExpiring(tenant.credits) && tenant.accessingAs("ADMIN/*/*"))) ? (
      "BUTTON" === mode ? (
          <button className={"btn btn-primary " + (props.className || "")} href="#" title={intl.msg("credit_transaction_extend_expiry_title")} onClick={() => openDialog("dlg_credits_extend_expiry")}>
            <i className="fas fa-history mr-1"></i>
            {intl.msg("credit_transaction_extend_expiry")}
          </button>
      ) : (
          <a className={"text-link " + (props.className || "")} href="#" onClick={() => openDialog("dlg_credits_extend_expiry")}>
            {intl.msg("credit_transaction_extend_expiry_title")}
          </a>
      )
  ) : (
      <></>
  )
}

export function CreditsWidget(props) {
  const {session} = useSolv()
  const {intl} = useIntlEx()
  const tenant = props.tenant
  return (
    tenant && tenant.credits &&
      <Widget title={intl.msg("credits")} subtitle={<CreditsExpiringText credits={tenant.credits} alt={intl.msg("balance")}/>} onClick={() => window.location = mkHref({suffix: "/credits", tenant: tenant})} size={props.size}>
        <WidgetMetric>
          {intl.num(tenant.credits.amount || 0.0)}
          <small className="pl-1">{tenant.region?.currencyCode || ""}</small>
        </WidgetMetric>
      </Widget>
  )
}

export function canExtendCredits(tenant, user) {
  return (
    tenant && CreditsExpiring(tenant.credits) && (user.isSystem() || tenant.accessingAs("ADMIN/MANAGER/*") || (tenant.accessingAs("SUPERADMIN/MEMBER/*") && !tenant.credits.extendedOn))
  )
}


