import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useQueryClient } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {BroadcastStatusTagLabel} from './components/BroadcastUtils.js';
import { useIntlEx } from "./components/IntlUtils.js"
import getLogger from "./components/Logging.js"
import {closeDialog, ModalDialog} from "./components/DialogUtils";
import {useSolv} from "./components/SolvProvider";
import {shortIdEqual} from "./components/StringUtils";
import './App.css'
import AsyncSelect from "react-select/async";
import {InfoBlock, InfoText} from "./components/ValidationUtils";

const log = getLogger("TemplatesInstallDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function TemplatesInstallDialog(props)  {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl } = useIntlEx()

  const [tenant, setTenant] = useState(null)

  const [broadcastTemplates, setBroadcastTemplates] = useState(null)
  const [touched, setTouched] = useState(false)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
      }
    }
  }, [props])

  useEffect(() => {
    if (broadcastTemplates) {
      setTouched(broadcastTemplates.length > 0)
    }
    else {
      setTouched(false)
    }
  }, [broadcastTemplates])

  function filterBroadcastTemplates(input) {
    log.debug("loadBroadcastTemplates: input=", input)
    return api.listInstallableBroadcastTemplates(tenant.tenantId, input)
      .then(({data}) => {
        if (data) {
          log.debug("loadBroadcastTemplates: data=", data)
          let opt = data.map(c => {
            return {
              value: c.broadcastTemplateId,
              label: c.broadcastTemplateName,
            };
          })
          log.debug("loadBroadcastTemplates: opt=", opt)
          return opt
        }
      })
      .catch((error) => {
        log.debug("Error loading broadcast", error)
      })
  }

  function handleBroadcastTemplatesChange(v) {
    setBroadcastTemplates(v)
    setTouched(true)
  }

  async function handleInstallClick() {

    log.debug("handleInstallClick: invoked")
    try {
      setBusy(intl.msg("Installing"))
      const payload = {
        broadcastTemplateIds: broadcastTemplates.map(i => i.value)
      }
      api.installBroadcastTemplates(tenant.tenantId, payload)
        .then(({data}) => {
          window.location.reload()
        })
        .catch(({error}) => {
          handleError(error)
        })
        .finally(() => {
          setBusy(null)
        })
    }
    catch (error) {
      handleError(error)
    }
    finally {
      setBusy(null)
    }

  }

  function handleError(error) {
    setAlert({error: intl.msg("error_failed")})
  }

  function handleOpenDialog() {

  }

  function handleCloseDialog() {
    closeDialog("dlg_template_install")
  }

  return (

    tenant &&
      <ModalDialog
        dialogId="dlg_template_install"
        header={<h1>{intl.msg("template_install_title")}</h1>}
        footer={
          <>
            {
              touched &&
                <button className="btn btn-primary" onClick={handleInstallClick}>{intl.msg("install")}</button>
            }
            <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
          </>
        }
        size="lg"
        keyboard={"true"}
        backdrop={props.backdrop || "static"}
        onOpen={handleOpenDialog}
        onClose={handleCloseDialog}
        alert={alert}>
          <p>
            {intl.msg("template_install_text")}
          </p>
          <div className="form-group row">
            <label className="col-sm-2 col-form-label" htmlFor="inp_broadcastTemplate">{intl.msg("template_install_templates")}</label>
            <div className="col-sm-10">
              <AsyncSelect
                  id="inp_broadcastTemplate"
                  className="react-select"
                  classNamePrefix="react-select"
                  cacheOptions
                  isMulti
                  isClearable
                  value={broadcastTemplates}
                  loadOptions={filterBroadcastTemplates}
                  debounceTimeout={800}
                  defaultOptions={true}
                  onChange={handleBroadcastTemplatesChange}
                  placeholder="Select..."
                  isDisabled={false}/>
              <InfoBlock>
                <InfoText>{intl.msg("template_install_templates_helptext_1")}</InfoText>
              </InfoBlock>
            </div>
          </div>
      </ModalDialog>
  )
}





/*
<div className={`form-group row ${isEditable() ? "" : "readonly"}`}>
                        <label className="col-sm-3 col-form-label" htmlFor="inp_broadcastTemplate">{intl.msg("broadcast_template")}</label>
                        <div className="col-sm-9">
                          <AsyncSelect
                            id="inp_broadcastTemplate"
                            className="react-select"
                            classNamePrefix="react-select"
                            cacheOptions
                            value={broadcastTemplate}
                            loadOptions={searchBroadcastTemplates}
                            debounceTimeout={800}
                            defaultOptions={true}
                            onChange={handleBroadcastTemplateChange}
                            placeholder="Select..."
                            isDisabled={false}/>
                            <InfoBlock>
                              <InfoText>{intl.msg("broadcast_template_helptext_1")}</InfoText>
                              <InfoText validate="required" disable={!isEditable()}>{intl.msg("helptext_required")}</InfoText>
                            </InfoBlock>
                          </div>
                        </div>
                      </div>
 */