import React  from 'react'

export function TagBox(props) {
  return (
    <div className={"tag-box" + (props.className ? ` ${props.className}`: "")}>
      {props.children}
    </div>
  )
}

export function TagLabel(props) {
  return (
    <>
    {
      props.label && 
        <div className={"tag-label " + props.className} title={props.tooltip || props.title}>
          {props.label.toUpperCase()}
        </div>
    }
    </>
  )
}
