import React, {Fragment, useEffect} from 'react'
import {useParams}  from 'react-router-dom'
import {useSolv, useTenant} from "./components/SolvProvider"
import {useIntlEx} from "./components/IntlUtils.js"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, useAlert} from "./components/FormComps.js"
import {BroadcastStatusTagLabel, useBroadcastStatusOpts} from './components/BroadcastComps.js'
import {TagBox} from "./components/TagBox.js"
import AccountTabs from "./AccountTabs"
import {HumanTimestamp} from "./components/DateUtils"
import {useCols} from "./components/TableComps"
import {DataTable} from "./components/TableComps"
import {mkHref, TenantLink} from "./components/AccountUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Broadcasts")

export default function Broadcasts() {

  const params = useParams()

  const {brandId, api, session:{user}} = useSolv()
  const {intl} = useIntlEx()

  const {tenant} = useTenant(params.tenantId)

  const [alert] = useAlert(null)

  const {broadcastStatusOpts} = useBroadcastStatusOpts({system: user?.isSystem()})

  log.debug("broadcastStatusOpts: ", broadcastStatusOpts)

  const {cols} = useCols({
    specs: [
      {
        field: "touchedOn",
        label: intl.msg("last_updated"),
        filter: "select:reportDateRange",
        sort: "DESC",
        align: "left",
      },
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "",
        align: "left",
        visible: function() {
          return tenant && tenant.accessingAs("*/MEMBER/SYSTEM,RESELLER")
        },
      },
      {
        field: "broadcastName",
        label: intl.msg("broadcast_name"),
        filter: "text",
        sort: "",
        align: "left",
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: {
          type: "select",
          multi: true,
          options: () => broadcastStatusOpts
        },
        sort: "",
        align: "left",
      },
      {
        field: "adViews",
        label: intl.msg("views"),
        sort: "",
        align: "right",
      },
      {
        field: "adClicks",
        label: intl.msg("clicks"),
        sort: "",
        align: "right",
      },
      // {
      //   field: "responses",
      //   label: intl.msg("responses"),
      //   sort: "",
      //   align: "right",
      // },
    ],
    multiSort: false,
    storageItemName: `${brandId.toUpperCase()}_COLS_broadcasts`,
  })

  // useEffect(() => {
  //   cols.load()
  // }, [])

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listBroadcasts({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "broadcastName":
        n = <span>{row.broadcastName}</span>
        break
      case "tenantName":
        n = <TenantLink tenant={row} suffix={`/broadcasts`}/>
        break
      case "status":
        n = <TagBox><BroadcastStatusTagLabel broadcast={row} user={user}/></TagBox>
        break
      case "adViews":
        n = <span title={intl.int(row.adViews)}>{intl.int(row.adViews, "compact")}</span>
        break
      case "adClicks":
        n = <span title={intl.int(row.adClicks)}>{intl.int(row.adClicks, "compact")}</span>
        break
      case "responses":
        n = (row.pageType === "SOLV") ? <span title={intl.int(row.feedbacks)}>{intl.int(row.feedbacks, "compact")}</span> : ""
        break
      case "touchedOn":
        n = <HumanTimestamp timestamp={row.touchedOn} else={intl.msg("never")}/>
        break
    }
    return (
      n
    )
  }

  function handleRowClick(row) {
    window.location = mkHref({suffix: `/broadcasts/${row.broadcastId}`, tenant: row, user: user})
  }

  return (
    tenant &&
      <MainContainer tenant={tenant} menu={MainMenu}>
        <FormHeader>
          <AccountTabs tenant={tenant}/>
          <FormHeader.Toolbar>
            <FormHeader.Toolbar.Title>
              {intl.msg("broadcasts")}
            </FormHeader.Toolbar.Title>
            <FormHeader.Toolbar.Controls>
              {
                tenant && tenant.isClient() && tenant.accessingAs(["BASIC/MEMBER/*", "ADMIN/MANAGER/*"]) &&
                  <a className="btn btn-primary ml-2" href={`/${tenant.tenantId}/broadcasts/_new`}>
                    <i className="fas fa-plus mr-1" style={{marginRight: "6px"}}></i>
                    {intl.msg("add_new")}
                  </a>
              }
            </FormHeader.Toolbar.Controls>
          </FormHeader.Toolbar>
          <FormHeader.Alert alert={alert}/>
        </FormHeader>
        <FormBody>
          <DataTable
            tenant={tenant}
            cols={cols}
            resourceName="broadcasts"
            dataKey="broadcastId"
            onFetchDataPage={handleFetchDataPage}
            onRenderDataCol={handleRenderDataCol}
            onClickDataRow={handleRowClick}
          />
        </FormBody>

      </MainContainer>
    )
}