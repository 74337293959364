import React, {Fragment}  from 'react'
import {useParams} from 'react-router-dom'
import {useSolv, useTenant} from "./components/SolvProvider"
import MainMenu from "./MainMenu.js"
import {FormHeader, FormBody, MainContainer, useAlert} from "./components/FormComps.js"
import {useIntlEx} from "./components/IntlUtils.js"
import {AccessLevel, UserStatus} from "./components/UserUtils";
import AccountTabs from "./AccountTabs";
import {HumanTimestamp} from "./components/DateUtils";
import {TagLabel} from "./components/TagBox";
import {DataTable, useCols} from "./components/TableComps";
import {mkHref, TenantLink} from "./components/AccountUtils";
import getLogger from "./components/Logging.js"
import './App.css'

const log = getLogger("Members")

export default function Members() {

  const {api, session: {user}} = useSolv()
  const {intl } = useIntlEx()

  const params = useParams()

  const {tenant} = useTenant(params.tenantId)

  const [alert] = useAlert()

  const {cols} = useCols({
    specs: [
      {
        field: "displayName",
        label: intl.msg("member"),
        filter: "text",
        sort: "ASC",
        align: "left",
      },
      {
        field: "tenantName",
        label: intl.msg("account"),
        filter: "text",
        sort: "",
        align: "left",
        visible: function() {
          return tenant && tenant.accessingAs("*/MEMBER/SYSTEM,RESELLER")
        },
      },
      {
        field: "userLevel",
        label: intl.msg("member_access_level"),
        filter: "select:accessLevel",
        multi: true,
        sort: "",
        align: "left",
      },
      {
        field: "signedInOn",
        label: intl.msg("member_last_signedin"),
        filter: "select:reportDateRange",
        sort: "",
        align: "left",
      },
      {
        field: "status",
        label: intl.msg("status"),
        filter: "select:userStatus",
        multi: true,
        sort: "",
        align: "left",
      },
    ],
    storageItemName: "members",
  })

  function handleFetchDataPage({pageParam: cursor}) {
    return api.listMembers({tenantId: tenant.tenantId, extraParams: cols.stringify(), cursor: cursor})
  }

  function handleRenderDataCol(col, row) {
    let n
    switch (col.field) {
      case "displayName":
        n = <div className="d-flex">
              <span>
                {row.displayName}
              </span>
              <span>
                {(user.emailAddress === row.emailAddress) ? <TagLabel label={intl.msg("you")} size="md"/> : ""}
              </span>
            </div>
        break
      case "tenantName":
        n = n = <TenantLink tenant={row} suffix={`/members`}/>
        break
      case "userLevel":
        n = <AccessLevel userLevel={row.userLevel}/>
        break
      case "signedInOn":
        n = <HumanTimestamp timestamp={row.signedInOn} else={""}/>
        break
      case "status":
        n = <UserStatus row={row} size="md"/>
        break
    }
    return (
        n
    )
  }

  function handleRowClick(row) {
    window.location = mkHref({suffix: `/members/${row.userId}`, tenant: row, user: user})
  }

  return (
    tenant &&
      <>
        <MainContainer tenant={tenant} menu={MainMenu}>
          <FormHeader>
            <AccountTabs tenant={tenant}/>
            <FormHeader.Toolbar>
              <FormHeader.Toolbar.Title>
                {intl.msg("members")}
              </FormHeader.Toolbar.Title>
              <FormHeader.Toolbar.Controls>
                {
                  !tenant.isSystem() && tenant.accessingAs("ADMIN/*/*") && !["INVITING"].includes(tenant.status) &&
                    <a className="btn btn-primary" href={mkHref({suffix: `/members/_new`, tenant: tenant, user: user})}>
                      <i className="fas fa-plus mr-1" style={{marginRight: "6px"}}></i>
                      {intl.msg("add_new")}
                    </a>
                }
              </FormHeader.Toolbar.Controls>
            </FormHeader.Toolbar>
            <FormHeader.Alert alert={alert}/>
          </FormHeader>
          <FormBody>
            <DataTable
                tenant={tenant}
                cols={cols}
                resourceName="members"
                dataKey="userId"
                onFetchDataPage={handleFetchDataPage}
                onRenderDataCol={handleRenderDataCol}
                onClickDataRow={handleRowClick}
            />
          </FormBody>
        </MainContainer>
      </>
    )
}