import React, { Fragment, useState, useEffect }  from 'react'
import { useParams, useHistory, useLocation }  from 'react-router-dom'
import { useMutation } from 'react-query'
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import { useSolv } from "./components/SolvProvider";
import { useIntlEx } from "./components/IntlUtils.js"
import { AsyncPaginate } from 'react-select-async-paginate'
import { InfoBlock, InfoText } from "./components/ValidationUtils.js"
import getLogger from "./components/Logging.js"
import './App.css'
import {closeDialog, ModalDialog} from "./components/DialogUtils";

const log = getLogger("TemplateDistributeDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function TemplateDistributeDialog(props)  {

  const {api, session: {user}, setBusy, setFatal} = useSolv()
  const {intl} = useIntlEx()

  const [tenant, setTenant] = useState(null)
      
  const [broadcastTemplateId, setBroadcastTemplateId] = useState(props.broadcastTemplateId)
  const [clients, setClients] = useState(null)
  const [resellers, setResellers] = useState(null)
          
  const [touched, setTouched] = useState(true)
  const [alert, setAlert] = useState(null)

  useEffect(() => {
    if (props) {
      if (props.tenant) {
        setTenant(props.tenant)
      }
      if (props.broadcastTemplateId) {
        setBroadcastTemplateId(props.broadcastTemplateId)
      }
    }
  }, [props])

  function loadClients(search, loadedOptions, additional) {
    log.debug("loadClients:", search, loadedOptions, additional)
    return api.listClients(user.tenantId, search, {field: "tenantName", direction: "ASC"}, additional?.cursor)
      .then(({data, nextCursor}) => {
        log.debug("loadClients: data=", data)
        let opt = data.map(c => {
            return {
              value: c.tenantId,
              label: c.tenantName,
              pop: c.pop
            };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadClients: res=", res)
        return res
      });
  }

  function loadResellers(search, loadedOptions, additional) {
    log.debug("loadResellers:", search, loadedOptions, additional)
    return api.listResellers(user.tenantId, search?.trim(), {field: "tenantName", direction: "ASC"}, additional?.cursor)
      .then(({data, nextCursor}) => {
        let opt = data.map(c => {
          return {
            value: c.tenantId,
            label: c.tenantName,
            pop: c.pop
          };
        });
        let res = {
          options: opt,
        }
        if (nextCursor) {
          res = {
            ...res,
            hasMore: true,
            additional: {
              cursor: nextCursor
            }
          }
        }
        log.debug("loadResellers: res=", res)
        return res
      })
      .catch((error) => {
        log.debug("Error loading reseller: ", error)
      })
  }
      
  function distributeTemplate(payload) {
    return api.distributeBroadcastTemplate(tenant.tenantId, broadcastTemplateId, payload)
  }

  const distributeTemplateMutation = useMutation(
    data => distributeTemplate(data),
    {
      onSuccess: ({data}) => {
        log.debug("distributeTemplateMutation: data=", data)
        setAlert(null)
        handleCloseDialog()
        // if (props.reloadUrl) {
        //   window.location = props.reloadUrl
        // }
        // else {
        //   const el = document.getElementById("btn_close")
        //   el.click()
        // }
      },
      onError: (error) => {
        log.debug("ERROR", error)
        handleError(error)
      }
    }
  )

  function validate() {
    return true
  }
    
  async function handleDistributeClick() {

    try {

      setAlert(null)

      if (validate()) {

        setBusy(intl.msg("working"))

        const clientIds = clients ? clients.map(t => t.value) : []
        const resellerIds = resellers ? resellers.map(t => t.value) : []

        const tenantIds = [...clientIds, ...resellerIds]

        log.debug(">>clientIds=", clientIds)
        log.debug(">>resellerIds=", resellerIds)
        log.debug(">>tenantIds=", tenantIds)

        let payload = {
          targetTenantIds: tenantIds
        }

        log.debug("handleDistributeClick: payload=", payload)

        await distributeTemplateMutation.mutateAsync(payload)

        setTouched(false)

      }
    }
    finally {
      setBusy(null)
    }
  }
  
  function handleError(error) {
    if (error && error.code) {
      log.debug("handleError: error=", error.code)
      const code = error.code
      if (code === "DUPLICATE_KEY") {
        setAlert({error: intl.msg("template_error_already_exits")})
      }
      else {
          setAlert({error: intl.msg("error_failed")})
      }
    }
    else {
          setAlert({error: intl.msg("error_failed")})
    }
  }
      
  function handleClientsChange(value) {
    log.debug("handleClientsChange: value=", value)
    setClients(value)
    setTouched(true)
  }
    
  function handleResellersChange(value) {
    log.debug("handleResellersChange: value=", value)
    setResellers(value)
    setTouched(true)
  }
    
  function isEditable() {
    return true
  }

  function handleOpenDialog() {
    setAlert(null)
    setBusy(null)
  }

  function handleCloseDialog() {
    closeDialog("dlg_template_distribute")
  }

  return (

    <ModalDialog
      dialogId="dlg_template_distribute"
      header={<h1>{intl.msg("template_distribute_title")}</h1>}
      footer={
        <>
          {
            touched && ((clients && clients.length >0) || (resellers && resellers.length >0)) &&
              <button type="button" className="btn btn-primary" onClick={handleDistributeClick}>{intl.msg("distribute")}</button>
          }
          <button className="btn btn-secondary" onClick={handleCloseDialog}>{intl.msg("cancel")}</button>
        </>
      }
      size="lg"
      keyboard={"true"}
      backdrop={props.backdrop || "static"}
      onOpen={handleOpenDialog}
      onClose={handleCloseDialog}
      alert={alert}>

      <form id="frmMain" autoComplete="off">

        <div className="form-card">

          <div className="row">

            <div className="col">

              <label className="text">{intl.msg("template_distribute_text")}</label>

              {
                (user.isAdminOrAbove() && (user.isSystemUser() || user.isResellerUser())) &&
                  <div className={`form-group row`}>
                    <label className="col-sm-2 col-form-label" htmlFor="inp_clients">{intl.msg("template_distribute_clients")}</label>
                    <div className="col-sm-10">
                      <AsyncPaginate
                          id="inp_clients"
                          className="react-select"
                          classNamePrefix="react-select"
                          cacheOptions
                          isMulti
                          isClearable
                          value={clients}
                          debounceTimeout={800}
                          defaultOptions={true}
                          loadOptions={loadClients}
                          onChange={handleClientsChange}
                          isDisabled={!isEditable()}
                      />
                      <InfoBlock>
                        <InfoText>{intl.msg("template_distribute_clients_helptext_1")}</InfoText>
                      </InfoBlock>
                    </div>
                  </div>
              }

              {
                (user && tenant && user.isAdminOrAbove() && (user.isSystemUser() || (user.isResellerUser() && (tenant.maxDepth ? tenant.maxDepth > (tenant.parentPath ? (tenant.parentPath.split(".").length - 1) : 0) : false)))) &&
                  <div className={`form-group row`}>
                    <label className="col-sm-2 col-form-label" htmlFor="inp_clients">{intl.msg("template_distribute_resellers")}</label>
                    <div className="col-sm-10">
                      <AsyncPaginate
                          id="inp_resellers"
                          className="react-select"
                          classNamePrefix="react-select"
                          cacheOptions
                          isMulti
                          isClearable
                          value={resellers}
                          debounceTimeout={800}
                          defaultOptions={true}
                          loadOptions={loadResellers}
                          onChange={handleResellersChange}
                          isDisabled={!isEditable()}
                      />
                      <InfoBlock>
                        <InfoText>{intl.msg("template_distribute_resellers_helptext_1")}</InfoText>
                      </InfoBlock>
                    </div>
                  </div>
              }

            </div>

          </div>

        </div>

      </form>
    </ModalDialog>
  )
}