import React from 'react'
import { Redirect, Route } from 'react-router-dom'
import { useSolv } from "./SolvProvider"
import getLogger from "./Logging.js"

const log = getLogger("PrivateRoute")

const PrivateRoute = ({component: Component, ...rest}) => {
    const {auth} = useSolv()
    log.debug("auth", auth)
    return (
        <Route {...rest} render={ props => {
          return (
            auth.isAuthenticated
              ? <Component {...props} {...rest}/>
              : <Redirect to={{pathname: `/signin`, state: {referrer: props?.location}}}/>
          )
      }}/>
    )
}

export default PrivateRoute;
