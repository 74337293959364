import React from 'react'
import * as dayjs from 'dayjs'
import getLogger from "./Logging.js"

const log = getLogger("CustomFieldUtils")

export function formatDate(v, regionId) {
  let r
  try {
    let format = getDateFormat(regionId, "dayjs")
    r = dayjs(v).format(format)
    r = "Invalid Date" === r ? "" : r
  }
  catch (e) {
  }
  return r
}

export function parseDate(v, regionId) {
  log.debug("parseDate: v=", v)
  if (v === "Invalid Date") {
    return null
  }
  let format = getDateFormat(regionId, "dayjs")
  let r
  try {
    if (v && v.trim().length > 0) {
      let d = dayjs(v, format)
      if (d === "Invalid Date" || d === "Invalid time value") {
        return null
      }
      else {
        r = d.toDate()        
      }
    }
  }
  catch (e) {
    
  }
  log.debug("parseDate: r=", r)
  return r
}

export function getDateFormat(regionId, lib) {
  let format 
  switch (regionId) {
    case "KR" :
      format = "date-picker" === lib ? "yyyy일 MM월 dd년" : "YYYY일 MM월 DD년"        
      break;
    case "GB":
      format = "date-picker" === lib ? "d MMM yyyy" : "D MMM YYYY"
      break;
    case "US":
      format = "date-picker" === lib ? "MMM d, yyyy" : "MMM D, YYYY"
      break;
    default:
      format = "date-picker" === lib ? "d MMM yyyy" : "D MMM YYYY"
  }
  return format
}

export const fieldTypes = [
  {
    "value": "DATE",
    "label": "Date",
  },
  {
    "value": "NUM_REAL",
    "label": "Number (with decimals)",
  },
  {
    "value": "NUM_INT",
    "label": "Number (without decimals)",
  },
  {
    "value": "SELECT",
    "label": "Option List",
  },
  {
    "value": "TEXT_LINE",
    "label": "Text"
  },
  {
    "value": "TEXT_BLOCK",
    "label": "Text Block"
  }
]

export function getCustomFieldTypeOptions(fieldType, allowedFieldTypes) {
  if (!allowedFieldTypes) {
    allowedFieldTypes = ["DATE", "NUM_INTEGER", "NUM_REAL", "SELECT", "TEXT_LINE", "TEXT_BLOCK"]
  }
  return fieldTypes.filter(t => allowedFieldTypes.includes(t.value)).map((t, i) => 
    <option value={t.value} selected={t.value === fieldType ? "selected" : null}>{t.label}</option>                                  
  )
}

export function getFieldInputName(field) {
  if (field && field.input) {
    if (field.input === "REQUIRED") {
      return ("Required")
    } 
    else if (field.input === "OPTIONAL") {
      return ("Optional")
    }
    else if (field.input === "READONLY") {
      return ("Read-only")
    }
    else {
      return ""
    }
  }
  else {
    return ""
  }
}

export const fieldLayoutOpts = [
  {
    value: "LCELL",
    label: "Aligned, left justified"
  },
  {
    value: "RCELL",
    label: "Aligned, right justified"
  },      
  {
    value: "INLINE",
    label: "Inline"
  },
  {
    value: "NEWLINE",
    label: "New line"
  }
]

export function getFieldLayoutName(field) {
  if (field && field.opts && field.opts.layout) {
    const value = field.opts.layout
    const l = fieldLayoutOpts.find(f => f.value === value)
    if (l) {
      return l.label
    }
    else {
      return ""
    }
  }
  else {
    return ""
  }
}

export const fieldLabelStyleOpts = [
  {
    value: "BOLD",
    label: "Bold"
  },
  {
    value: "HIDDEN",
    label: "Hidden"
  },
  {
    value: "NORMAL",
    label: "Normal"
  }
]

export function getFieldLabelStyleName(field) {
  if (field && field.opts && field.opts.labelStyle) {
    const value = field.opts.labelStyle
    const l = fieldLabelStyleOpts.find(f => f.value === value)
    if (l) {
      return l.label
    }
    else {
      return ""
    }
  }
  else {
    return ""
  }
}

export const fieldValueStyleOpts = [
  {
    value: "BOLD",
    label: "Bold"
  },
  {
    value: "NORMAL",
    label: "Normal"
  }
]

export function getFieldValueStyleName(field) {
  if (field && field.opts && field.opts.valueStyle) {
    const value = field.opts.valueStyle
    const l = fieldValueStyleOpts.find(f => f.value === value)
    if (l) {
      return l.label
    }
    else {
      return ""
    }
  }
  else {
    return ""
  }
}



