import React, { Fragment, useState, useEffect }  from 'react'
import useStateRef from "react-usestateref";
import {PayPalButton} from "react-paypal-button-v2";
import 'react-datepicker/dist/react-datepicker.css'
import * as dayjs from 'dayjs'
import * as utc from 'dayjs/plugin/utc'
import * as timezone from 'dayjs/plugin/timezone'
import * as relativeTime from 'dayjs/plugin/relativeTime'
import {useSolv} from "../components/SolvProvider"
import {useIntlEx} from "../components/IntlUtils"
import {closeDialog, ModalDialog} from "../components/DialogUtils";
import {Alert, useBusySpinner} from "../components/FormComps";
import getLogger from "../components/Logging.js"
import '../App.css'
import './PetSolv.css'

const log = getLogger("PurchaseDialog")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)

export default function PurchaseDialog(props)  {

  const { api, auth: { session: { user, tenant }, refreshSignin }, env } = useSolv()

  log.debug("env", env)

  const { intl } = useIntlEx()
  const {setBusy} = useBusySpinner()
  const [touched, setTouched] = useState(false)
  // const [working, setWorking] = useState(null)
  const [info, setInfo] = useState(null)
  const [error, setError] = useState(null)

  // const queryClient = useQueryClient()

  const [alert, setAlert] = useState(null)

  const [paymentTransactionId, setPaymentTransactionId, paymentTransactionIdRef] = useStateRef(null)

  async function handlePaypalCreateOrder(orderData, actions) {

    log.debug("handlePaypalCreateOrder: orderData=", orderData, props)

    setBusy(intl.msg("working"))
    try {

      // const amount = parseFloat(intl.num(props.item.usdPrice / props.region.usdMultiplier))
      const amount = parseFloat(intl.num(props.amount))

      log.debug("handlePaypalCreateOrder: amount=", amount)

      const payload = {
        paymentTransactionType: "TOP_UP",
        paymentMethodId: "PAYPAL_CHECKOUT",
        amount: amount,
        usdMultiplier: props.region.usdMultiplier,
        details: orderData
      }

      log.debug("handlePaypalCreateOrder: payload=", payload)

      const { data } = await api.createPaymentTransaction(payload)

      setPaymentTransactionId(data.paymentTransactionId)

      log.debug("handlePaypalCreateOrder: paymentTransactionId=", data.paymentTransactionId)

      return actions.order.create({
        purchase_units: [
          {
            amount: {
              currency_code: `${props.region.currencyCode ? props.region.currencyCode : "USD"}`,
              value: amount
            }
          }
        ],
        application_context: {
          brand_name: props.paypalBrandName || "Top up CreditTransactions",
          shipping_preference: "NO_SHIPPING"
        }
      })

    }
    catch (err) {
      log.debug("handlePaypalCreateOrder: error=", JSON.stringify(err))
      setAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  async function handlePaypalOnApprove(orderData, actions) {

    log.debug("handlePaypalOnApprove: orderData=", orderData)

    setBusy(intl.msg("working"))
    try {

      const details = await actions.order.capture()

      await props.onPurchase({
        paymentTransactionId: paymentTransactionIdRef.current,
        details: details
      })

      return actions.order
        .capture()
        .then((details) => {
          log.debug("handlePaypalOnApprove: details=", details)
          if (props.onComplete) {
            props.onComplete()
          }
          closeDialog(props.id || "dlg_purchase_credits")
        })
        .catch((err) => {
          log.debug("handlePaypalOnApprove: err=", err)
          setAlert({error: intl.msg("error_paypal_failed")})
        })
    }
    catch (err) {
      log.debug("handlePaypalOnApprove: error=", JSON.stringify(err))
      setAlert({error: intl.msg("error_paypal_failed")})
    }
    finally {
      setBusy(null)
    }
  }

  function handleOpenDialog() {
  }

  function handleCloseDialogClick() {
    if (props.onCancel) {
      props.onCancel()
    }
    closeDialog(props.id || "dlg_purchase_credits")
  }

  return (

      <ModalDialog
          dialogId={props.id || "dlg_purchase"}
          size={props.size || "md"}
          keyboard={"true"}
          backdrop={props.backdrop || "static"}
          header={<h1>{props.title || ""}</h1>}
          alert={alert}
          onOpen={handleOpenDialog}
          onClose={handleCloseDialogClick}>
          <div className="row">
            <div className="col">
              <div className="d-flex justify-content-center centered" style={{padding: "0 0 20px 0"}}>
                {props.children}
              </div>
              <center>
                <PayPalButton
                    options={{
                      clientId: (tenant.tenantTypeId === "SYSTEM" || ["DEMO", "TEST"].includes(tenant.tenantPlan) || user?.emailAddress?.endsWith("@solv.group") || user?.emailAddress?.endsWith("@solv.technology") ? env.PAYPAL_TEST_CLIENT_ID : env.PAYPAL_LIVE_CLIENT_ID),
                      currency: `${props.region.currencyCode ? props.region.currencyCode : "USD"}`,
                    }}
                    createOrder={handlePaypalCreateOrder}
                    onApprove={handlePaypalOnApprove}
                />
              </center>
            </div>
          </div>
      </ModalDialog>
  )
}